/** oldValue | newValue | result
 *   -----------------------------
 *       T    |   F      |  -1
 *       F    |   T      |  +1
 *       T    |   T      |   0
 *       F    |   F      |   0
 */
export const getTransition = (oldValue: boolean, newValue: boolean): number => {
  if (oldValue === newValue) {
    return 0;
  }

  return Number(!oldValue) - Number(!newValue);
};
