import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IIconType } from '../../interfaces/icon-type.interface';

const newUiToolKitAssetsFolder = '/assets/ui-toolkit';
const newUiToolReactionsAssetsFolder = '/assets/ui-toolkit/reactions';
const newDashboardsAssetsFolder = '/assets/ui-toolkit/dashboards';
const newAssetsFolder = '/assets';

const icons = [
  {
    name: 'neu-account',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/account.svg`
  },
  {
    name: 'neu-ai-assistant',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/ai-assistant.svg`
  },
  {
    name: 'neu-tools',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/neu-tools.svg`
  },
  {
    name: 'neu-tuning',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/tuning.svg`
  },
  {
    name: 'neu-open-mind',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/open-mind.svg`
  },
  {
    name: 'neu-reading-list',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/reading-list.svg`
  },
  {
    name: 'neu-reading-list-colored',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/reading-list-colored.svg`
  },
  {
    name: 'neu-reading-list-blue',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/reading-list-blue.svg`
  },
  {
    name: 'neu-reading-list-blue-white',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/reading-list-blue-white.svg`
  },
  {
    name: 'neu-logout',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/logout.svg`
  },
  {
    name: 'neu-collections',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/collections.svg`
  },
  {
    name: 'neu-collections-primary',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/collections-primary.svg`
  },
  {
    name: 'neu-dialogue-boards',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/dialogue-boards.svg`
  },
  {
    name: 'neu-topic',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/neu-topic.svg`
  },
  {
    name: 'neu-feedback',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/feedback.svg`
  },
  {
    name: 'neu-notebooks',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/notebooks.svg`
  },
  {
    name: 'neu-notifications',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/notifications.svg`
  },
  {
    name: 'neu-user-dashboard',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/user-dashboard.svg`
  },
  {
    name: 'neu-refer-friend',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/refer-friend.svg`
  },
  {
    name: 'neu-invite-friend-primary',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/neu-invite-friend-primary.svg`
  },
  {
    name: 'neu-connection',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/connection.svg`
  },
  {
    name: 'neu-layouts',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/layout.icon.svg`
  },
  {
    name: 'neu-collection-fill',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/collection-fill.svg`
  },
  {
    name: 'neu-notebook',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/notebook.svg`
  },
  {
    name: 'neu-articles',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/articles.svg`
  },
  {
    name: 'neu-toggle-sidebar',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/toggle-sidebar.svg`
  },
  {
    name: 'neu-charts',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/charts.svg`
  },
  {
    name: 'neu-feedback-colored',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/feedback-colored.svg`
  },
  {
    name: 'neu-feedback-colored-primary',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/feedback-colored-primary.svg`
  },
  {
    name: 'neu-invite-colored',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/invite-colored.svg`
  },
  {
    name: 'neu-notification-colored',
    path: `${newUiToolKitAssetsFolder}/navbar/icons/notification-colored.svg`
  },
  {
    name: 'neu-analytics',
    path: `${newAssetsFolder}/icons/ion_analytics.svg`
  }
];

const dashboardIcons = [
  {
    name: 'pie-chart',
    path: `${newDashboardsAssetsFolder}/pie-chart-icon.svg`
  }
];

const reactionIcons = [
  {
    name: 'reaction-angry',
    path: `${newUiToolReactionsAssetsFolder}/reaction-angry.svg`
  },
  {
    name: 'reaction-crap',
    path: `${newUiToolReactionsAssetsFolder}/reaction-crap.svg`
  },
  {
    name: 'reaction-education',
    path: `${newUiToolReactionsAssetsFolder}/reaction-education.svg`
  },
  {
    name: 'reaction-entertainment',
    path: `${newUiToolReactionsAssetsFolder}/reaction-entertainment.svg`
  },
  {
    name: 'reaction-none',
    path: `${newUiToolReactionsAssetsFolder}/reaction-none.svg`
  },
  {
    name: 'reaction-suspicious',
    path: `${newUiToolReactionsAssetsFolder}/reaction-suspicious.svg`
  },
  {
    name: 'reaction-valuable',
    path: `${newUiToolReactionsAssetsFolder}/reaction-valuable.svg`
  }
];

const moreIcons = [
  {
    name: 'neu-transit-connection',
    path: `${newAssetsFolder}/icons/transit-connection.svg`
  },
  {
    name: 'neu-awesome-window',
    path: `${newAssetsFolder}/icons/awesome-window-maximize.svg`
  },
  {
    name: 'neu-settings',
    path: `${newAssetsFolder}/icons/ProfileSettings.svg`
  },
  {
    name: 'pivot',
    path: `${newAssetsFolder}/icons/pivot.svg`
  },
  {
    name: 'cancel',
    path: `${newAssetsFolder}/icons/cancel.svg`
  },
  {
    name: 'calendar',
    path: `${newAssetsFolder}/icons/date-filter.svg`
  },
  {
    name: 'classic-view',
    path: `${newAssetsFolder}/icons/classic-view.svg`
  },
  {
    name: 'personal-info',
    path: `${newAssetsFolder}/icons/ProfilePersonalInfo.svg`
  },
  {
    name: 'info',
    path: `${newAssetsFolder}/icons/infoicon2.svg`
  },
  {
    name: 'platform-setting',
    path: `${newAssetsFolder}/icons/platform-setting.svg`
  },
  {
    name: 'security',
    path: `${newAssetsFolder}/icons/security.svg`
  },
  {
    name: 'search-wizard',
    path: `${newAssetsFolder}/icons/search-wizard.svg`
  },
  {
    name: 'panel-tuning',
    path: `${newAssetsFolder}/icons/panel-tuning-active.svg`
  },
  {
    name: 'briefcase',
    path: `${newAssetsFolder}/icons/briefcase.svg`
  },
  {
    name: 'neu-blog',
    path: `${newAssetsFolder}/icons/fa-brands_blogger-b.svg`
  },
  {
    name: 'neu-legislation',
    path: `${newAssetsFolder}/icons/legislation_icon.svg`
  },
  {
    name: 'neu-collection-document',
    path: `${newAssetsFolder}/icons/documents_icon.svg`
  }
];

const faceReaction = [
  {
    name: 'happy-icon',
    path: `${newAssetsFolder}/icons/happy.svg`
  },
  {
    name: 'netrual-icon',
    path: `${newAssetsFolder}/icons/netrual.svg`
  },
  {
    name: 'sad-icon',
    path: `${newAssetsFolder}/icons/sad.svg`
  }
];

const collectionIcons: IIconType[] = [
  {
    name: 'neu-collection-folder',
    path: `${newAssetsFolder}/icons/collection-folder.svg`
  },
  {
    name: 'neu-collection-folder-blue',
    path: `${newAssetsFolder}/icons/collection-folder-blue.svg`
  }
];

export const loadNeutralityIcons = (matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) => {
  for (const customIcon of [
    ...icons,
    ...reactionIcons,
    ...dashboardIcons,
    ...moreIcons,
    ...faceReaction,
    ...collectionIcons
  ]) {
    matIconRegistry.addSvgIcon(customIcon.name, domSanitizer.bypassSecurityTrustResourceUrl(customIcon.path));
  }
};
