import { createAction, props } from '@ngrx/store';
import { IFilterLabel, IFilterChip } from '../../core/filters/core/interfaces';
import { ISavedTopic } from '../../ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { IPage, INewsPagination, INewsDataset, INewsItem, IShowTrendingTopicsResultsEvent } from '../core/interfaces';
import { ISavedCustomSources } from 'src/app/news-filters/filter/tree-filter-publishers/core/interfaces';
import { IResearchCollection } from '../../research-collections/core/interfaces';

// --------------- EXECUTE A SEARCH --------------- //
export const ExecuteNewSearch = createAction('[News] Executes a new search with all the queries and filters');

// ------------------- FILTERS ------------------- //
export const AddToggleFilterToQuery = createAction('[News] Pushes Reading List filters', props<{ filter: string }>());

export const RemoveToggleFilterFromQuery = createAction(
  '[News] Removes Reading List filters',
  props<{ filter: string }>()
);

export const UpdateFiltersForQuery = createAction('[News] Sets the current filters', props<{ filters: string[] }>());

export const ToggleFilter = createAction(
  '[News] Toggle true or false the value of the selected filter',
  props<{ filter: IFilterLabel; shouldBeActive?: boolean }>()
);

export const ClearActiveReadingAndTopFilters = createAction('[News] Clear active reading filters');

export const AppendToFilters = createAction(
  '[News] Adds the filters from the API to the store for populate in template',
  props<{ filters: IFilterLabel[] }>()
);

export const SetDateFilter = createAction(
  '[News] Sets the current date filter in the store',
  props<{ date: string }>()
);

// ----------------- FILTERS RESETS ----------------- //
export const ResetDateForm = createAction('[News] Resets the date form');

export const ResetAllFilters = createAction('[News] Resets all filters in the UI');

// ------------------- PAGINATION ------------------- //
export const SetCurrentPage = createAction('[News] Sets the current page', props<{ currentPage: IPage }>());

// ------------------- SEARCH CHIPS ------------------- //
export const AppendSearchChip = createAction('[News] Adds a new chip to the array', props<{ chip: IFilterChip }>());

export const AppendMultipleSearchChip = createAction(
  '[News] Adds multiple new chips to the array',
  props<{ chips: IFilterChip[] }>()
);

export const AppendOnlySearchChip = createAction(
  '[News] Adds only new chip to the array',
  props<{ chip: IFilterChip[] }>()
);

export const DeleteSearchChip = createAction('[News] Deletes a chip from the array', props<{ chip: IFilterChip }>());

export const DeleteAllSearchChip = createAction('[News] Deletes allchips from the array');

// ------------------- SEARCH QUERY ------------------- //
export const UpdateCurrentQuery = createAction('[News] Sets the current query', props<{ currentQuery: string }>());

// ------------------- SORT ORDER ------------------- //
export const SetCurrentSortOrder = createAction(
  '[Sets the current sort order]',
  props<{ currentSortOrder: INewsPagination }>()
);

// ------------------- SORT DIRECTION ------------------- //
export const SetCurrentSortDirection = createAction(
  '[Sets the current sort direction]',
  props<{ currentSortDirection: string }>()
);

// ------------------- SAVED TOPICS ------------------- //
export const AddSavedTopic = createAction('[News] Add a saved topic to the menu', props<{ savedTopic: ISavedTopic }>());
export const SaveTopicAndQuickAccess = createAction('[News] Trigger effect that saved topic and quick access to DB');

export const InvalidateCurrentActiveSearch = createAction('[News] Invalidate current active search if exists');
export const ClearInvalidateCurrentActiveSearch = createAction('[News]  Set invalidate current active search to null');

export const UpdateSavedTopics = createAction(
  '[News] Add many saved topics to the menu',
  props<{ savedTopics: ISavedTopic[] }>()
);

export const SetQuickAccessTopicsState = createAction(
  '[News] Set quick access topic state',
  props<{ topics: string[] }>()
);

export const UpdateQuickAccessTopicsState = createAction(
  '[News] Update quick access topic state',
  props<{ topics: string[] }>()
);

export const DeleteSavedTopic = createAction(
  '[News] Deletes a save topic from the array',
  props<{ savedTopic: ISavedTopic }>()
);

export const SetNameSavedTopic = createAction(
  '[News] Set a save topic name from the array',
  props<{ newTopics: ISavedTopic[] }>()
);

export const SetSavedTopicsOrder = createAction(
  '[News] Set a save topic order from the array',
  props<{ setSavedTopicOrder: ISavedTopic[] }>()
);

export const SetSelectedTopic = createAction('[News] Set the selected saved topic', props<{ selectedTopic: string }>());

// ------------------- SAVED CUSTOM SOURCES ------------------- //
export const AddCustomSource = createAction(
  '[News] Add a custom source',
  props<{ savedCustomSource: ISavedCustomSources }>()
);

export const UpdateSavedCustomSource = createAction(
  '[News] Add many saved custom sources',
  props<{ savedCustomSources: ISavedCustomSources[] }>()
);

export const DeleteCustomSource = createAction(
  '[News] Deletes a custom source from the array',
  props<{ deletedCustomSource: ISavedCustomSources }>()
);

// ------------------- MENUS ------------------- //
export const SetMenuChartOpen = createAction(
  '[News] Set true or false the value of the menu to show menu',
  props<{ currentState: boolean }>()
);

export const SetMenuLayoutOpen = createAction(
  '[News] Set true or false the value of the menu to change the view type',
  props<{ currentState: boolean }>()
);

// ---------------------- SPINNER ---------------------- //
export const SetSpinnerStatus = createAction(
  '[News] Set true or false the value of the spinner',
  props<{ currentState: boolean }>()
);

// ---------------------- DEFAULT FILTER ---------------------- //
export const SetDefaultFilter = createAction('[News] Set the default filter', props<{ defaultFilter: ISavedTopic }>());

// ---------------------- ACTIVE DEFAULT FILTER ---------------------- //
export const ToggleDefaultFilter = createAction(
  '[News] Toggle value of default filter of user',
  props<{ active: boolean }>()
);

// ---------------------- Network calls reducing ---------------------- //
export const SetCurrentNews = createAction(
  // SetCurrentNewsDataset
  '[News] Set current app news',
  props<{ newsDataset: INewsDataset }>()
);

export const UpdateCurrentNewsItem = createAction(
  '[News] Update current app new',
  props<{ newsItemData: INewsItem }>()
);

// ---------------------- Date filter ---------------------- //
export const SetCurrentDateFilter = createAction(
  '[News] Set current date query filter',
  props<{ dateQueryFilter: string }>()
);

// Trending topics
export const SetShowResultsFromTrendingTopicsId = createAction(
  '[News] Set current payload for trenidng topics news',
  props<{ event: IShowTrendingTopicsResultsEvent }>()
);

// Tabs
export const SetIsArticlesTabVisible = createAction(
  '[News] Set is article tabs visible',
  props<{ isVisible: boolean }>()
);

// Reading List
export const SetReadingListCount = createAction('[News] Set reading lists count', props<{ count: number }>());

// ------------------- USER COLLECTIONS ------------------- //
export const AddResearchCollection = createAction(
  '[News] Add a user collection to collections',
  props<{ researchCollection: IResearchCollection }>()
);

export const UpdateResearchCollections = createAction(
  '[News] Add many user collections',
  props<{ researchCollections: IResearchCollection[] }>()
);

export const DeleteResearchCollection = createAction(
  '[News] Deletes a user collection from the array',
  props<{ researchCollection: IResearchCollection }>()
);

export const SetNameResearchCollection = createAction(
  '[News] Set a user collection name from the array',
  props<{ newCollections: IResearchCollection[] }>()
);

export const SetResearchCollectionsOrder = createAction(
  '[News] Set a save search order from the array',
  props<{ setResearchCollectionOrder: IResearchCollection[] }>()
);
