import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

//  Global site tag (gtag.js) - Google Analytics - Google Tag Manager
function createGTMTag(tagId) {
  const scriptGTM = document.createElement('script');

  scriptGTM.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id=${tagId}'+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${tagId}');
  `;

  document.head.appendChild(scriptGTM);

  const noscriptGTM = document.createElement('noscript');
  noscriptGTM.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${tagId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;

  document.body.appendChild(noscriptGTM);
}

function getEnvironment() {
  const hostname = window.location.hostname;

  if (hostname.includes('localhost') || hostname.includes('dev')) {
    return 'dev';
  } else if (hostname.includes('staging')) {
    return 'staging';
  } else {
    return 'production';
  }
}

const environments = getEnvironment();

if (environments === 'dev') {
  createGTMTag('GTM-M22L9FPG'); // Dev
} else if (environments === 'staging') {
  createGTMTag('GTM-K9QGQSG9'); // Staging
} else if (environments === 'production') {
  createGTMTag('GTM-57HGMNX'); // Production
}

function bootstrapApp() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      preserveWhitespaces: false
    })
    // TODO: uncomment this for debug profiling
    /*  .then((moduleRef) => {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(componentRef);
    })*/
    .catch((err) => handleError(err));
}

function handleError(err) {
  console.error(err);
}

bootstrapApp();
