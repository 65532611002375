import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { RoleType } from './core/enums';
import { ViewInfoService } from './core/services/view-info.service';
import { loadNeutralityIcons } from './ui-kits/neu-ui-kit/functions';

@Component({
  selector: 'neu-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, OnDestroy {
  public availableRoles: typeof RoleType = RoleType;

  private destroy$ = new Subject<void>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private viewInfoService: ViewInfoService
  ) {}

  public ngOnInit(): void {
    loadNeutralityIcons(this.matIconRegistry, this.domSanitizer);
    this.viewInfoService.currentViewSub(this.destroy$);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:click', ['$event'])
  private clickOutside(event: MouseEvent) {
    this.viewInfoService.documentClick$.next(event);
  }
}
