/**
 * This function will take 2 strings and
 * output how similar they are from 0 to 1 (floating numbers)
 * Example: We have 2 filters: topics & categories that have similar names,
 * this function returns a value between 0 and 1.
 */
export const stringSimilarity = (inputString1: string, inputString2: string) => {
  let longerString: string = inputString1;
  let shorterString: string = inputString2;

  if (inputString1.length < inputString2.length) {
    longerString = inputString2;
    shorterString = inputString1;
  }

  const longerLength: number = longerString.length;

  if (longerLength === 0) {
    return 1.0;
  }

  // Range from 0 to 1
  return (longerLength - calculateDistance(longerString, shorterString)) / parseFloat(longerLength.toString());
};

/**
 *
 * Based on: https://en.wikipedia.org/wiki/Levenshtein_distance
 */
const calculateDistance = (inputString1: string, inputString2: string) => {
  inputString1 = inputString1.toLowerCase();
  inputString2 = inputString2.toLowerCase();

  const matchingCosts: number[] = [];

  for (let indexFirstString = 0; indexFirstString <= inputString1.length; indexFirstString++) {
    let lastValue = indexFirstString;

    for (let indexSecondString = 0; indexSecondString <= inputString2.length; indexSecondString++) {
      if (indexFirstString === 0) {
        matchingCosts[indexSecondString] = indexSecondString;
      } else {
        if (indexSecondString > 0) {
          let newValue = matchingCosts[indexSecondString - 1];

          if (inputString1.charAt(indexFirstString - 1) !== inputString2.charAt(indexSecondString - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), matchingCosts[indexSecondString]) + 1;
          }
          matchingCosts[indexSecondString - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (indexFirstString > 0) {
      matchingCosts[inputString2.length] = lastValue;
    }
  }

  return matchingCosts[inputString2.length];
};
