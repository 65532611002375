import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CirculationFilterType, distributionFiltersType } from '../filter/tree-filter-publishers/core/enums';
import { IFiltersState, filtersStateFeatureKey } from './filters-state.reducer';

export const selectFiltersState = createFeatureSelector<IFiltersState>(filtersStateFeatureKey);

export const selectAvailablePublishersFilters = createSelector(
  selectFiltersState,
  (filtersState) => filtersState.availablePublishersFilters
);

export const selectAvailablePublishersLocalStateFilters = createSelector(
  selectFiltersState,
  (filtersState) => filtersState.availablePublishersLocalStateFilters
);

export const selectActivePublishersFilters = createSelector(
  selectFiltersState,
  (filtersState) => filtersState?.selectedPublishersNodes
);

export const selectActivePublishersStateFilters = createSelector(
  selectFiltersState,
  (filtersState) => filtersState.selectedPublishersLocalStateNodes
);

export const selectSubfilters = createSelector(selectFiltersState, (filtersState) => {
  return {
    circulation: filtersState.circulationFilters,
    type: filtersState.typeFilters,
    subfilterSearchQuery: filtersState.subFilterSearchQuery,
    states: filtersState.selectedPublishersLocalStateNodes
  };
});

/** * Date Filter ***/
export const selectDateFilter = createSelector(selectFiltersState, (filtersState) => {
  return filtersState?.dateFilter;
});

export const selectMyFilterActive = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.myFilterActive;
});

export const selectFiltersToBeUnselected = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.publishersToBeDeselectedFromUi;
});

export const selectFiltersToBeSelected = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.publishersToBeSelectedFromUi;
});

export const selectDateFilterOpened = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.isDateFilterOpened;
});

/** Topics filers, Categories filters **/

export const selectAllTrendingTopicsPublishers = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.allTrendingTopicsPublishers;
});

export const selectTopicCategoriesFilters = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.topicCategoriesFilters;
});

export const selectPublishersAndCategoiresFilters = createSelector(selectFiltersState, (filtersState) => {
  return {
    publishers: filtersState.selectedPublishersNodes,
    categories: filtersState.topicCategoriesFilters
  };
});

export const selectTopicTermsFilters = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.termFilters;
});

export const selectPublishersCategoiresAndTerms = createSelector(selectFiltersState, (filtersState) => {
  return {
    publishers: filtersState.selectedPublishersNodes,
    categories: filtersState.topicCategoriesFilters,
    terms: filtersState.termFilters
  };
});

/** * Trending topics filters for news ***/
export const selectTrendingTopicFiltersForNews = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.trendingTopicsFiltersForNews;
});

// Facets

export const selectFiltersFacets = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.filtersFacets;
});

// Facets for Sections
export const selectFiltersFacetsForSection = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.filtersFacetsForSections;
});

export const selectAllCategories = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.allCategoriesFilters;
});

// Chips
export const onClearAllFiltersAndQuery = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.lastTimeCleared;
});

// Combined Any subfilter selected
export const selectTrendingTopicSubfilterQuery = createSelector(selectFiltersState, (filtersState) => {
  return filtersState.trendingTopicSubFilterQuery;
});

export const selectAnySubFilterActive = createSelector(
  selectSubfilters,
  selectTrendingTopicSubfilterQuery,
  (
    sourcesSubFilters: {
      circulation: CirculationFilterType[];
      type: distributionFiltersType[];
      subfilterSearchQuery: string;
    },
    trendingTopicSubFilterQuery: string
  ) => {
    if (sourcesSubFilters.circulation.length) {
      return true;
    }

    if (sourcesSubFilters.type.length) {
      return true;
    }

    if (sourcesSubFilters.subfilterSearchQuery && sourcesSubFilters.subfilterSearchQuery.length) {
      return true;
    }

    if (sourcesSubFilters.subfilterSearchQuery.length) {
      return true;
    }

    if (trendingTopicSubFilterQuery?.length) {
      return true;
    }

    return false;
  }
);
