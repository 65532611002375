// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './interfaces';

export const environment: IEnvironment = {
  environmentName: 'development',
  production: false,
  // Keycloak
  keycloakRealm: 'neutrality-news-dev',
  keycloakClientId: 'neutrality-news-dev-client',
  // Auth
  AUTH_KC_ENDPOINT: 'https://auth-kc.neutrality.systems',
  // API
  API_ENDPOINT: 'https://dev-api.neutrality-news.com',
  // Whether to use Zepplin Endpoint or not
  useZepplinEndpoint: false,
  pocSolrQueryApiKey: '9eb33d8b-0ec0-4036-9c9d-f73e67535d3e',
  gtag: 'G-BRRSNK64TJ'
};
