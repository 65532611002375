export enum NeutralityBreakpoints {
  // SM  - Mobile
  LessThanSM = '(max-width: 480px)',
  GreaterThanSM = '(min-width: 480px)',
  // MD - Tablet
  LessThanMD = '(max-width: 768px)',
  GreaterThanMD = '(min-width: 768px)',
  // LG - Desktop
  LessThanLG = '(max-width: 1080px)',
  GreaterThanLG = '(min-width: 1080px)',
  // ExLG
  GreaterThanExLG = '(min-width: 1920px)'
}
