import { createAction, props } from '@ngrx/store';

export const readingListEventStartState = createAction(
  '[Reading List Event] start reading list event session',
  props<{ isReadingListEventStart: boolean }>()
);

export const setReadingListEventQuery = createAction(
  '[Reading List Event] set reading list event query',
  props<{ query: string }>()
);

export const setReadingListEventFilteredSource = createAction(
  '[Reading List Event] set reading list event filtered source',
  props<{ filterSource: string }>()
);

export const setReadingListEventFilteredKeyword = createAction(
  '[Reading List Event] set reading list event filtered keyword',
  props<{ filterKeywords: string }>()
);

export const setReadingListEventFilteredDate = createAction(
  '[Reading List Event] set reading list event filtered date',
  props<{ filterDate: string }>()
);

export const setReadingListEventLayoutType = createAction(
  '[Reading List Event] set reading list event layout type',
  props<{ layoutType: string }>()
);

export const setReadingListEventSortType = createAction(
  '[Reading List Event] set reading list event sort type',
  props<{ sortType: string }>()
);

export const setReadingListEventDuration = createAction(
  '[Reading List Event] set reading list event duration',
  props<{ duration: string }>()
);

export const resetReadingListEvent = createAction('[Reading List Event] reset reading list event');
