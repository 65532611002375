import { createAction, props } from '@ngrx/store';
import { IDateOption, ISearchAnalyticsFilter } from '../interfaces';

export const setAnalyticSelectedOption = createAction(
  '[Neu Analytic] set analytic selected option',
  props<{ option: IDateOption }>()
);

export const setSearchAnalyticParams = createAction(
  '[Neu Analytic] set search analytic selected params',
  props<{ searchFilter: ISearchAnalyticsFilter }>()
);

export const setSelectedChartType = createAction(
  '[Neu Analytic] set search analytic chart type',
  props<{ chartType: string }>()
);
