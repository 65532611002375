export enum NeutralityPageTitles {
  NeutralityMain = 'Neutrality |  Your Data. Your News.',
  PrivacyPolicy = 'Privacy Policy | Neutrality',
  Terms = 'Terms & Conditions | Neutrality',
  SignIn = 'Sign In | Neutrality',
  SignUp = 'Sign Up | Neutrality',
  Dashboard = 'Dashboard | Neutrality',
  Search = 'Search | Neutrality',
  OpenMind = 'OpenMind | Neutrality',
  Profile = 'Profile | Neutrality',
  Trutopics = 'Trutopics | Neutrality',
  Collections = 'Collections | Neutrality',
  Settings = 'Settings | Neutrality'
}
