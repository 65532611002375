import { createAction, props } from '@ngrx/store';
import { IDatasetTuneupPublisher } from 'src/app/api/core/api-interfaces';
import { IApiRssDetails } from 'src/app/api/core/interfaces';

// ------------------- Main ------------------- //

// Sources

export const setAvailableSources = createAction(
  '[sourcesFilters] Set available sources',
  props<{ sources: (IDatasetTuneupPublisher | IApiRssDetails)[] }>()
);

export const setActiveSelectedSources = createAction(
  '[sourcesFilters] Set available selected sources',
  props<{ sources: (IDatasetTuneupPublisher | IApiRssDetails)[] }>()
);

export const setSourcesWithContentInDatasetCount = createAction(
  '[sourcesFilters] Set number of sources with content',
  props<{ amount: number }>()
);

export const toggleActiveSelectedSource = createAction(
  '[sourcesFilters] Toggle active selected source or sub source',
  props<{ source: IDatasetTuneupPublisher | IApiRssDetails; isActive?: boolean }>()
);

// Circulation
export const setActiveCirculationFilters = createAction(
  '[sourcesFilters] Set the active circulation filters',
  props<{ activeCirculationFilters: string[] }>()
);

// Publisher type
export const setActivePublisherTypeFilters = createAction(
  '[sourcesFilters] Set the active publisher type filters',
  props<{ activePublisherTypeFilters: string[] }>()
);

// Publisher state
export const setActivePublisherStateFilters = createAction(
  '[sourcesFilters] Set the active publisher state filters',
  props<{ activePublisherStateFilters: string[] }>()
);
