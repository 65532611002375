// The type of neu-event, Analytics for server, or notifications
export enum NeuEventType {
  // General
  Search = 'search',
  SearchSession = 'searchSession',
  ApplicationState = 'applicationState',
  userSignupCategories = 'userSignupCategories',
  Exit = 'exit',
  // Auth
  PreRegister = 'preRegistered',
  PreVerified = 'preVerified',
  Verified = 'verified',
  Login = 'login',
  Logout = 'logout',
  PasswordChange = 'passwordChange',
  Signup = 'signup',
  ForgotPassword = 'forgotPassword',
  ForgotPasswordChanged = 'forgotPasswordChanged',
  // Sidebar
  NotificationClosed = 'notificationClosed',
  NotificationsRefresh = 'notificationsRefresh',
  FeedbackClicked = 'feedbackClicked',
  FeedbackSent = 'feedbackSent',
  NotificationsOpened = 'notificationsOpened',
  NotificationsClosed = 'notificationsClosed',
  // Filter management
  RemovedSavedSearch = 'removedSavedSearch',
  SearchEditTopic = 'searchEditTopic',
  SetDefaultFilterOnInit = 'setDefaultFilterOnInit',
  RemovedDefaultFilterOnInit = 'removedDefaultFilterOnInit',
  // Openmind
  OpenmindSession = 'openmindSession',
  ArticleOut = 'articleOut',
  SentimentRelevancyChartOpen = 'sentimentRelevancyChartOpen',
  // Notifications
  Informative = 'informative',
  // Search + Filters
  SavedSearch = 'savedSearch',
  SavedStory = 'savedStory',
  SavedCustomSource = 'savedCustomSource',
  RemovedCustomSource = 'removedCustomSource',
  SearchCreateTopic = 'searchCreateTopic',
  TrendingNowChartOpen = 'trendingNowChartOpen',
  TopicExplorerChartOpen = 'topicExplorerChartOpen',
  // Dashboard
  DashboardCreateTopic = 'dashboardCreateTopic',
  DashboardIn = 'dashboardIn',
  // Analytics
  AnalyticsOpen = 'nAnalyticsOpen',
  // Profile
  OptInChanged = 'optInChanged',
  TermsAndConditions = 'termsAndConditionsClicked',
  PrivacyPolicyClicked = 'privacyPolicyClicked',
  ProfileSession = 'profileSession',
  // User Collection + Filters
  ResearchCollection = 'researchCollection',
  // Saved Topic
  RecallSavedTopic = 'recallSavedTopic',
  // Reading List
  ReadingListSession = 'readingListSession',

  // Neutrality Analytic
  TrendingNowSession = 'trendingNowSession',
  SearchAnalyticsSession = 'searchAnalyticsSession',

  // Dashboard
  DashboardSession = 'dashboardSession'
}
