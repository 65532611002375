import { Injectable } from '@angular/core';
import { IDatasetTuneupData, IRelevancyHist, ISentimentHist } from 'src/app/api/core/api-interfaces';
import { IApiSentimentRelevancyBucket } from 'src/app/api/core/interfaces';
import { ArticleSentimentType, RelevancyType } from 'src/app/news/enums';
import { SentimentColorType } from 'src/app/openmind/core/enums';
import { IOpenmindItem } from 'src/app/openmind/core/interfaces';
import { RelevancyChartButtonColors } from 'src/app/shared/ui-charts/enums';
import { IFilter } from '../filters/core/interfaces';
import { IHistogram, IMinMaxRange } from '../utils/interfaces';
import { makeHistogram } from '../utils/make-histogram.function';

@Injectable({
  providedIn: 'root'
})
export class SentimentRelevancyService {
  public sentimentRange = [0, 0.8, 1.6, 2.4, 3.2, 4.0];
  public relevancyRange = [0, 25, 50, 75, 100];
  public relevancyBtnColorRange: number[] = [20, 40, 60, 80, 100];

  public relevancyTuneupFromArticles(articles: IOpenmindItem[]): IApiSentimentRelevancyBucket[] {
    const articlesRelevancy: number[] = articles.map((article: IOpenmindItem) => article.relevancy);

    const bucketsNumber = 5;

    const buckets: IApiSentimentRelevancyBucket[] = this.tuneupRelevancyFromValues(articlesRelevancy, bucketsNumber);

    return buckets;
  }

  public sentimentTuneupFromArticles(articles: IOpenmindItem[]): IApiSentimentRelevancyBucket[] {
    const articlesSentiment: number[] = articles.map((article: IOpenmindItem) => article.raw.sentiment);
    const buckets: IApiSentimentRelevancyBucket[] = this.tuneupSentimentsFromValues(articlesSentiment);

    return buckets;
  }

  public mapSentimentTypeToFilterRange(
    sentimentType: ArticleSentimentType
  ):
    | 'sentiment:[0 TO 0.8]'
    | 'sentiment:[0.81 TO 1.60]'
    | 'sentiment:[1.61 TO 2.40]'
    | 'sentiment:[2.41 TO 3.20]'
    | 'sentiment:[3.21 TO 4.00]' {
    switch (sentimentType) {
      case ArticleSentimentType.VeryNegative:
        return 'sentiment:[0 TO 0.8]';
      case ArticleSentimentType.Negative:
        return 'sentiment:[0.81 TO 1.60]';
      case ArticleSentimentType.Neutral:
        return 'sentiment:[1.61 TO 2.40]';
      case ArticleSentimentType.Positive:
        return 'sentiment:[2.41 TO 3.20]';
      case ArticleSentimentType.VeryPositive:
        return 'sentiment:[3.21 TO 4.00]';
    }
  }

  public mapRelevancyScoreToDisplay(relevancyPercentage: number): RelevancyType {
    if (relevancyPercentage < this.relevancyRange[1]) {
      return RelevancyType.Irrelevant;
    }

    if (relevancyPercentage < this.relevancyRange[2]) {
      return RelevancyType.SomewhatRelevant;
    }

    if (relevancyPercentage < this.relevancyRange[3]) {
      return RelevancyType.Relevant;
    }

    if (relevancyPercentage < this.relevancyRange[4]) {
      return RelevancyType.VeryRelevant;
    }
  }

  public getSentimentButtonColor(sentiment: string): string {
    switch (sentiment) {
      case ArticleSentimentType.VeryPositive:
        return SentimentColorType.VeryPositiveColor;

      case ArticleSentimentType.Positive:
        return SentimentColorType.PositiveColor;

      case ArticleSentimentType.Neutral:
        return SentimentColorType.NeutralColor;

      case ArticleSentimentType.Negative:
        return SentimentColorType.NegativeColor;

      case ArticleSentimentType.VeryNegative:
        return SentimentColorType.VeryNegativeColor;

      default:
        return SentimentColorType.NeutralColor;
    }
  }

  public getRelevancyButtonColor(relevancy: number, customRelevancyRange: number[] = null): string {
    const relevancyRangeToUse = customRelevancyRange ? customRelevancyRange : this.relevancyBtnColorRange;

    if (relevancy <= relevancyRangeToUse[0]) {
      return RelevancyChartButtonColors.RelevantVeryLow;
    }

    if (relevancy <= relevancyRangeToUse[1]) {
      return RelevancyChartButtonColors.RelevantLow;
    }

    if (relevancy <= relevancyRangeToUse[2]) {
      return RelevancyChartButtonColors.Relevant;
    }

    if (relevancy <= relevancyRangeToUse[3]) {
      return RelevancyChartButtonColors.RelevantHigh;
    }

    if (relevancy <= relevancyRangeToUse[4]) {
      return RelevancyChartButtonColors.RelevantVeryHigh;
    }
  }

  public getRelevancyLabel(relevancy: number, customRelevancyRange: number[] = null): string {
    const relevancyRangeToUse = customRelevancyRange ? customRelevancyRange : this.relevancyBtnColorRange;

    if (relevancy <= relevancyRangeToUse[0]) {
      return RelevancyType.Irrelevant;
    }

    if (relevancy <= relevancyRangeToUse[1]) {
      return RelevancyType.SomewhatRelevant;
    }

    if (relevancy <= relevancyRangeToUse[2]) {
      return RelevancyType.Neutral;
    }

    if (relevancy <= relevancyRangeToUse[3]) {
      return RelevancyType.Relevant;
    }

    if (relevancy <= relevancyRangeToUse[4]) {
      return RelevancyType.VeryRelevant;
    }
  }

  // Check for relevancy filter
  public isRelevancyFilterActive(allFilters: IFilter[], type: RelevancyType): boolean {
    return !!allFilters.find((filter: IFilter) => filter.value === (type as string));
  }

  // Check for sentiment filter
  public isSentimentFilterActive(allFilters: IFilter[], type: ArticleSentimentType): boolean {
    return !!allFilters.find((filter: IFilter) => filter.value === (type as string));
  }

  public getSentimentPercentage(sentimentValue = 0): number {
    const maxPossibleSentiment = 5;

    return (sentimentValue / maxPossibleSentiment) * 100;
  }

  public getRelevancyPercentage(relevancyValue = 0): number {
    const maxPossibleRelevancy = 5;
    const minPossibleRelevancy = 1;

    return ((minPossibleRelevancy + relevancyValue) / maxPossibleRelevancy) * 100;
  }

  public getRelevancyFilter(activeFilters: IFilter[]): string {
    const minRelevancy: number = Math.min(
      ...activeFilters.map((filter: IFilter) => {
        const relevancyFilterMetadata: ISentimentHist = <ISentimentHist>filter.metadata;

        return relevancyFilterMetadata.min;
      })
    );

    const maxRelevancy: number = Math.max(
      ...activeFilters.map((filter: IFilter) => {
        const relevancyFilterMetadata: ISentimentHist = <ISentimentHist>filter.metadata;

        return relevancyFilterMetadata.max;
      })
    );

    return `score:[${minRelevancy}  TO ${maxRelevancy}]`;
  }

  public getSentimentFilter(activeFilters: IFilter[]): string {
    const minSentiment: number = Math.min(
      ...activeFilters.map((filter: IFilter) => {
        const relevancyFilterMetadata: ISentimentHist = <ISentimentHist>filter.metadata;

        return relevancyFilterMetadata.min;
      })
    );

    const maxSentiment: number = Math.max(
      ...activeFilters.map((filter: IFilter) => {
        const relevancyFilterMetadata: ISentimentHist = <ISentimentHist>filter.metadata;

        return relevancyFilterMetadata.max;
      })
    );

    return `sentiment:[${minSentiment} TO ${maxSentiment}]`;
  }

  /**
   * Relative calculations
   */

  public getMinMaxSentimentInTuneup(tuneup: IDatasetTuneupData): IMinMaxRange {
    const minMax: IMinMaxRange = {
      max: 0,
      min: 0
    };

    minMax.max = Math.max(
      ...tuneup.sentiment_hist.map((sentimentBucket: ISentimentHist) => {
        return sentimentBucket.max;
      })
    );

    minMax.min = Math.min(
      ...tuneup.sentiment_hist.map((sentimentBucket: ISentimentHist) => {
        return sentimentBucket.min;
      })
    );

    return minMax;
  }

  public getMinMaxRelevancyInTuneup(tuneup: IDatasetTuneupData): IMinMaxRange {
    const minMax: IMinMaxRange = {
      max: 0,
      min: 0
    };

    minMax.max = Math.max(
      ...tuneup.relevancy_hist.map((relevancyBucket: IRelevancyHist) => {
        return relevancyBucket.max;
      })
    );

    minMax.min = Math.min(
      ...tuneup.relevancy_hist.map((relevancyBucket: IRelevancyHist) => {
        return relevancyBucket.min;
      })
    );

    return minMax;
  }

  public getRelativeMinMaxSentiment(value: number, minMaxRange: IMinMaxRange): ArticleSentimentType {
    const availableBuckets: ArticleSentimentType[] = [
      ArticleSentimentType.VeryNegative,
      ArticleSentimentType.Negative,
      ArticleSentimentType.Neutral,
      ArticleSentimentType.Positive,
      ArticleSentimentType.VeryPositive
    ];

    let bucketIndex = Math.round(minMaxRange.min + value / minMaxRange.max);

    if (bucketIndex >= availableBuckets.length) {
      bucketIndex = availableBuckets.length - 1;
    }

    return availableBuckets[bucketIndex];
  }

  public getSentimentLabel(sentiment: number): ArticleSentimentType {
    if (sentiment <= this.sentimentRange[1]) {
      return ArticleSentimentType.VeryNegative;
    }
    if (sentiment > this.sentimentRange[1] && sentiment <= this.sentimentRange[2]) {
      return ArticleSentimentType.Negative;
    }
    if (sentiment > this.sentimentRange[2] && sentiment <= this.sentimentRange[3]) {
      return ArticleSentimentType.Neutral;
    }
    if (sentiment > this.sentimentRange[3] && sentiment <= this.sentimentRange[4]) {
      return ArticleSentimentType.Positive;
    }
    if (sentiment > this.sentimentRange[4]) {
      return ArticleSentimentType.VeryPositive;
    }
  }

  private tuneupRelevancyFromValues(values: number[], bucketsNumber = 5): IApiSentimentRelevancyBucket[] {
    const histogramData: IHistogram[] = makeHistogram(values, bucketsNumber);

    const total = values.length;
    let cumProb = 0.0;

    const buckets: IApiSentimentRelevancyBucket[] = histogramData.map((histogram: IHistogram) => {
      const prob = histogram.N / total;
      cumProb += prob;

      const stdev = Math.sqrt(histogram.var);

      const bucket: IApiSentimentRelevancyBucket = {
        max: histogram.max,
        min: histogram.min,
        mean: histogram.mean,
        N: histogram.N,
        sum: histogram.sum,
        var: histogram.var,
        prob,
        cumProb,
        stdev
      };

      return bucket;
    });

    return buckets;
  }

  private tuneupSentimentsFromValues(values: number[]): IApiSentimentRelevancyBucket[] {
    const buckets: IApiSentimentRelevancyBucket[] = [];

    for (let i = 0; i < this.sentimentRange.length - 1; i++) {
      const minRange = this.sentimentRange[i];
      const maxRange = this.sentimentRange[i + 1];

      const count = values.filter((x) => {
        return x > minRange && x <= maxRange;
      }).length;

      const bucket: IApiSentimentRelevancyBucket = {
        max: maxRange,
        min: minRange,
        mean: 0,
        N: count,
        sum: 0,
        var: 0,
        prob: 0,
        cumProb: 0,
        stdev: 0
      };
      buckets.push(bucket);
    }

    return buckets;
  }
}
