import { createReducer, on } from '@ngrx/store';
import { ISearchSessionEvent } from '../../interfaces/search-session-event.interface';
import * as eventActions from './event.actions';

export const searchSessionEventFeatureKey = 'Events';

export interface INeuEvent {
  isSearchStart: boolean;
  isSearchSessionStart: boolean;
  eventStartTime: Date;
  searchSessionEventData: ISearchSessionEvent;
}

export const initialEventState: INeuEvent = {
  isSearchStart: false,
  isSearchSessionStart: false,
  eventStartTime: undefined,
  searchSessionEventData: {
    query: '',
    querySavedTopic: false,
    filterSource: '',
    filterKeywords: '',
    filerDate: '',
    layoutType: '',
    sortType: '',
    duration: '00:00:00'
  }
};

export const eventReducer = createReducer(
  initialEventState,
  on(eventActions.setSearchSessionEventStartState, (state, { isEventStart }) => {
    const eventState = { ...state };
    eventState.isSearchSessionStart = isEventStart;
    if (isEventStart) {
      eventState.eventStartTime = new Date();
    }

    return eventState;
  }),
  on(eventActions.setSearchSessionQuery, (state, { searchQuery }) => {
    const newEventState: INeuEvent = { ...state };
    const session: ISearchSessionEvent = { ...newEventState.searchSessionEventData };

    session.query = searchQuery;
    newEventState.searchSessionEventData = session;

    return newEventState;
  }),
  on(eventActions.setSearchSessionSortType, (state, { sortType }) => {
    const newEventState: INeuEvent = { ...state };
    const session: ISearchSessionEvent = { ...newEventState.searchSessionEventData };

    session.sortType = sortType;
    newEventState.searchSessionEventData = session;

    return newEventState;
  }),
  on(eventActions.setSearchSessionfilterDate, (state, { filterDate }) => {
    const newEventState: INeuEvent = { ...state };
    const session: ISearchSessionEvent = { ...newEventState.searchSessionEventData };

    session.filerDate = filterDate;
    newEventState.searchSessionEventData = session;

    return newEventState;
  }),
  on(eventActions.setSearchSessionFilterSources, (state, { sources }) => {
    const newEventState: INeuEvent = { ...state };
    const session: ISearchSessionEvent = { ...newEventState.searchSessionEventData };

    session.filterSource = sources;
    newEventState.searchSessionEventData = session;

    return newEventState;
  }),
  on(eventActions.setSearchSessionFilterKeywords, (state, { keywords }) => {
    const newEventState: INeuEvent = { ...state };
    const session: ISearchSessionEvent = { ...newEventState.searchSessionEventData };

    session.filterKeywords = keywords;
    newEventState.searchSessionEventData = session;

    return newEventState;
  }),
  on(eventActions.setSearchSessionSelectedLayoutType, (state, { selectedLayout }) => {
    const newEventState: INeuEvent = { ...state };
    const session: ISearchSessionEvent = { ...newEventState.searchSessionEventData };

    session.layoutType = selectedLayout;
    newEventState.searchSessionEventData = session;

    return newEventState;
  }),
  on(eventActions.setSearchSessionSaveTopicState, (state, { isTopicSave }) => {
    const newEventState: INeuEvent = { ...state };
    const session: ISearchSessionEvent = { ...newEventState.searchSessionEventData };

    session.querySavedTopic = isTopicSave;
    newEventState.searchSessionEventData = session;

    return newEventState;
  }),
  on(eventActions.setIsSearchExecute, (state, { isSearchStart }) => {
    const newEventState: INeuEvent = { ...state };
    newEventState.isSearchStart = isSearchStart;

    return newEventState;
  }),
  on(eventActions.setSearchSessionDuration, (state, { sessionDuration }) => {
    const newEventState: INeuEvent = { ...state };
    const session: ISearchSessionEvent = { ...newEventState.searchSessionEventData };

    session.duration = sessionDuration;
    newEventState.searchSessionEventData = session;

    return newEventState;
  }),
  on(eventActions.resetSearchSessionEvent, () => {
    return initialEventState;
  })
);
