import { createAction, props } from '@ngrx/store';
import { RelevancyType } from 'src/app/news/enums';
import { RequestedFilterAnimationType } from '../core/enums';
import { IFilter } from '../core/interfaces';

// ------------------- Main ------------------- //

// Sidebar keyword filter

export const setActiveKeywordFilters = createAction(
  '[Filters] Set the active keyword filters',
  props<{ filters: IFilter[] }>()
);

export const setAvailableKeywords = createAction(
  '[Filters] Set the available keywords',
  props<{ keywords: IFilter[] }>()
);

export const toggleActiveKeywordFilter = createAction(
  '[Filters] Toggle a keyword',
  props<{ keyword: IFilter; isActive?: boolean }>()
);

export const clearAllKeywordsFilters = createAction('[Filters] Remove active keyword filters');

export const setIsLoadingKeywords = createAction('[Filters] Set is loading keywords', props<{ isLoading: boolean }>());

export const setCategoryFilter = createAction(
  '[Filters] Set the category filters',
  props<{ categoryFilters: string[] }>()
);

// Query for filters

export const toggleKeywordForQueryFilter = createAction(
  '[Filters] Toggle a keyword for query filter',
  props<{ keyword: IFilter; isActive?: boolean }>()
);

export const removeActiveFilter = createAction('[Filters] Remove active filter', props<{ filter: IFilter }>());
export const addActiveFilter = createAction('[Filters] Add active filter', props<{ filter: IFilter }>());

export const addMultipleActiveFilter = createAction('[Filters] Add multiple filter', props<{ filters: IFilter[] }>());

export const requestFilterAnimation = createAction(
  '[Filters] Request filter animation',
  props<{ animationType: RequestedFilterAnimationType }>()
);

// Sentiment Filter
export const setSentimentFilters = createAction(
  '[RelatedArticles] Add Sentiment Filter',
  props<{ sentimentFilters: IFilter[] }>()
);

export const addRemoveSentimentFilters = createAction(
  '[RelatedArticles]  Add or Remove Sentiment Filter',
  props<{ sentimentFilter: IFilter }>()
);

// Relevancy Filter
export const setActiveRelevancyFilters = createAction(
  '[RelatedArticles] Add Relevancy Filter',
  props<{ relevancyFilters: IFilter[] }>()
);

export const toggleRelevancyFilterByType = createAction(
  '[RelatedArticles] Toggle Relevancy Filter by type',
  props<{ relevancyFilterType: RelevancyType; shouldBeActive?: boolean }>()
);
// Related articles filtering
export const addRemoveRelevancyFilter = createAction(
  '[RelatedArticles] Add or Remove Relevancy Filter',
  props<{ relevancyFilter: IFilter }>()
);
