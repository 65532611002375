import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISourcesFiltersState, sourcesFiltersFeatureKey } from './sources-filters-state.reducer';

export const selectSourcesFiltersState = createFeatureSelector<ISourcesFiltersState>(sourcesFiltersFeatureKey);

// Sources
export const selectAvailableSources = createSelector(
  selectSourcesFiltersState,
  (sourcesFiltersState) => sourcesFiltersState.availableSources
);

export const selectActiveSelectedSource = createSelector(
  selectSourcesFiltersState,
  (sourcesFiltersState) => sourcesFiltersState.selectedActiveSources
);

export const selectSourcesWithContentInDatasetCount = createSelector(
  selectSourcesFiltersState,
  (sourcesFiltersState) => sourcesFiltersState.sourcesWithContentInDatasetCount
);

// Circulation
export const selectAvailableCirculationFilters = createSelector(
  selectSourcesFiltersState,
  (sourcesFiltersState) => sourcesFiltersState.availableCirculationFilters
);

export const selectActiveCirculationFilters = createSelector(
  selectSourcesFiltersState,
  (sourcesFiltersState) => sourcesFiltersState.activeCirculationFilters
);

// Publisher type
export const selectAvailablePublisherTypeFilters = createSelector(
  selectSourcesFiltersState,
  (sourcesFiltersState) => sourcesFiltersState.availablePublisherTypeFilters
);

export const selectActivePublisherTypeFilters = createSelector(
  selectSourcesFiltersState,
  (sourcesFiltersState) => sourcesFiltersState.activePublisherTypeFilters
);

// Publisher state
export const selectActivePublisherStateFilters = createSelector(
  selectSourcesFiltersState,
  (sourcesFiltersState) => sourcesFiltersState.activePublisherStateFilters
);
