import { createReducer, on } from '@ngrx/store';
import { IProfileSessionEvent } from '../../interfaces/profile-session-event.interface';
import * as profileEventActions from './profile-event-action';

export const profileSessionEventFeatureKey = 'ProfileEvent';

export interface INeuProfileEvent {
  isProfileEventStart: boolean;
  eventStartTime: Date;
  profileSessionEventData: IProfileSessionEvent;
}

export const initialProfileEventState: INeuProfileEvent = {
  isProfileEventStart: false,
  eventStartTime: undefined,
  profileSessionEventData: {
    fieldsEdited: '',
    duration: '',
    isPasswordChange: false
  }
};

export const profileEventReducer = createReducer(
  initialProfileEventState,
  on(profileEventActions.setProfileSessionStartState, (state, { isProfileEventStart }) => {
    const profileEventState: INeuProfileEvent = { ...state };
    profileEventState.isProfileEventStart = isProfileEventStart;

    if (isProfileEventStart) {
      profileEventState.eventStartTime = new Date();
    }

    return profileEventState;
  }),

  on(profileEventActions.setProfileSessionEditedFields, (state, { editedFields }) => {
    const profileEventState: INeuProfileEvent = { ...state };
    const profileSessionData = { ...profileEventState.profileSessionEventData };

    profileSessionData.fieldsEdited = editedFields;
    profileEventState.profileSessionEventData = profileSessionData;

    return profileEventState;
  }),

  on(profileEventActions.setProfileSessionPasswordChangeStatus, (state, { isPasswordChange }) => {
    const profileEventState: INeuProfileEvent = { ...state };
    const profileSessionData = { ...profileEventState.profileSessionEventData };

    profileSessionData.isPasswordChange = isPasswordChange;
    profileEventState.profileSessionEventData = profileSessionData;

    return profileEventState;
  }),

  on(profileEventActions.setProfileSessionDuration, (state, { sessionDuration }) => {
    const profileEventState: INeuProfileEvent = { ...state };
    const profileSessionData = { ...profileEventState.profileSessionEventData };

    profileSessionData.duration = sessionDuration;
    profileEventState.profileSessionEventData = profileSessionData;

    return profileEventState;
  }),

  on(profileEventActions.resetProfileSessionEvent, () => {
    return initialProfileEventState;
  })
);
