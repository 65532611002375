import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, zip } from 'rxjs';
import { debounceTime, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { selectUserId } from '../auth/store-auth/auth.selectors';
import { DEFAULT_NEWS_QUERY } from '../core/constants/default-query';
import { SortType } from '../core/filters/core/enums';
import { setIsLoadingKeywords } from '../core/filters/filters-store/filters.actions';
import {
  selectActiveKeywordFilters,
  selectActiveKeywordsForQueryFilters,
  selectActiveRelevancyFilters,
  selectActiveSentimentFilters,
  selectCategoryFilters
} from '../core/filters/filters-store/filters.selectors';
import { FilterKeywordsService } from '../core/filters/keywords/filter-keywords.service';
import { QueryFiltersService } from '../core/filters/query-filters.service';
import { buildFilterFromSelectedSources } from '../core/filters/sources/core/utils';
import {
  selectActiveSelectedSource,
  selectAvailableSources
} from '../core/filters/sources/sources-filters-store/sources-filters.selectors';
import { NetworkLRUCachedObservable } from '../core/models';
import { SentimentRelevancyService } from '../core/services/sentiment-relevancy.service';
import { IAppState } from '../core/store-app/reducers';
import { JSONstringifyOrder, secureObjectCopy, solrDefaultDateFormatting } from '../core/utils';
import { hashCode } from '../core/utils/hash-string';
import { IDateFilter } from '../news-filters/core/interfaces';
import {
  selectDateFilter,
  selectTrendingTopicFiltersForNews
} from '../news-filters/store-filters/filters-state.selectors';
import {
  INewsApi,
  INewsApiV2,
  INewsDataset,
  INewsItemApi,
  INewsPagination,
  INewsRequestV2,
  INewsTerm,
  IPage,
  IStackNewsItemsResults,
  ITermsDataset
} from '../news/core/interfaces';
import { selectNewsTagFilters } from '../news/news-tagging/news-tags-store/news-tags.selectors';
import { SameNewsDifferentSourceService } from '../news/services/same-news-different-sources.service';
import { SetReadingListCount } from '../news/store-news/news.actions';
import {
  selectAllFiltersForQuery,
  selectCurrentPage,
  selectCurrentSearchQuery,
  selectCurrentSortDirection,
  selectCurrentSortOrder
} from '../news/store-news/news.selectors';
import { ApiPublisherService } from './api-publisher.service';
import { ApiTuningService } from './api-tuning.service';
import { IGenericObservedFilters, IObservedParamsForNews } from './core/interfaces';
import { ObservableParamsForNews } from './core/types';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiNewsService {
  public tuneupParallelRequest$ = new Subject<Partial<INewsRequestV2>>();

  private pageSkipCounts: any = new Map();
  private newsParameters: INewsRequestV2 = {
    start: 0,
    rows: 10,
    filter: [],
    query: '',
    sort: 'neutrality_article_normpubDate DESC',
    rawFilters: [null, null]
  };
  private currentPage$: Observable<IPage>;
  private userId$: Observable<string>;
  private filters$: Observable<IGenericObservedFilters>;
  private currentSortOrder$: Observable<INewsPagination>;
  private currentSortDirection$: Observable<string>;
  private currentQuery$: Observable<string>;
  private dateFilters$: Observable<IDateFilter>;

  private newsRequestCache$: NetworkLRUCachedObservable<INewsApiV2>;
  private cache$ = new NetworkLRUCachedObservable<INewsApiV2>(100, 5 * 60 * 1000);

  constructor(
    private http: HttpClient,
    private store: Store<IAppState>,
    private sameNewsDifferentSourceService: SameNewsDifferentSourceService,
    private queryFiltersService: QueryFiltersService,
    private sentimentRelevancyService: SentimentRelevancyService,
    private filterKeywordsService: FilterKeywordsService,
    private newsApiTuningService: ApiTuningService,
    private apiPublisherService: ApiPublisherService
  ) {
    this.initGenericStringFilters();

    const cacheSizeLimit = 50; // 50 items in the LRU cache
    const cacheObjectEvictionTimeInMilliseconds = 2 * 60 * 1000; // 2 minutes of persistence in the cache

    this.newsRequestCache$ = new NetworkLRUCachedObservable<INewsApiV2>(
      cacheSizeLimit,
      cacheObjectEvictionTimeInMilliseconds
    );
  }

  // This is the main method that will sub to all filters, params, and details to get the final news to display
  public getNews(): Observable<INewsDataset> {
    return this.getNewsParamsObservable()
      .pipe(take(1))
      .pipe(
        withLatestFrom(this.filters$),
        switchMap(([newsParams, filters]: [IObservedParamsForNews, IGenericObservedFilters]) => {
          const currentPage: IPage = newsParams.currentPage;
          const currentSortOrder: INewsPagination = newsParams.currentSortOrder;
          let startAdjust = 0;

          // Walk previous pages to accumulate extra skip for rows
          for (const value of this.pageSkipCounts.entries()) {
            if (value[0] < currentPage.pageIndex) {
              startAdjust += value[1];
            }
          }

          const sortOrder: string = currentSortOrder ? currentSortOrder.sort : SortType.Date;
          const sortDirection: string = newsParams.currentSortDirection ? newsParams.currentSortDirection : 'DESC';

          // Init the news params to make the API request
          this.newsParameters = {
            start: currentPage.pageIndex * currentPage.pageSize + startAdjust,
            rows: currentPage.pageSize,
            sort: sortOrder + sortDirection,
            filter: [],
            query: DEFAULT_NEWS_QUERY,
            rawFilters: [newsParams, filters]
          };

          this.newsParameters.query = this.queryFiltersService.buildQuery(
            newsParams.currentQuery,
            newsParams.trendingTopicFiltersForNews
          );

          this.newsParameters.filter = this.queryFiltersService.buildFilters(
            [...filters.queryFilters],
            newsParams.dateFilters
          );

          const publisherFilters: string[] = buildFilterFromSelectedSources(newsParams.selectedActiveSourcesFilters);
          this.newsParameters.filter = [...this.newsParameters.filter, ...publisherFilters];

          // Keyword FOR query (main)
          this.newsParameters.filter = [
            ...this.newsParameters.filter,
            ...this.filterKeywordsService.buildKeywordFilters(newsParams.activeKeywordsForQueryFilters)
          ];

          // Extended keyword filters

          this.newsParameters.query = this.filterKeywordsService.updateQueryFromKeywords(
            this.newsParameters.query,
            newsParams.activeKeywordsFilters
          );

          // Fix improper uuid:*
          if (this.newsParameters.query === 'uuid:*') {
            this.newsParameters.query = '*';
          }

          // For improper date filter handling
          this.newsParameters.filter = this.newsParameters.filter.filter((filter: string) => {
            if (filter === 'neutrality_article_normpubDate:[* TO NOW]') {
              return false;
            }

            return true;
          });

          // Relevancy / Sentiment
          if (newsParams.relevancyFilters.length) {
            const relevancyFilter: string = this.sentimentRelevancyService.getRelevancyFilter(
              newsParams.relevancyFilters
            );

            this.newsParameters.filter = [...this.newsParameters.filter, relevancyFilter];
          }

          if (newsParams.sentimentFilters.length) {
            const sentimentFilters: string = this.sentimentRelevancyService.getSentimentFilter(
              newsParams.sentimentFilters
            );

            this.newsParameters.filter = [...this.newsParameters.filter, sentimentFilters];
          }

          if (newsParams.tagFilters.length) {
            // Will remove all other filters... ?
            this.newsParameters.filter = [...this.newsParameters.filter, ...newsParams.tagFilters];
          }

          if (newsParams.categoryFilters) {
            this.newsParameters.filter = [...this.newsParameters.filter, ...newsParams.categoryFilters];
          }

          const mainNewsRequest: Observable<INewsDataset> = this.mainNewsRequest(
            this.newsParameters,
            newsParams.userId,
            sortOrder,
            currentPage
          );

          this.tuneupParallelRequest$.next(this.newsParameters);

          return mainNewsRequest.pipe(
            tap((news) => {
              this.store.dispatch(SetReadingListCount({ count: news.readingListRecCount }));
            })
          );
        })
      );
  }

  public getTuneupKeywords(paramsGiven: Partial<INewsRequestV2> = null, openmindId: string = undefined) {
    return this.tuneupParallelRequest$.pipe(
      debounceTime(200),
      switchMap((params: INewsRequestV2) => {
        const sourcesAcceptedFilters: string[] = ['neutrality_article_normpubDate:'];

        let requestFilters: string[] = params.filter || [];

        requestFilters = requestFilters.filter((filter: string) => {
          return sourcesAcceptedFilters.includes(filter.split(':')[0] + ':');
        });

        if (params.rawFilters) {
          const generatedKwFilters: string = this.queryFiltersService.generateFilterString(
            'neutrality_search',
            params.rawFilters[0].activeKeywordsFilters.map((v) => v.value)
          );

          if (generatedKwFilters?.length > 0) {
            requestFilters.push(generatedKwFilters);
          }
        }

        const tuneupRequestGeneratedParams: Partial<INewsRequestV2> = {
          filter: requestFilters,
          query: params?.query ? params?.query : `(id:"${openmindId}")`,
          id: params.id
        };

        const shouldForce = true;
        this.newsApiTuningService.setTuneupRequestGeneratedParamsIfNull(tuneupRequestGeneratedParams, shouldForce);

        return this.requestKeywords(paramsGiven || params, openmindId);
      })
    );
  }

  public getNewsArticle(articleId: string): Observable<INewsApiV2> {
    // Init the news params to make the API request
    this.newsParameters = {
      start: 0,
      rows: 1,
      sort: 'neutrality_article_normpubDate DESC',
      filter: [],
      query: 'id:' + articleId,
      rawFilters: [null, null]
    };

    return this.http.post<INewsApiV2>(`${environment.API_ENDPOINT}/news/news-data`, this.newsParameters);
  }

  public getReadingListByUser(userId: string): Observable<INewsApiV2> {
    // Init the news params to make the API request
    this.newsParameters = {
      start: 0,
      rows: 10000,
      sort: 'neutrality_article_normpubDate DESC',
      filter: [`reading_list_users:"${userId}"`],
      query: '*',
      rawFilters: [null, null]
    };

    return this.http.post<INewsApiV2>(`${environment.API_ENDPOINT}/news/news-data`, this.newsParameters);
  }

  public getTopNewsArticleByCurrentSort(startDate: Date, endDate: Date): Observable<INewsApiV2> {
    const key = `getTopNewsArticleByCurrentSort@${startDate.toISOString()}@${endDate.toISOString()}}`;

    const query: Observable<INewsApiV2> = this.getNewsParamsObservable()
      .pipe(take(1))
      .pipe(
        switchMap((newsParams: IObservedParamsForNews) => {
          const currentSortOrder: INewsPagination = newsParams.currentSortOrder;
          const sortOrder: string = currentSortOrder ? currentSortOrder.sort : SortType.Date;
          const sortDirection: string = newsParams.currentSortDirection ? newsParams.currentSortDirection : 'DESC';

          this.newsParameters = {
            start: 0,
            rows: 1,
            sort: sortOrder + sortDirection,
            filter: [
              `neutrality_article_normpubDate:[${moment(startDate)
                .startOf('day')
                .format(solrDefaultDateFormatting)} TO ${moment(endDate)
                .endOf('day')
                .format(solrDefaultDateFormatting)}]`
            ],
            query: newsParams.currentQuery,
            rawFilters: [null, null]
          };

          return this.http.post<INewsApiV2>(`${environment.API_ENDPOINT}/news/news-data`, this.newsParameters);
        })
      );

    return this.cache$.next(key, query);
  }

  /**
   *  Extra helper methods
   */
  public requestKeywords(params: Partial<INewsRequestV2>, openmindId: string = undefined) {
    if (!params.query) {
      params.query = `(id:"${openmindId}")`;
    }

    this.store.dispatch(setIsLoadingKeywords({ isLoading: true }));

    return this.newsApiTuningService.requestTuneupKeywords(params).pipe(
      startWith(null),
      tap(() => {
        this.store.dispatch(setIsLoadingKeywords({ isLoading: false }));
      })
    );
  }

  public getNewsParamsObservable(shouldExposeAvailableSourcesFilters = false): ObservableParamsForNews {
    const observablesForNewsRequests: ObservableParamsForNews = combineLatest({
      // User related
      userId: this.userId$,
      // Pagination related
      currentPage: this.currentPage$,
      currentSortOrder: this.currentSortOrder$,
      currentSortDirection: this.currentSortDirection$,
      // Query related
      currentQuery: this.currentQuery$,
      // Filter related
      dateFilters: this.dateFilters$,
      // Sources
      selectedActiveSourcesFilters: this.store.pipe(select(selectActiveSelectedSource)),
      availableSourcesFilters: shouldExposeAvailableSourcesFilters
        ? this.store.pipe(select(selectAvailableSources))
        : of(undefined),
      // Topics
      trendingTopicFiltersForNews: this.store.pipe(select(selectTrendingTopicFiltersForNews)),
      activeKeywordsFilters: this.store.pipe(select(selectActiveKeywordFilters)),
      activeKeywordsForQueryFilters: this.store.pipe(select(selectActiveKeywordsForQueryFilters)),
      // Relevancy/Sentiment
      relevancyFilters: this.store.pipe(select(selectActiveRelevancyFilters)),
      sentimentFilters: this.store.pipe(select(selectActiveSentimentFilters)),
      // Top and saved stories filter
      tagFilters: this.store.pipe(select(selectNewsTagFilters)),
      categoryFilters: this.store.pipe(select(selectCategoryFilters))
    });

    return observablesForNewsRequests;
  }

  private initGenericStringFilters() {
    this.userId$ = this.store.pipe(select(selectUserId));
    this.currentPage$ = this.store.pipe(select(selectCurrentPage));
    this.currentSortOrder$ = this.store.pipe(select(selectCurrentSortOrder));
    this.currentSortDirection$ = this.store.pipe(select(selectCurrentSortDirection));
    this.currentQuery$ = this.store.pipe(select(selectCurrentSearchQuery));
    this.dateFilters$ = this.store.pipe(select(selectDateFilter));

    this.filters$ = combineLatest({
      queryFilters: this.store.pipe(select(selectAllFiltersForQuery))
    });
  }

  private mainNewsRequest(
    newsParameters: INewsRequestV2,
    userId: string,
    sortOrder: string,
    currentPage: IPage
  ): Observable<INewsDataset> {
    const paramsForNews: INewsRequestV2 = secureObjectCopy(newsParameters);
    delete paramsForNews.rawFilters;

    let accumulatedSkippedElements = 0;
    let countedPagesIndex: number[] = [];

    const cacheKey: string = hashCode(JSONstringifyOrder(paramsForNews)).toString();

    const newsRequest = this.http.post<INewsApiV2>(`${environment.API_ENDPOINT}/news/news-data`, paramsForNews);

    const hasReadingListFilter = newsParameters.filter.find((filter: string) => filter.includes('reading_list_users'));

    if (hasReadingListFilter) {
      this.newsRequestCache$.cache.clear();
    }

    return zip([this.apiPublisherService.waitForLoadPublisher(), this.newsRequestCache$.next(cacheKey, newsRequest)])
      .pipe(map(([, apiNewsV2]: [unknown, INewsApiV2]) => apiNewsV2))
      .pipe(
        map((apiNewsV2: INewsApiV2) => {
          let newsDataSet: INewsDataset = {
            totalItems: 0,
            readingListRecCount: 0,
            newsItems: [],
            terms: [],
            requestParams: secureObjectCopy(newsParameters) as INewsRequestV2,
            facets: null
          };

          const newResults: INewsItemApi[] = apiNewsV2.docs;
          if (newResults) {
            const apiNews: INewsApi = {
              docs: newResults,
              reading_list_count: apiNewsV2.readingListRecCount,
              total_count: apiNewsV2.numFound
            };

            const mergeStacksResult: IStackNewsItemsResults =
              this.sameNewsDifferentSourceService.mergeDatasetRedundantElements(apiNews, userId);

            // under post method
            if (currentPage.pageIndex === 0) {
              // Starting or going back to first page
              accumulatedSkippedElements = mergeStacksResult.skippedElements;
              countedPagesIndex = [0];
            } else {
              if (!countedPagesIndex.includes(currentPage.pageIndex)) {
                accumulatedSkippedElements += mergeStacksResult.skippedElements;
                countedPagesIndex.push(currentPage.pageIndex);
              }
            }

            newsDataSet = {
              totalItems: apiNews.total_count - accumulatedSkippedElements,
              readingListRecCount: apiNews.reading_list_count,
              newsItems: mergeStacksResult.newsItems.slice(0, currentPage.pageSize),
              terms: apiNewsV2?.terms.map((term: INewsTerm) => {
                const updatedTerm: ITermsDataset = {
                  facetCount: term.count,
                  termName: term.term
                };

                return updatedTerm;
              }),
              requestParams: secureObjectCopy(newsParameters) as INewsRequestV2,
              facets: apiNewsV2.facets
            };

            this.pageSkipCounts.set(currentPage.pageIndex, mergeStacksResult.skippedElements);

            return newsDataSet;
          } else {
            return newsDataSet;
          }
        })
      );
  }
}
