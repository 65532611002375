import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filtersFeatureKey, IFiltersState } from './filters.reducer';

export const selectFiltersState = createFeatureSelector<IFiltersState>(filtersFeatureKey);

export const selectActiveKeywordFilters = createSelector(
  selectFiltersState,
  (filtersState) => filtersState.activeKeywordFilters
);

export const selectCategoryFilters = createSelector(selectFiltersState, (filtersState) => filtersState.categoryFilters);

export const selectActiveKeywordsForQueryFilters = createSelector(
  selectFiltersState,
  (filtersState) => filtersState.activeKeywordForQueryFilters
);

export const selectAvailableKeywords = createSelector(
  selectFiltersState,
  (filtersState) => filtersState.availableKeywords
);

export const selectIsLoadingKeywords = createSelector(
  selectFiltersState,
  (filtersState) => filtersState.isLoadingKeywords
);

//
export const selectRequestedFilterAnimation = createSelector(
  selectFiltersState,
  (filtersState) => filtersState.requestedAnimation
);

export const selectActiveSentimentFilters = createSelector(
  selectFiltersState,
  (relatedArticlesState) => relatedArticlesState.activeSentimentFilters
);

export const selectActiveRelevancyFilters = createSelector(
  selectFiltersState,
  (relatedArticlesState) => relatedArticlesState.activeRelevancyFilters
);
