import { v4 as uuid } from 'uuid';
import {
  SearchCapturedData,
  LoginCapturedData,
  LogoutCapturedData,
  PasswordCapturedData,
  SignupCapturedData,
  NotificationClosedCapturedData,
  NotificationPanelToggleCapturedData,
  FeedbackClickedCapturedData,
  EmptyCapturedData,
  ForgotPasswordCapturedData,
  SavedTopicCapturedData
} from './captured-data-types';
import { NeuEventCategory, NeuEventType } from 'src/app/core/track-events/enum';
import { NeuInformativeNotification } from './notification-data-types';
import { NotificationRefreshedCapturedData } from './captured-data-types/notification/notifications-refresh-captured-data';
import { RouteCapturedData } from './captured-data-types/routes/route-captured-data';
import { ArticleCapturedData } from './captured-data-types/general';

export class NeutralityEvent {
  public readonly id?: string;
  public activityDate?: Date;
  public shouldBeSaved?: boolean;
  public initiatorUserID?: string;

  public eventCategory: NeuEventCategory;
  public eventType: NeuEventType | string;
  public capturedData: NeuCapturedData | NeuNotificationData;

  constructor(data: NeutralityEvent) {
    this.id = uuid() || 'NO ID';
    this.activityDate = new Date();
    this.shouldBeSaved = data.shouldBeSaved || false;
    this.eventCategory = data.eventCategory;
    this.eventType = data.eventType;
    this.capturedData = data.capturedData;
  }
}

export type NeuCapturedData =
  | EmptyCapturedData
  | SearchCapturedData
  | NeuAuthCapturedData
  | NeuNotificationCapturedData
  | NeuSidebarCapturedData
  | NeuOpenmindCapturedData
  | NeuRouteCapturedData
  | NeuSavedTopicFiltersCapturedData;

export type NeuAuthCapturedData =
  | LoginCapturedData
  | LogoutCapturedData
  | PasswordCapturedData
  | SignupCapturedData
  | ForgotPasswordCapturedData;

export type NeuNotificationCapturedData = NotificationClosedCapturedData | NotificationRefreshedCapturedData;

export type NeuSidebarCapturedData = NotificationPanelToggleCapturedData | FeedbackClickedCapturedData;

export type NeuOpenmindCapturedData = ArticleCapturedData;

export type NeuRouteCapturedData = RouteCapturedData;

export type NeuNotificationData = NeuInformativeNotification;

export type NeuSavedTopicFiltersCapturedData = SavedTopicCapturedData;
