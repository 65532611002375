import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QueryFiltersService } from '../core/filters/query-filters.service';
import { setAvailableSources } from '../core/filters/sources/sources-filters-store/sources-filters.actions';
import { CachedObservable } from '../core/models';
import { JSONstringifyOrder, secureObjectCopy } from '../core/utils';
import { INewsRequestV2 } from '../news/core/interfaces';
import { IDatasetTuneupPublisher, INewsTuneupDataKeywords } from './core/api-interfaces';
import { IApiRssDetails, IApiRssDetailsRequest, IApiSentimentRelevancyResponse } from './core/interfaces';
import { ApiPublisherService } from './api-publisher.service';

@Injectable({
  providedIn: 'root'
})
export class ApiTuningService {
  private tuneupRequestGeneratedParams: Partial<INewsRequestV2> = null;
  private childrenTuningCache$ = new CachedObservable<IApiRssDetailsRequest>();

  constructor(
    private queryFiltersService: QueryFiltersService,
    private http: HttpClient,
    private store: Store,
    private publisherMetadataService: ApiPublisherService
  ) {}

  public setTuneupRequestGeneratedParamsIfNull(
    updatedTuneupRequestGeneratedParams: Partial<INewsRequestV2>,
    shouldForce = false
  ) {
    if (this.tuneupRequestGeneratedParams && !shouldForce) {
      return;
    }

    this.tuneupRequestGeneratedParams = updatedTuneupRequestGeneratedParams;
  }

  public requestOpenmindTuneupSentiment(params: Partial<INewsRequestV2>): Observable<IApiSentimentRelevancyResponse> {
    const updatedParams: Partial<INewsRequestV2> = this.removeSentimentAndRelevancyFilters(params);

    const sentimentRequestRequest: Observable<IApiSentimentRelevancyResponse> =
      this.http.post<IApiSentimentRelevancyResponse>(`${environment.API_ENDPOINT}/tuneup/open-mind/sentiments`, {
        ...updatedParams
      });

    return sentimentRequestRequest;
  }

  public requestOpenmindTuneupRelevancy(params: Partial<INewsRequestV2>): Observable<IApiSentimentRelevancyResponse> {
    const updatedParams: Partial<INewsRequestV2> = this.removeSentimentAndRelevancyFilters(params);

    const relevancyRequestRequest: Observable<IApiSentimentRelevancyResponse> =
      this.http.post<IApiSentimentRelevancyResponse>(`${environment.API_ENDPOINT}/tuneup/open-mind/relevancy`, {
        ...updatedParams
      });

    return relevancyRequestRequest;
  }

  public requestTuneupKeywords(params: Partial<INewsRequestV2>) {
    const keywordsAcceptedFilters: string[] = [
      'neutrality_article_normpubDate:',
      'neutrality_pubRSS_ID:',
      'publisher_name:'
    ];
    let keywordFilters: string[] = params.filter || [];

    keywordFilters = keywordFilters.filter((filter: string) => {
      return keywordsAcceptedFilters.includes(filter.split(':')[0] + ':');
    });

    if (params.rawFilters) {
      const selfKwFilters: string = this.queryFiltersService.generateFilterString(
        'neutrality_search',
        params.rawFilters[0].activeKeywordsFilters.map((v) => v.value)
      );

      if (selfKwFilters?.length > 0) {
        keywordFilters.push(selfKwFilters);
      }
    }

    const keywordRequest: Observable<INewsTuneupDataKeywords> = this.http.post<INewsTuneupDataKeywords>(
      `${environment.API_ENDPOINT}/tuneup/keywords`,
      {
        filter: keywordFilters,
        query: params.query,
        id: params.id
      }
    );

    return keywordRequest;
  }

  public requestAllSources(): Observable<IDatasetTuneupPublisher[]> {
    return this.publisherMetadataService.requestAllPublishers().pipe(
      tap((sources: IDatasetTuneupPublisher[]) => {
        // Side effect
        this.store.dispatch(setAvailableSources({ sources }));
      })
    );
  }

  public requestChildrenTuning(publisherName: string): Observable<IApiRssDetails[]> {
    const pNameFilter = `+publisher_name:("${publisherName}")`;

    const modifiedRequestParams: Partial<INewsRequestV2> = secureObjectCopy(this.tuneupRequestGeneratedParams);

    modifiedRequestParams.filter = modifiedRequestParams.filter || [];
    modifiedRequestParams.filter.push(pNameFilter);

    const key: string = JSONstringifyOrder(modifiedRequestParams);

    return this.childrenTuningCache$
      .next(
        key,
        this.http.post<IApiRssDetailsRequest>(
          `${environment.API_ENDPOINT}/tuneup/publishers/rssmap-count`,
          modifiedRequestParams
        )
      )
      .pipe(
        map((sourcesRawRssResponse: IApiRssDetailsRequest) => {
          return sourcesRawRssResponse['result-set'].docs;
        })
      );
  }

  private removeSentimentAndRelevancyFilters(params: Partial<INewsRequestV2>): Partial<INewsRequestV2> {
    const updatedParams: Partial<INewsRequestV2> = {
      ...params,
      filter: (params?.filter || []).filter(
        (filter: string) => !filter.startsWith('score:') && !filter.startsWith('sentiment:')
      )
    };

    return updatedParams;
  }
}
