/**
 * Key order invariant serialization
 * since JSON.stringify is not order stable, meaning that two objects with the same keys
 * but in different order are considered not equals ex: {a:1,b:2}!={b:2,a:1}
 */
export function JSONstringifyOrder(obj: unknown): string {
  const allKeys = new Set<string>();
  JSON.stringify(obj, (key, value) => (allKeys.add(key), value));

  return JSON.stringify(obj, Array.from(allKeys).sort());
}

export const objectEquals = (item1: unknown, item2: unknown): boolean => {
  return JSONstringifyOrder(item1) === JSONstringifyOrder(item2);
};

export const isSameObject = (item1: unknown, item2: unknown): boolean => {
  return JSON.stringify(item1) === JSON.stringify(item2);
};
