import { createAction, props } from '@ngrx/store';

export const setProfileSessionStartState = createAction(
  '[profile-event] set profile event start state',
  props<{ isProfileEventStart: boolean }>()
);

export const setProfileSessionEditedFields = createAction(
  '[profile-event] set profile event edited fields',
  props<{ editedFields: string }>()
);
export const setProfileSessionPasswordChangeStatus = createAction(
  '[profile-event] set profile password change status',
  props<{ isPasswordChange: boolean }>()
);

export const setProfileSessionDuration = createAction(
  '[profile-event] set profile event duration',
  props<{ sessionDuration: string }>()
);

export const resetProfileSessionEvent = createAction('[profile-event] reset profile session');
