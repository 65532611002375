import { createAction, props } from '@ngrx/store';

export const setOpenmindSessionEventStartState = createAction(
  '[openmind-event] start openmind session event',
  props<{ isSessionStart: boolean }>()
);

export const setOpenMindSessionArticleId = createAction(
  '[openmind-event] set openmindArticleId',
  props<{ articleId: string }>()
);

export const setOpenmindFilterSources = createAction(
  '[openmind-event] set session filter sources',
  props<{ sources: string }>()
);

export const setOpenmindSessionFilterKeywords = createAction(
  '[openmind-event] set session filter keywords',
  props<{ keywords: string }>()
);

export const setOpenmindSessionSelectedLayoutType = createAction(
  '[openmind-event] set session selected layout type',
  props<{ selectedLayout: string }>()
);

export const setOpenmindSessionSortType = createAction(
  '[openmind-event] set session sort type',
  props<{ sortType: string }>()
);

export const setOpenmindSessionSentimentIds = createAction(
  '[openmind-event] set session sentiment Ids',
  props<{ sentimentId: string }>()
);

export const setOpenmindSessionRelevancyIds = createAction(
  '[openmind-event] set session relevancy Ids',
  props<{ relevancyId: string }>()
);

export const setOpenmindNestedArticleIds = createAction(
  '[openmind-event] set session openmind nested article Ids',
  props<{ nestedArticleId: string }>()
);

export const setOpenmindSessionReadingListIds = createAction(
  '[openmind-event] set session reading list Ids',
  props<{ readingListIds: string[] }>()
);

export const setOpenmindSessionFeedbackId = createAction(
  '[openmind-event] set session feedback Id',
  props<{ feedbackId: string }>()
);

export const setOpenmindSessionDuration = createAction(
  '[openmind-event] set session duration',
  props<{ sessionDuration: string }>()
);

export const resetOpenmindSessionEvent = createAction('[openmind-event] reset openmind session');
