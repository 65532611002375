import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, skipWhile, switchMap, take, BehaviorSubject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkCachedSubject, PublisherHandler } from '../core/models';
import { IFacets } from '../news/core/interfaces';
import {
  IDatasetTuneupPublisher,
  INewsTuneupDataPublishers,
  IPublisherFacetsMetadata,
  IPublisherMetadata
} from './core/api-interfaces';
import { ExcludedPublishersNames } from './core/enums';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiPublisherService {
  private publishers = new Map<string, IPublisherMetadata>();
  private publishersByName = new Map<string, IPublisherMetadata>();
  private publishersById = new Map<string, IPublisherMetadata>();
  private allSourcesTuneupCache$ = new NetworkCachedSubject<INewsTuneupDataPublishers>(null);
  private stackedSourcesTuneupCache$ = new NetworkCachedSubject<IDatasetTuneupPublisher[]>(null);
  // we are using cache to facilitate the logic to make sure we only load the publishers once
  private arePublisherLoaded$ = new NetworkCachedSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.authService.user$
      .pipe(
        skipWhile((user) => !user),
        take(1)
      )
      .pipe(switchMap(() => this.arePublisherLoaded$.next(this.loadAllPublishers(), true)))
      .pipe(take(1))
      .subscribe();
  }

  public waitForLoadPublisher(): Observable<boolean> {
    return this.arePublisherLoaded$.next(this.loadAllPublishers()).pipe(skipWhile((isLoaded: boolean) => !isLoaded));
  }

  public requestAllPublishers(shouldStackByRSS = true): Observable<IDatasetTuneupPublisher[]> {
    const cachedRequestOption: Observable<INewsTuneupDataPublishers> = this.http.post<INewsTuneupDataPublishers>(
      `${environment.API_ENDPOINT}/publishers/get-publishers`,
      {}
    );

    const publishers: Observable<IDatasetTuneupPublisher[]> = this.allSourcesTuneupCache$
      .next(cachedRequestOption)
      .pipe(
        map((sourcesRawResponse: INewsTuneupDataPublishers) => {
          const excludedPublishers: ExcludedPublishersNames[] = Object.values(ExcludedPublishersNames);

          const updatedSources: IDatasetTuneupPublisher[] = [];

          for (const doc of sourcesRawResponse['result-set'].docs) {
            // skip no name publisher
            if (!doc.publisher_name) {
              continue;
            }

            const pubName = Array.isArray(doc.publisher_name) ? doc.publisher_name[0] : doc.publisher_name;

            const shouldExcludePublisher: boolean = excludedPublishers.some(
              (excludedPublisher: ExcludedPublishersNames) => pubName.includes(excludedPublisher)
            );

            // skip excluded publisher
            if (pubName && shouldExcludePublisher) {
              continue;
            }

            const publisher: IDatasetTuneupPublisher = {
              ...doc,
              publisher_name: pubName,
              publisher_type: doc.publisher_type || [],
              path: doc.path || []
            };

            updatedSources.push(publisher);
          }

          return updatedSources;
        })
      );

    if (!shouldStackByRSS) {
      return publishers;
    }

    const stackedPublishers: Observable<IDatasetTuneupPublisher[]> = publishers.pipe(
      map((publishersDataset: IDatasetTuneupPublisher[]) => {
        const publishersByName = new Map<string, IPublisherMetadata>();

        const publisherHandler = new PublisherHandler((publisherName: string) => publishersByName.get(publisherName));

        for (const publisherDataset of publishersDataset) {
          const publisher: IPublisherMetadata = {
            name: publisherDataset.publisher_name,
            path: publisherDataset.path,
            neutrality_pubRSS_ID: publisherDataset.neutrality_pubRSS_ID,
            datasetTuneupPublisher: publisherDataset,
            publisher_logo: publisherDataset.publisher_logo?.length ? publisherDataset.publisher_logo[0] : null,
            paywall: publisherDataset?.paywall?.length ? publisherDataset.paywall[0] : false,
            RSSmap: publisherDataset?.RSSmap[0] || null
          };

          publishersByName.set(publisher.name, publisher);
          publisherHandler.push_publisher(publisher);
        }

        const publishers: IDatasetTuneupPublisher[] = publisherHandler.publishers;

        return publishers;
      })
    );

    return this.stackedSourcesTuneupCache$.next(stackedPublishers);
  }

  public getPublisherFromName(publisherName: string): IPublisherMetadata {
    return this.publishersByName.get(publisherName);
  }

  public getPublisherFromId(id: string): IPublisherMetadata {
    return this.publishersById.get(id);
  }

  public facetsToPublishers(facets: IFacets): Observable<IPublisherFacetsMetadata> {
    return this.waitForLoadPublisher().pipe(
      map(() => {
        const publisherHandler = new PublisherHandler((publisherName: string) =>
          this.getPublisherFromName(publisherName)
        );

        for (const facet of facets.neutrality_pubRSS_ID?.buckets || []) {
          const publisher = this.getPublisherFromId(facet.val);

          if (!publisher) {
            continue;
          }

          if (publisher.name.includes(ExcludedPublishersNames.ADMIN_DISABLE)) {
            continue;
          }

          publisher.datasetTuneupPublisher.article_count = facet.count;

          publisherHandler.push_publisher(publisher);
        }

        const publishersFacets: IPublisherFacetsMetadata = {
          publishers: publisherHandler.publishers,
          publisherRss: publisherHandler.publisherRss
        };

        return publishersFacets;
      })
    );
  }

  public facetsToAllPublishers(
    facets: IFacets,
    allPublishers: IDatasetTuneupPublisher[]
  ): Observable<IPublisherFacetsMetadata> {
    return this.facetsToPublishers(facets).pipe(
      map((publisherFacets: IPublisherFacetsMetadata) => {
        const publisherFacetsNames = new Map<string, IDatasetTuneupPublisher>();

        for (const publisher of publisherFacets.publishers) {
          publisherFacetsNames.set(publisher.publisher_name, publisher);
        }

        const publishers = allPublishers.map((publisher: IDatasetTuneupPublisher) => {
          if (!publisherFacetsNames.has(publisher.publisher_name)) {
            return publisher;
          }

          return <IDatasetTuneupPublisher>{
            ...publisher,
            rssmap_count: publisherFacetsNames.get(publisher.publisher_name).rssmap_count,
            article_count: publisherFacetsNames.get(publisher.publisher_name).article_count
          };
        });

        return <IPublisherFacetsMetadata>{
          ...publisherFacets,
          publishers
        };
      })
    );
  }

  private loadAllPublishers(): Observable<boolean> {
    return this.requestAllPublishers(false).pipe(
      take(1),
      map((publishersDataset: IDatasetTuneupPublisher[]) => {
        this.publishers.clear();
        this.publishersByName.clear();
        this.publishersById.clear();

        const publisherHandler = new PublisherHandler((publisherName: string) =>
          this.getPublisherFromName(publisherName)
        );

        for (const publisherDataset of publishersDataset) {
          const publisher: IPublisherMetadata = {
            name: publisherDataset.publisher_name,
            path: publisherDataset.path,
            neutrality_pubRSS_ID: publisherDataset.neutrality_pubRSS_ID,
            datasetTuneupPublisher: publisherDataset,
            publisher_logo: publisherDataset.publisher_logo?.length ? publisherDataset.publisher_logo[0] : null,
            paywall: publisherDataset?.paywall?.length ? publisherDataset.paywall[0] : false,
            RSSmap: publisherDataset?.RSSmap[0] || null
          };

          this.publishersByName.set(publisher.name, publisher);
          this.publishersById.set(publisher.neutrality_pubRSS_ID, publisher);
          publisherHandler.push_publisher(publisher);
        }

        const publishers: IDatasetTuneupPublisher[] = publisherHandler.publishers;

        for (const publisherDataset of publishers) {
          const publisher: IPublisherMetadata = {
            name: publisherDataset.publisher_name,
            path: publisherDataset.path,
            neutrality_pubRSS_ID: publisherDataset.neutrality_pubRSS_ID,
            datasetTuneupPublisher: publisherDataset,
            publisher_logo: publisherDataset.publisher_logo?.length ? publisherDataset.publisher_logo[0] : null,
            paywall: publisherDataset?.paywall?.length ? publisherDataset.paywall[0] : false
          };

          this.publishersByName.set(publisher.name, publisher);
        }

        return true;
      })
    );
  }
}
