import { IDatasetTuneupPublisher } from 'src/app/api/core/api-interfaces';
import { IApiRssDetails } from 'src/app/api/core/interfaces';
import { isSameObject } from 'src/app/core/utils';

export function buildFilterFromSelectedSources(
  selectedSources: (IDatasetTuneupPublisher | IApiRssDetails)[]
): string[] {
  const returnedFilters: string[] = [];

  if (selectedSources.length === 0) {
    return returnedFilters;
  }

  // RSS_MAP
  let allRssMapFiltering = selectedSources
    .map((source: IDatasetTuneupPublisher | IApiRssDetails) => {
      if ((<IApiRssDetails>source).RSSmap) {
        return (<IApiRssDetails>source).RSSmap;
      }
      return null;
    })
    .filter((selectedRSSLists: string[]) => !!selectedRSSLists && selectedRSSLists.length)
    .reduce((prevRssList: string[], currentRssList: string[]) => prevRssList.concat(currentRssList), []);

  // remove repeated rss
  allRssMapFiltering = [...new Set(allRssMapFiltering)];

  let RssMapFilter: string[] = [];
  allRssMapFiltering.forEach((rssMapItem) => {
    const parentSource: IDatasetTuneupPublisher | IApiRssDetails = getParentSourceFromRssMap(
      rssMapItem,
      selectedSources
    );

    if (parentSource) {
      const RssMapList: (IDatasetTuneupPublisher | IApiRssDetails)[] = getRssMapListFromParentSource(
        Array.isArray(parentSource.publisher_name) ? parentSource.publisher_name[0] : parentSource.publisher_name,
        selectedSources
      );

      let rssMapListForFilters = RssMapList.map((source: IDatasetTuneupPublisher | IApiRssDetails) => {
        return (<IApiRssDetails>source).RSSmap;
      }).reduce((prevRssList: string[], currentRssList: string[]) => prevRssList.concat(currentRssList), []);

      const filterString = `(RSSmap:("${rssMapListForFilters.join('" OR "')}") AND publisher_name:("${
        parentSource.publisher_name
      }"))`;
      RssMapFilter.push(filterString);
    }
  });

  RssMapFilter = [...new Set(RssMapFilter)];
  const combinedRssMapFilter: string = RssMapFilter.join(' OR ');

  let allSourcesToBeSelected: string[] = selectedSources
    .map((source: IDatasetTuneupPublisher | IApiRssDetails) => {
      if (!(<IApiRssDetails>source).RSSmap) {
        return Array.isArray(source.publisher_name) ? source.publisher_name : [source.publisher_name];
      }
      return null;
    })
    .filter((selectedSourceLists: string[]) => !!selectedSourceLists && selectedSourceLists.length)
    .reduce((prevSourceList: string[], currentSourceList: string[]) => prevSourceList.concat(currentSourceList), []);

  // remove repeated publishers
  allSourcesToBeSelected = [...new Set(allSourcesToBeSelected)];

  let pubIdFiltersSection: string = allSourcesToBeSelected.length
    ? `publisher_name: ("${allSourcesToBeSelected.join('" OR "')}")`
    : '';

  pubIdFiltersSection +=
    combinedRssMapFilter.length > 0 && pubIdFiltersSection.length
      ? ` OR ${combinedRssMapFilter}`
      : combinedRssMapFilter;
  if (pubIdFiltersSection.length) {
    returnedFilters.push(pubIdFiltersSection);
  }

  return returnedFilters;
}

function getParentSourceFromRssMap(
  RssMap: string,
  allSelectedSources: (IDatasetTuneupPublisher | IApiRssDetails)[]
): IDatasetTuneupPublisher | IApiRssDetails {
  return allSelectedSources.find((parentSource: IDatasetTuneupPublisher | IApiRssDetails) => {
    if (parentSource.RSSmap) {
      return parentSource.RSSmap[0] === RssMap;
    }
  });
}

function getRssMapListFromParentSource(
  publisher_name: string,
  allSelectedSources: (IDatasetTuneupPublisher | IApiRssDetails)[]
): (IDatasetTuneupPublisher | IApiRssDetails)[] {
  return allSelectedSources.filter((parentSource: IDatasetTuneupPublisher | IApiRssDetails) => {
    if (parentSource.publisher_name) {
      return parentSource.publisher_name[0] === publisher_name;
    }
  });
}
