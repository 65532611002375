import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISignificantTerm } from 'src/app/api/core/api-interfaces';
import { INewsItem } from 'src/app/news/core/interfaces';
import { cleanAndAdjustQuery } from '../../utils';
import { FilterType } from '../core/enums';
import { IFilter } from '../core/interfaces';
import { setAvailableKeywords } from '../filters-store/filters.actions';

@Injectable({
  providedIn: 'root'
})
export class FilterKeywordsService {
  constructor(private store: Store) {}

  public keywordsTuneupFromArticles(articles: INewsItem[], keywordsMap?: Map<string, Set<string>>): IFilter[] {
    if (!keywordsMap) {
      keywordsMap = new Map<string, Set<string>>();

      for (const article of articles) {
        for (const keyword of article.terms) {
          if (!keywordsMap.has(keyword)) {
            keywordsMap.set(keyword, new Set<string>());
          }

          keywordsMap.get(keyword).add(article.id);
        }
      }
    }

    const keywordFilters: IFilter[] = [];
    for (const [keyword, articlesIds] of keywordsMap.entries()) {
      const keywordFilter: IFilter = {
        value: keyword,
        type: FilterType.Keywords,
        metadata: {
          term: keyword,
          score: 1.0,
          foreground: articlesIds.size,
          background: articlesIds.size
        }
      };

      keywordFilters.push(keywordFilter);
    }

    return keywordFilters;
  }

  public convertTermsToFilters(terms: ISignificantTerm[]) {
    let uniqueTermsFilters: IFilter[] = terms.map((value: ISignificantTerm) => {
      const genFilter: IFilter = {
        value: value.term,
        type: FilterType.Keywords,
        metadata: value
      };

      return genFilter;
    });

    uniqueTermsFilters = uniqueTermsFilters.filter((term: IFilter) => {
      return term.value && term.value.length;
    });

    return uniqueTermsFilters;
  }

  public updateGlobalKeywordsForQuery(terms: ISignificantTerm[]) {
    this.store.dispatch(
      setAvailableKeywords({
        keywords: this.convertTermsToFilters(terms)
      })
    );
  }

  public buildKeywordFilters(keywords: IFilter[]): string[] {
    if (keywords.length === 0) {
      return [];
    }

    const keywordsToIncludeInQuery: string[] = keywords.map((keywordsFilter: IFilter) => keywordsFilter.value);

    let filtersQueryStr = `(q=("${keywordsToIncludeInQuery.join('" AND "')}"))`;
    filtersQueryStr = cleanAndAdjustQuery(filtersQueryStr);

    return [filtersQueryStr];
  }

  public buildSimpleKeywordFilters(keywords: IFilter[]): string {
    if (keywords.length === 0) {
      return '';
    }

    const keywordsToIncludeInQuery: string[] = keywords.map((keywordsFilter: IFilter) => keywordsFilter.value);

    let filtersQueryStr = keywordsToIncludeInQuery.join('" AND "');
    filtersQueryStr = cleanAndAdjustQuery(filtersQueryStr);

    return filtersQueryStr;
  }

  public updateQueryFromKeywords(query: string, keywords: IFilter[]): string {
    if (keywords.length === 0) {
      return query;
    }

    const keywordQuery = this.buildSimpleKeywordFilters(keywords);

    // Empty query should bring all results
    if (query === '' || query === '*') {
      return keywordQuery;
    }

    return `${query} AND (${keywordQuery})`;
  }
}
