import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { INeuDashboardEventCaptureData } from '../../interfaces/dashboard-event.interface';
import * as dashboardEventAction from './dashboard-event.action';

export const dashboardEventFeatureKey = 'DashboardEvent';

export interface INeuDashboardEvent {
  isDashboardEventStart: boolean;
  eventStartTime: Date;
  dashboardEventData: INeuDashboardEventCaptureData;
}

export const initialDashboardEventState: INeuDashboardEvent = {
  isDashboardEventStart: false,
  eventStartTime: undefined,
  dashboardEventData: {
    panelName: '',
    nameChanged: undefined,
    expandPanel: undefined,
    hidden: undefined,
    deleted: undefined,
    move: undefined,
    duplicatePanel: undefined,
    applySearch: '',
    duration: '00:00:00'
  }
};

export const dashboardEventReducer: ActionReducer<INeuDashboardEvent, Action> = createReducer(
  initialDashboardEventState,
  on(dashboardEventAction.dashboardEventStartState, (state, { isDashboardEventStart }) => ({
    ...state,
    isDashboardEventStart,
    eventStartTime: isDashboardEventStart ? new Date() : state.eventStartTime
  })),
  on(dashboardEventAction.setDashboardEventPanelName, (state, { panelName }) => ({
    ...state,
    dashboardEventData: { ...state.dashboardEventData, ...(state.isDashboardEventStart ? { panelName } : {}) }
  })),
  on(dashboardEventAction.setDashboardEventNameChangeState, (state, { nameChanged }) => ({
    ...state,
    dashboardEventData: { ...state.dashboardEventData, ...(state.isDashboardEventStart ? { nameChanged } : {}) }
  })),
  on(dashboardEventAction.setDashboardEventexpandPanelState, (state, { expandPanel }) => ({
    ...state,
    dashboardEventData: { ...state.dashboardEventData, ...(state.isDashboardEventStart ? { expandPanel } : {}) }
  })),
  on(dashboardEventAction.setDashboardEventHiddenState, (state, { hidden }) => ({
    ...state,
    dashboardEventData: { ...state.dashboardEventData, ...(state.isDashboardEventStart ? { hidden } : {}) }
  })),
  on(dashboardEventAction.setDashboardEventDeletedState, (state, { deleted }) => ({
    ...state,
    dashboardEventData: { ...state.dashboardEventData, ...(state.isDashboardEventStart ? { deleted } : {}) }
  })),
  on(dashboardEventAction.setDashboardEventMoveState, (state, { move }) => ({
    ...state,
    dashboardEventData: { ...state.dashboardEventData, ...(state.isDashboardEventStart ? { move } : {}) }
  })),
  on(dashboardEventAction.setDashboardEventDuplicatePanelState, (state, { duplicatePanel }) => ({
    ...state,
    dashboardEventData: { ...state.dashboardEventData, ...(state.isDashboardEventStart ? { duplicatePanel } : {}) }
  })),
  on(dashboardEventAction.setDashboardEventApplySearch, (state, { applySearch }) => ({
    ...state,
    dashboardEventData: { ...state.dashboardEventData, ...(state.isDashboardEventStart ? { applySearch } : {}) }
  })),
  on(dashboardEventAction.resetDashboardEvent, () => initialDashboardEventState)
);
