import { createReducer, on } from '@ngrx/store';
import { IReadingListessionEvent } from '../../interfaces';
import * as readingListActions from './reading-list-event-action';

export const readingListSessionEventFeatureKey = 'ReadingListEvents';

export interface INeuReadingListEvent {
  isReadingListSessionStart: boolean;
  eventStartTime: Date;
  readingListSessionEventData: IReadingListessionEvent;
}

export const initialReadingListEventState: INeuReadingListEvent = {
  isReadingListSessionStart: false,
  eventStartTime: undefined,
  readingListSessionEventData: {
    query: '',
    filterSource: '',
    filterKeywords: '',
    filterDate: '',
    layoutType: '',
    sortType: '',
    duration: '00:00:00'
  }
};

export const readingListEventReducer = createReducer(
  initialReadingListEventState,
  on(readingListActions.readingListEventStartState, (state, { isReadingListEventStart }) => {
    return {
      ...state,
      isReadingListSessionStart: isReadingListEventStart,
      eventStartTime: isReadingListEventStart ? new Date() : state.eventStartTime
    };
  }),
  on(readingListActions.setReadingListEventQuery, (state, { query }) => {
    return {
      ...state,
      readingListSessionEventData: {
        ...state.readingListSessionEventData,
        query
      }
    };
  }),
  on(readingListActions.setReadingListEventFilteredSource, (state, { filterSource }) => {
    return {
      ...state,
      readingListSessionEventData: {
        ...state.readingListSessionEventData,
        filterSource
      }
    };
  }),
  on(readingListActions.setReadingListEventFilteredKeyword, (state, { filterKeywords }) => {
    return {
      ...state,
      readingListSessionEventData: {
        ...state.readingListSessionEventData,
        filterKeywords
      }
    };
  }),
  on(readingListActions.setReadingListEventFilteredDate, (state, { filterDate }) => {
    return {
      ...state,
      readingListSessionEventData: {
        ...state.readingListSessionEventData,
        filterDate
      }
    };
  }),
  on(readingListActions.setReadingListEventLayoutType, (state, { layoutType }) => {
    return {
      ...state,
      readingListSessionEventData: {
        ...state.readingListSessionEventData,
        layoutType
      }
    };
  }),
  on(readingListActions.setReadingListEventSortType, (state, { sortType }) => {
    return {
      ...state,
      readingListSessionEventData: {
        ...state.readingListSessionEventData,
        sortType
      }
    };
  }),
  on(readingListActions.setReadingListEventDuration, (state, { duration }) => {
    return {
      ...state,
      readingListSessionEventData: {
        ...state.readingListSessionEventData,
        duration
      }
    };
  }),

  on(readingListActions.resetReadingListEvent, () => {
    return initialReadingListEventState;
  })
);
