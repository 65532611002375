import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { IAnalyticSearchEventData } from '../../interfaces';
import * as analyticSearchEventAction from './analytic-search-event.action';

export const analyticSearchEventFeatureKey = 'analyticSearchEvent';

export interface INeuAnalyticSearchEvent {
  isAnalyticSearchEventStart: boolean;
  eventStartTime: Date;
  analyticSearchEventData: IAnalyticSearchEventData;
  selectedTimePeriod?: string;
}

export const initialAnalyticSearchEventState: INeuAnalyticSearchEvent = {
  isAnalyticSearchEventStart: false,
  eventStartTime: undefined,
  analyticSearchEventData: {
    query: '',
    querySavedTopic: undefined,
    searchSession: '',
    timePeriod: '',
    chartType: '',
    expandPanel: undefined,
    isTooltipClicked: undefined,
    applySearch: '',
    duration: '00:00:00'
  },
  selectedTimePeriod: ''
};

export const analyticSearchEventReducer: ActionReducer<INeuAnalyticSearchEvent, Action> = createReducer(
  initialAnalyticSearchEventState,
  on(analyticSearchEventAction.analyticSearchEventStartState, (state, { isAnalyticSearchEventStart }) => ({
    ...state,
    isAnalyticSearchEventStart,
    eventStartTime: isAnalyticSearchEventStart ? new Date() : state.eventStartTime
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventQuery, (state, { query }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, query }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventQuerySavedTopicState, (state, { querySavedTopic }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, querySavedTopic }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventSearchSession, (state, { searchSession }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, searchSession }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventTimePeriod, (state, { timePeriod }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, timePeriod }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventChartType, (state, { chartType }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, chartType }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventExpandPanelState, (state, { expandPanel }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, expandPanel }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventApplySearch, (state, { applySearch }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, applySearch }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventDuration, (state, { duration }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, duration }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventTooltipClickedState, (state, { isTooltipClicked }) => ({
    ...state,
    analyticSearchEventData: { ...state.analyticSearchEventData, isTooltipClicked }
  })),
  on(analyticSearchEventAction.setAnalyticSearchEventSelectedTimePeriod, (state, { selectedTimePeriod }) => ({
    ...state,
    selectedTimePeriod
  })),
  on(analyticSearchEventAction.resetAnalyticSearchEvent, () => initialAnalyticSearchEventState)
);
