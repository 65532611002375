export const filterParamEncoding = (filter: string) => {
  return filter
    .replace(/%/g, '%25')
    .replace(/#/g, '%23')
    .replace(/\$/g, '%24')
    .replace(/@/g, '%40')
    .replace(/&/g, '%26')
    .replace(/!/g, '%21')
    .replace(/\^/g, '%5E');
};
