import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Subject, Subscription, takeUntil } from 'rxjs';
import { MainApplicationRoutes, NeutralityBreakpoints } from '../enums';
import { reverseEnumKeyByValue } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ViewInfoService {
  public memoizedCurrentViewSub: Subscription = null;
  public currentView$ = new BehaviorSubject<MainApplicationRoutes>(null);
  public currentMainRoute$ = new BehaviorSubject<MainApplicationRoutes>(null);
  public neutralityBreakpoints$ = new BehaviorSubject<Record<keyof typeof NeutralityBreakpoints, boolean>>(null);
  public documentClick$ = new Subject<MouseEvent>();

  public destroy$ = new Subject<void>();

  constructor(private router: Router, private breakpointObserver: BreakpointObserver) {
    this.currentView$.next(this.getViewFromUrl(this.router.url));

    this.breakpointObserverSub();
  }

  public currentViewSub(destroy: Subject<void>): Subscription {
    this.destroy$ = destroy;

    if (this.memoizedCurrentViewSub) {
      return this.memoizedCurrentViewSub;
    }

    this.memoizedCurrentViewSub = this.router.events
      .pipe(
        filter((routerEvent): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => {
          this.currentView$.next(this.getViewFromUrl(this.router.url));
          this.currentMainRoute$.next(this.getAppRouteFromUrl(this.router.url));
        }
      });

    return this.memoizedCurrentViewSub;
  }

  private getViewFromUrl(url: string): MainApplicationRoutes {
    const searchRoutes: string[] = ['classic', 'grid', 'list'];
    const mainRouteSegment: string = url.split('/')[1].split('?')[0];

    if (searchRoutes.includes(mainRouteSegment)) {
      return MainApplicationRoutes.Search;
    }

    return mainRouteSegment as MainApplicationRoutes;
  }

  private getAppRouteFromUrl(url: string): MainApplicationRoutes {
    const mainRouteSegment: string = url.split('/')[1].split('?')[0];

    return mainRouteSegment as MainApplicationRoutes;
  }

  private breakpointObserverSub() {
    const breakpointsToObserve: string[] = Object.keys(NeutralityBreakpoints).map(
      (key: string) => NeutralityBreakpoints[key]
    );

    this.breakpointObserver
      .observe(breakpointsToObserve)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (matching) => {
          const matchingBreakpoints: Record<keyof typeof NeutralityBreakpoints, boolean> = <any>{};

          for (const matchingBreakpointKey of Object.keys(matching.breakpoints)) {
            matchingBreakpoints[reverseEnumKeyByValue(NeutralityBreakpoints, matchingBreakpointKey)] =
              matching.breakpoints[matchingBreakpointKey];
          }

          this.neutralityBreakpoints$.next(matchingBreakpoints);
        }
      });
  }
}
