import { createReducer, on } from '@ngrx/store';
import { IOpenmindSessionEvent } from '../../interfaces/openmind-session-event.interface';
import * as openmindEventActions from './openmind-event.action';

export const openmindSessionEventFeatureKey = 'OpenmindEvents';

export interface INeuOpenmindEvent {
  isOpenmindSessionStart: boolean;
  eventStartTime: Date;
  openmindSessionEventData: IOpenmindSessionEvent;
}

export const initialOpenmindEventState: INeuOpenmindEvent = {
  isOpenmindSessionStart: false,
  eventStartTime: undefined,
  openmindSessionEventData: {
    articleId: '',
    filterSource: '',
    filterKeywords: '',
    filerDate: '',
    layoutType: '',
    sortType: '',
    sentimentViewIDs: [],
    relevancyViewIDs: [],
    readinglistIDs: [],
    collectionAddIDs: [],
    openmindNestedArticleIDs: [],
    feedbackID: '',
    duration: '00:00:00'
  }
};

export const openmindEventReducer = createReducer(
  initialOpenmindEventState,
  on(openmindEventActions.setOpenmindSessionEventStartState, (state, { isSessionStart }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    openmindEventState.isOpenmindSessionStart = isSessionStart;

    if (isSessionStart) {
      openmindEventState.eventStartTime = new Date();
    }

    return openmindEventState;
  }),
  on(openmindEventActions.setOpenMindSessionArticleId, (state, { articleId }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const sessionData: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    sessionData.articleId = articleId;
    openmindEventState.openmindSessionEventData = sessionData;

    return openmindEventState;
  }),
  on(openmindEventActions.setOpenmindFilterSources, (state, { sources }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    session.filterSource = sources;
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),
  on(openmindEventActions.setOpenmindSessionFilterKeywords, (state, { keywords }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    session.filterKeywords = keywords;
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),

  on(openmindEventActions.setOpenmindSessionSelectedLayoutType, (state, { selectedLayout }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    session.layoutType = selectedLayout;
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),

  on(openmindEventActions.setOpenmindSessionSortType, (state, { sortType }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    session.sortType = sortType;
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),

  on(openmindEventActions.setOpenmindSessionSentimentIds, (state, { sentimentId }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    const isSentimentIdExist: boolean = session.sentimentViewIDs.some((id: string) => id === sentimentId);
    if (!isSentimentIdExist) {
      session.sentimentViewIDs = [...session.sentimentViewIDs, sentimentId];
    }
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),

  on(openmindEventActions.setOpenmindNestedArticleIds, (state, { nestedArticleId }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    const isNestedArticleExist: boolean = session.openmindNestedArticleIDs.some((id: string) => id === nestedArticleId);
    if (!isNestedArticleExist) {
      session.openmindNestedArticleIDs = [...session.openmindNestedArticleIDs, nestedArticleId];
    }
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),

  on(openmindEventActions.setOpenmindSessionRelevancyIds, (state, { relevancyId }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    const isRelevancyIdExist: boolean = session.relevancyViewIDs.some((id: string) => id === relevancyId);
    if (!isRelevancyIdExist) {
      session.relevancyViewIDs = [...session.relevancyViewIDs, relevancyId];
    }
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),

  on(openmindEventActions.setOpenmindSessionReadingListIds, (state, { readingListIds }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    session.readinglistIDs = readingListIds;
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),
  on(openmindEventActions.setOpenmindSessionDuration, (state, { sessionDuration }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    session.duration = sessionDuration;
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),

  on(openmindEventActions.setOpenmindSessionFeedbackId, (state, { feedbackId }) => {
    const openmindEventState: INeuOpenmindEvent = { ...state };
    const session: IOpenmindSessionEvent = { ...openmindEventState.openmindSessionEventData };

    session.feedbackID = feedbackId;
    openmindEventState.openmindSessionEventData = session;

    return openmindEventState;
  }),
  on(openmindEventActions.resetOpenmindSessionEvent, () => {
    return initialOpenmindEventState;
  })
);
