import { createAction, props } from '@ngrx/store';
import { MessageType } from '../enums/message-type.enum';

export const SetNotificationState = createAction(
  '[AlertBanner] Set notification bar state',
  props<{ showNotification: boolean }>()
);

export const SetNotificationText = createAction(
  '[AlertBanner] Set notification bar text',
  props<{ notificationText: string }>()
);

export const SetNotificationType = createAction(
  '[AlertBanner] Set notification bar type',
  props<{ notificationType: MessageType }>()
);
