export const prettyPrintNumber = (num: number) => {
  if (!num) {
    return '0';
  }

  let numberFormatted: string = num.toString();

  const digitAllocationMap = {
    1000: '>1k',
    5000: '>5k',
    10000: '>10k',
    50000: '>50k+',
    100000: '99k+',
    500000: '0.5M+',
    1000000: '1M+',
    5000000: '5M+',
    10000000: '10M+',
    50000000: '50M+',
    100000000: '>99M'
  };

  for (const allocation of Object.keys(digitAllocationMap)) {
    if (num > Number(allocation) - 1) {
      numberFormatted = digitAllocationMap[allocation];
    }
  }

  return numberFormatted || '0';
};

export function simpleNumberFormatter(numberToFormat: number, digits = 1) {
  const lookupLetterAllocation: {
    value: number;
    symbol: string;
  }[] = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];

  const digitFinderRegex = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const letterValueAllocation: {
    value: number;
    symbol: string;
  } = lookupLetterAllocation
    .slice()
    .reverse()
    .find(function (item: { value: number; symbol: string }) {
      return numberToFormat >= item.value;
    });

  return letterValueAllocation
    ? (numberToFormat / letterValueAllocation.value).toFixed(digits).replace(digitFinderRegex, '$1') +
        letterValueAllocation.symbol
    : '0';
}
