import { GeneralApplicationRoutes, MainApplicationRoutes } from '../enums';

export function getAppRoute(url: string): MainApplicationRoutes {
  const appRoute: MainApplicationRoutes = Object.values(MainApplicationRoutes)
    .sort((value1: string, value2: string) => value2.length - value1.length)
    .find((route: string) => url.startsWith(`/${route}`));

  return appRoute;
}

export function getGeneralAppRoute(url: string): GeneralApplicationRoutes {
  const appRoute: GeneralApplicationRoutes = Object.values(GeneralApplicationRoutes)
    .sort((value1: string, value2: string) => value2.length - value1.length)
    .find((route: string) => url.startsWith(`/${route}`) || url.startsWith(`${route}`));

  return appRoute;
}
