/* eslint-disable */

import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, take } from 'rxjs/operators';
import { MainApplicationViews } from 'src/app/core/enums';
import { FilterType } from 'src/app/core/filters/core/enums';
import { IFilterLabel } from 'src/app/core/filters/core/interfaces';
import { IAppState } from 'src/app/core/store-app/reducers';
import { NeuEventCategory, NeuEventType } from 'src/app/core/track-events/enum';
import { IDateFilter } from 'src/app/news-filters/core/interfaces';
import {
  IPublisherFilter,
  ISavedCustomSources
} from 'src/app/news-filters/filter/tree-filter-publishers/core/interfaces';
import {
  selectActivePublishersFilters,
  selectDateFilter
} from 'src/app/news-filters/store-filters/filters-state.selectors';
import { INewsRequestV2 } from 'src/app/news/core/interfaces';
import {
  selectActiveFilters,
  selectCurrentSearchQuery,
  selectCurrentSortDirection
} from 'src/app/news/store-news/news.selectors';
import { ISavedTopic } from 'src/app/ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { NeuEventAccessMode } from '../../enums';
import { FilterTypeCapturedType, SearchCapturedData, TopSavedStoryCaptureData } from '../../models/captured-data-types';
import { ISeachSessionCapture, ISeachSessionCapturedData } from '../../models/captured-data-types/auth';
import { SearchLayoutCapturedData, SearchQueryCapturedData } from '../../models/captured-data-types/search';
import { NeuSavedTopicFiltersCapturedData, NeutralityEvent } from '../../models/neutrality-event.model';
import { durationInMsToPrettyFormat } from '../../utils';
import { EventDataService } from '../neu-event-data.service';

@Injectable({
  providedIn: 'root'
})
export class NeuSearchEventsService {
  private storedSearchEventSession: ISeachSessionCapture = null;

  constructor(private store: Store<IAppState>, private eventDataService: EventDataService, private router: Router) {
    this.filtersChangeSub();
    this.routerChangedSub();
  }

  // A click on the search icon is the only thing that will trigger this
  public catchEventManualSearch() {
    // Get needed items for the session
    return combineLatest([
      this.store.pipe(select(selectCurrentSearchQuery)),
      this.store.pipe(select(selectDateFilter)),
      this.store.pipe(select(selectCurrentSortDirection)),
      this.getActiveFilters(),
      this.store.pipe(select(selectActivePublishersFilters))
    ])
      .pipe(
        take(1),
        switchMap(([searchQuery, dateFilter, sortDirection, filters, pubFilters]: any) => {
          const generatedPublishersFilters: string[] = this.generateParentSubfilterFromPublishers(pubFilters);
          if (this.storedSearchEventSession === null) {
            // Capture the first search
            const generatedCapturedData: SearchCapturedData = {
              query: searchQuery,
              filterDate: dateFilter.apiQuery,
              sort: sortDirection,
              filterCategory: filters.categoryFiltersQuery,
              filterPublisher: generatedPublishersFilters
            };
            const generatedEvent: NeutralityEvent = new NeutralityEvent({
              eventCategory: NeuEventCategory.Analytics,
              eventType: NeuEventType.Search,
              capturedData: generatedCapturedData,
              shouldBeSaved: true
            });
            this.storedSearchEventSession = {
              startMs: new Date().getTime(),
              searchedKeywords: [],
              sortDirections: [sortDirection],
              publisherFiltersAdded: generatedPublishersFilters,
              dateFilters: [dateFilter]
            };
            return this.eventDataService.saveEvent(generatedEvent);
          }
          this.addNewDataToSession(dateFilter, sortDirection, pubFilters, searchQuery);
          return of(false);
        })
      )
      .subscribe({
        next: () => {
          // IMPORTANT: I'm commenting this to not incur in extra expenses for writing to the Database so many times
          // The search Event has been registered,
          // Creating a notification now
          // this.neuNotificationService
          //   .createNotification({
          //     targetSubscriptionGroups: [UserGroups.Self],
          //     displayText: 'You searched at ' + new Date().toUTCString(),
          //     title: 'Search',
          //     creationDate: new Date().toUTCString()
          //   }).pipe(take(1))
          //   .subscribe();
        }
      });
  }

  public catchSavedTopicFilters(savedTopic: ISavedTopic, defaultDashboard: boolean): Subscription {
    const generatedCapturedData: NeuSavedTopicFiltersCapturedData = {
      ...savedTopic,
      isDefaultDashboard: defaultDashboard
    };

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: NeuEventType.SavedSearch,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent).pipe(take(1)).subscribe();
  }

  public catchSavedCustomSources(savedCustomSource: ISavedCustomSources) {
    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: NeuEventType.SavedCustomSource,
      capturedData: savedCustomSource
    });

    return this.eventDataService.saveEvent(generatedEvent).pipe(take(1)).subscribe();
  }

  public catchReadingListFilter(isSelected: boolean) {
    const generatedCapturedData: TopSavedStoryCaptureData = {
      filterType: FilterTypeCapturedType.ReadingList,
      isSelected
    };

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: NeuEventType.SavedStory,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent).pipe(take(1)).subscribe();
  }

  public catchEventRemovedSavedSearch(savedTopic: ISavedTopic) {
    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: NeuEventType.RemovedSavedSearch,
      capturedData: savedTopic,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent).pipe(take(1)).subscribe();
  }

  public catchEventDefaultFilterSet(savedTopic: ISavedTopic) {
    const eventType = savedTopic.isDefault
      ? NeuEventType.RemovedDefaultFilterOnInit
      : NeuEventType.SetDefaultFilterOnInit;

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType,
      capturedData: savedTopic,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent).pipe(take(1)).subscribe();
  }

  public catchEventModifiedFilterName(savedTopic: ISavedTopic) {
    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: NeuEventType.SearchEditTopic,
      capturedData: savedTopic,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent).pipe(take(1)).subscribe();
  }

  public terminateSearchSession() {
    if (this.storedSearchEventSession) {
      const durationInMs: number = Math.floor(Date.now() - this.storedSearchEventSession.startMs);
      const formattedDuration: string = durationInMsToPrettyFormat(durationInMs);

      const generatedCapturedData: ISeachSessionCapturedData = {
        searchedKeywords: this.storedSearchEventSession.searchedKeywords,
        sortDirections: this.storedSearchEventSession.sortDirections,
        publisherFiltersAdded: this.storedSearchEventSession.publisherFiltersAdded,
        dateFilters: this.storedSearchEventSession.dateFilters,
        duration: formattedDuration
      };

      const generatedEvent: NeutralityEvent = new NeutralityEvent({
        eventCategory: NeuEventCategory.Analytics,
        eventType: NeuEventType.SearchSession,
        capturedData: generatedCapturedData,
        shouldBeSaved: true
      });

      this.storedSearchEventSession = null;

      return this.eventDataService.saveEvent(generatedEvent).subscribe();
    }
  }

  private getActiveFilters() {
    return this.store.pipe(
      select(selectActiveFilters),
      map((filters: IFilterLabel[]) => {
        let publisherFiltersQuery = '';
        let categoryFiltersQuery = '';
        for (const filterItem of filters) {
          switch (filterItem.type) {
            case FilterType.Category:
              categoryFiltersQuery += filterItem.name + ',';
              break;
            case FilterType.Publishers:
              publisherFiltersQuery += filterItem.name + ',';
              break;
            case FilterType.ReadingList:
              publisherFiltersQuery += filterItem.name + ',';
              break;
          }
        }

        return {
          publisherFiltersQuery,
          categoryFiltersQuery
        };
      })
    );
  }

  private generateParentSubfilterFromPublishers(filters: IPublisherFilter[]): string[] {
    return filters.map((parentFilter: IPublisherFilter) => {
      // Generates: New york times > Human interest
      return parentFilter.parentNode.itemTitle.split('_')[0] + ' > ' + parentFilter.childNode.itemTitle.split('_')[0];
    });
  }

  private cleanRepeatedPublishersFromSession(): void {
    this.storedSearchEventSession.publisherFiltersAdded = this.storedSearchEventSession.publisherFiltersAdded.filter(
      (value: string, index: number) => {
        return this.storedSearchEventSession.publisherFiltersAdded.indexOf(value) === index;
      }
    );
  }

  private filtersChangeSub(): void {
    combineLatest([
      this.store.pipe(select(selectDateFilter)),
      this.store.pipe(select(selectCurrentSortDirection)),
      this.store.pipe(select(selectActivePublishersFilters), debounceTime(500))
    ]).subscribe({
      next: ([dateFilter, sortDirection, activePublisherFilters]) => {
        const generatedPublishersFilters: string[] = this.generateParentSubfilterFromPublishers(activePublisherFilters);

        if (this.storedSearchEventSession === null && activePublisherFilters.length) {
          // No session, start a new one with filters only
          this.storedSearchEventSession = {
            startMs: new Date().getTime(),
            searchedKeywords: [],
            sortDirections: [sortDirection],
            publisherFiltersAdded: generatedPublishersFilters,
            dateFilters: [dateFilter]
          };

          return;
        }

        if (this.storedSearchEventSession) {
          this.addNewDataToSession(dateFilter, sortDirection, activePublisherFilters);
        }
      }
    });
  }

  private addNewDataToSession(
    dateFilter: IDateFilter = null,
    sortDirection: string = null,
    activePublisherFilters: IPublisherFilter[] = null,
    searchString: string = null
  ): void {
    if (this.storedSearchEventSession === null) {
      return;
    }

    // Check if date filter changed
    if (dateFilter) {
      const lastDate: IDateFilter = this.storedSearchEventSession.dateFilters.slice(-1)[0];
      if (lastDate) {
        if (
          lastDate.startDate.valueOf() !== dateFilter.startDate.valueOf() &&
          lastDate.endDate.valueOf() !== dateFilter.endDate.valueOf()
        ) {
          // Only add if changed
          this.storedSearchEventSession.dateFilters.push(dateFilter);
        }
      } else {
        // Add, since no date exists
        this.storedSearchEventSession.dateFilters.push(dateFilter);
      }
    }

    // Check sort direction
    if (sortDirection) {
      const lastDirection: string = this.storedSearchEventSession.sortDirections.slice(-1)[0];
      if (lastDirection) {
        if (lastDirection !== sortDirection) {
          // Only add if changed
          this.storedSearchEventSession.sortDirections.push(sortDirection);
        }
      } else {
        // Add, since no dir exists
        this.storedSearchEventSession.sortDirections.push(sortDirection);
      }
    }

    // Check activePublisherFilters
    if (activePublisherFilters) {
      const generatedPublishersFilters: string[] = this.generateParentSubfilterFromPublishers(activePublisherFilters);
      this.storedSearchEventSession.publisherFiltersAdded.push(...generatedPublishersFilters);
      this.cleanRepeatedPublishersFromSession();
    }

    // Check serchString
    if (searchString) {
      this.storedSearchEventSession.searchedKeywords.push(searchString);
    }
  }

  private routerChangedSub(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe({
      next: (event: NavigationStart) => {
        if (event.url.includes('profile/') || event.url.includes('open-mind/')) {
          // Terminate session
          this.terminateSearchSession();
        }
      }
    });
  }
}
