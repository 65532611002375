import { createAction, props } from '@ngrx/store';
import { INewsItem } from '../../../news/core/interfaces';
import { IOpenmindItem, IStackedOpenmindFilters } from '../interfaces';

// ------------------- FILTERS ------------------- //
export const SetNumberOfRelatedArticles = createAction(
  '[Openmind] Sets the related articles to bring in the search',
  props<{ numberOfArticles: number }>()
);

// ------------------- ARTICLES ------------------- //

export const SetOpenMindMainArticle = createAction(
  '[Openmind] Sets the main article',
  props<{ mainArticle: INewsItem }>()
);

export const UpdateOpenMindMainArticle = createAction(
  '[Openmind] Update the main article',
  props<{ mainArticleUpdate: Partial<INewsItem>; onlyForId?: string }>()
);

export const SetOpenMindRelatedArticles = createAction(
  '[Openmind] Set the openmind related articles',
  props<{ articles: IOpenmindItem[] }>()
);

export const SetOpenMindDuplicatedRelatedArticlesCount = createAction(
  '[Openmind] Set the openmind duplicated related articles count',
  props<{ duplicatedArticlesCount: number }>()
);

export const SetOpenMindSelectedRelatedCard = createAction(
  '[Openmind] Sets the selected related card',
  props<{ relatedCard: IOpenmindItem }>()
);

export const UpdateOpenMindSelectedRelatedCard = createAction(
  '[Openmind] Update the selected related card',
  props<{ relatedCardUpdate: Partial<IOpenmindItem> }>()
);

// -------------- FILTERED ARTICLES -------------- //
export const UpdateFilteredArticles = createAction(
  '[Openmind] Updates the showed articles by adding some filtering on them',
  props<{ filteredArticles: IOpenmindItem[] }>()
);

export const UpdateRelatedOpenmindArticlesNewsItem = createAction(
  '[Openmind] Updates an article with new details',
  props<{ article: IOpenmindItem }>()
);

export const UpdateChartFilters = createAction(
  '[Openmind] Updates the current filters',
  props<{ currentOpenmindFilters: IStackedOpenmindFilters[] }>()
);

export const UpdateOpenMindFilter = createAction(
  '[Openmind] Updates openmind filters',
  props<{ currentOpenMindFilter: IStackedOpenmindFilters }>()
);

// Modals

export const SetArticleToRenderInModal = createAction(
  '[Openmind] Set article to render in the modal',
  props<{ article: INewsItem }>()
);

// Reading List
export const SetOpenmindReadingListCount = createAction(
  '[Openmind] Set reading lists count',
  props<{ count: number }>()
);
