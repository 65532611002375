import { IFilterChip } from 'src/app/core/filters/core/interfaces';
import { CollectionItemType } from 'src/app/core/filters/core/enums';
import { IResearchCollection } from 'src/app/research-collections/core/interfaces';

export class ResearchCollectionCapturedData {
  public id: string;
  public searchName: string;
  public collections: IResearchCollection[];
  public chips: IFilterChip[];
  public order: number;
  public itemType: CollectionItemType;
  public isDefaultDashboard: boolean;
}
