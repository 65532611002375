import { createReducer, on } from '@ngrx/store';
import {
  IAssignedDataEvent,
  IDashboardDataEvent,
  IPlaceHolderSpaceElement,
  IRenderedVisualization,
  ISavedDashboard
} from '../core/interfaces';
import * as DashboardActionTypes from './dashboard.actions';

export const dashboardStateFeatureKey = 'dashboardState';

export interface IDashboardState {
  version: string;
  activeVisualizations: IRenderedVisualization[];
  // IO (Deprecated)
  connectionEvents: IDashboardDataEvent[];
  selectedVisualizationInputConnector: IRenderedVisualization;
  selectedVisualizationOutputConnector: IRenderedVisualization;
  assignedInputOutputData: IAssignedDataEvent[];
  // Selecting
  activeVisualizationId: string;
  //
  isPanelMovementLocked: boolean;
  visualizationBackgroundImage: string;
  shouldDisplayBackgroundPicker: boolean;
  // Grid, Free modes
  isFreeModeActive: boolean;
  gridElementsPlaceholder: IPlaceHolderSpaceElement[];
  fullScreenElementId: string;
  canResetPanel: boolean;
  // Animations
  isDraggingAVisualization: boolean;
  // Saved dashboards
  savedDashboards: ISavedDashboard[];
  activeDashboardFromSave: ISavedDashboard;
  // Is sidebar opened
  isSidebarOpened: boolean;
  // Loading
  isLoadingDashboardFromApi: boolean;
  // State management
  isDashboardModified: boolean;
}

export const dashboardInitialState: IDashboardState = {
  version: '0.0.0',
  activeVisualizations: [],
  connectionEvents: [],
  selectedVisualizationInputConnector: null,
  selectedVisualizationOutputConnector: null,
  assignedInputOutputData: [],
  activeVisualizationId: null,
  isPanelMovementLocked: false,
  visualizationBackgroundImage: '',
  shouldDisplayBackgroundPicker: false,
  // Grid, free modes
  isFreeModeActive: true,
  gridElementsPlaceholder: [],
  fullScreenElementId: null,
  canResetPanel: false,
  // Animations
  isDraggingAVisualization: false,
  savedDashboards: [],
  activeDashboardFromSave: null,
  isSidebarOpened: false,
  isLoadingDashboardFromApi: false,
  // State management
  isDashboardModified: false
};

export const dashboardReducer = createReducer(
  dashboardInitialState,
  on(DashboardActionTypes.SetVersion, (state, action) => {
    return { ...state, version: action.version };
  }),
  on(DashboardActionTypes.AddDashboardVisualization, (state, action) => {
    return {
      ...state,
      activeVisualizations: [...state.activeVisualizations, action.visualization]
    };
  }),
  on(DashboardActionTypes.RemoveDashboardVisualization, (state, action) => {
    const newGeneratedVisualizations = state.activeVisualizations.filter(
      (viz: IRenderedVisualization) => viz.id !== action.visualizationId
    );

    return { ...state, activeVisualizations: newGeneratedVisualizations };
  }),
  on(DashboardActionTypes.RemoveAllDashboardVisualizations, (state) => {
    return { ...state, activeVisualizations: [] };
  }),
  on(DashboardActionTypes.SetActiveVisualizationId, (state, action) => {
    return { ...state, activeVisualizationId: action.id };
  }),
  on(DashboardActionTypes.SetPanelMovementLocked, (state, action) => {
    return { ...state, isPanelMovementLocked: action.isLocked };
  }),
  // Background
  on(DashboardActionTypes.SetVisualizationBackgroundImage, (state, action) => {
    return { ...state, visualizationBackgroundImage: action.imageInBase64 };
  }),
  on(DashboardActionTypes.setShouldDisplayBackgroundPicker, (state, action) => {
    return { ...state, shouldDisplayBackgroundPicker: action.shouldDisplay };
  }),
  // Grid, Free modes
  on(DashboardActionTypes.setVisualizationFreeMode, (state, action) => {
    return { ...state, isFreeModeActive: action.isFreeMode };
  }),
  on(DashboardActionTypes.setVisualizationResetMode, (state, action) => {
    return { ...state, canResetPanel: action.canResetPanel };
  }),
  on(DashboardActionTypes.setGridFreeSpacePlaceHolders, (state, action) => {
    return {
      ...state,
      gridElementsPlaceholder: action.placeholderElements
    };
  }),
  on(DashboardActionTypes.setFullScreenElement, (state, action) => {
    return {
      ...state,
      fullScreenElementId: action.visualizationId
    };
  }),
  // Animations
  on(DashboardActionTypes.setIsDraggingAVisualization, (state, action) => {
    return {
      ...state,
      isDraggingAVisualization: action.isDragged
    };
  }),
  // Saved dashboards
  on(DashboardActionTypes.setStoredDashboards, (state, action) => {
    return {
      ...state,
      savedDashboards: action.dashboards
    };
  }),
  on(DashboardActionTypes.setActiveDashboardFromSave, (state, action) => {
    return {
      ...state,
      activeDashboardFromSave: action.dashboard
    };
  }),
  // Sidebar
  on(DashboardActionTypes.setIsDashboardSidebarOpened, (state, action) => {
    return {
      ...state,
      isSidebarOpened: action.isOpen
    };
  }),
  // Loading
  on(DashboardActionTypes.setIsLoadingDashboardFromApi, (state, action) => {
    return {
      ...state,
      isLoadingDashboardFromApi: action.isLoading
    };
  }),
  // Updating
  on(DashboardActionTypes.updateVisualization, (state, action) => {
    const updatedVisualization: IRenderedVisualization[] = state.activeVisualizations.map(
      (viz: IRenderedVisualization) => {
        let updatedViz: IRenderedVisualization = viz;

        if (viz.id === action.visualization.id) {
          updatedViz = {
            ...updatedViz,
            ...action.visualization
          };
        }

        return updatedViz;
      }
    );

    return {
      ...state,
      activeVisualizations: updatedVisualization
    };
  }),
  // State
  on(DashboardActionTypes.setIsDashboardModified, (state, action) => {
    return {
      ...state,
      isDashboardModified: action.isModified
    };
  })
);
