import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, switchMap, of } from 'rxjs';
import { authStatusKey } from 'src/app/core/constants/auth-status-key';
import { IAppState } from '../../core/store-app/reducers';
import { IKeycloakUser } from '../interfaces';
import { LogoutAction } from '../store-auth/auth.actions';
import { MainApplicationRoutes } from 'src/app/core/enums';
import { KeycloakLoginOptions } from 'keycloak-js';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isPaidUser = false;

  public user$ = new BehaviorSubject<IKeycloakUser>(null);

  private mockUserSubscription = {
    customSourcesLimit: 10
  };

  private mockPaidUserSubscription = {
    customSourcesLimit: 20
  };

  constructor(
    private keycloakService: KeycloakService,
    private store: Store<IAppState>,
    private router: Router
   ) {}

  public login(options?: KeycloakLoginOptions) {
    this.keycloakService.login(options);
  }

  public register(options?: KeycloakLoginOptions) {
    const newOptions = {
      ...(options || {}),
      action: 'register'
    };

    this.keycloakService.register(newOptions);
  }

  public getUser() {
    return this.user$.pipe(
      switchMap((user: IKeycloakUser) => {
        if (this.isPaidUser) {
          return of({
            ...user,
            isPaidUser: true,
            userSubscriptionDetails: {
              ...this.mockUserSubscription,
              ...this.mockPaidUserSubscription
            }
          });
        }

        return of({
          ...user,
          userSubscriptionDetails: {
            ...this.mockUserSubscription
          }
        });
      })
    );
  }

  public setDevPaidMode(isPaidUser = false) {
    this.isPaidUser = isPaidUser;
  }

  public async logout(): Promise<void> {
    const redirectUrl = `${window.location.origin}/${MainApplicationRoutes.Dashboard}`;

    localStorage.setItem(authStatusKey, JSON.stringify(false));

    await this.keycloakService.logout(redirectUrl);
    this.keycloakService.clearToken();

    this.store.dispatch(new LogoutAction());
    this.router.navigate(['auth', 'login']);
  }
}
