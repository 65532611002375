import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, of, skip, skipWhile, switchMap, take } from 'rxjs';
import { ApiNewsService } from 'src/app/api/api-news.service';
import { IObservedParamsForNews } from 'src/app/api/core/interfaces';
import { SavedTopicCreateCapturedData } from 'src/app/event/models/captured-data-types/save-topic';
import { NeutralityEvent } from 'src/app/event/models/neutrality-event.model';
import { EventDataService } from 'src/app/event/services/neu-event-data.service';
import { NewsDataService, NewsUIService } from 'src/app/news/services';
import { AddSavedTopic, DeleteSavedTopic, UpdateSavedTopics } from 'src/app/news/store-news/news.actions';
import { selectQuickAccessTopics, selectSavedTopics } from 'src/app/news/store-news/news.selectors';
import {
  IQuickAccessSavedOrder,
  ISavedTopic
} from 'src/app/ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { DisplayNotificationService } from 'src/app/shared/global-services/display-notification.service';
import { MainApplicationRoutes } from '../../enums';
import { ViewInfoService } from '../../services/view-info.service';
import { NeuEventCategory, NeuEventType } from '../../track-events/enum';
import { setActiveKeywordFilters, setCategoryFilter } from '../filters-store/filters.actions';
import { SearchFiltersService } from '../search-filters.service';
import { setActiveSelectedSources } from '../sources/sources-filters-store/sources-filters.actions';
import { selectIsProcessingRequests } from 'src/app/news/store-news-management/news-management.selectors';
import { BoleanOneTimeValue } from '../../models';
import { createUUID } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class SavedTopicsService {
  public activeSavedTopic$ = new BehaviorSubject<ISavedTopic>(null);
  public shouldIgnoreInvalidation = new BoleanOneTimeValue();

  constructor(
    private store: Store,
    private newsService: NewsUIService,
    private newsDataService: NewsDataService,
    private newsApiService: ApiNewsService,
    private searchFiltersService: SearchFiltersService,
    private displayNotificationService: DisplayNotificationService,
    private eventDataService: EventDataService,
    private viewInfoService: ViewInfoService
  ) {}

  // MAIN: Save current query and return an observable
  public saveCurrentSearchTopic(
    name: string,
    customParams: Record<string, any> = {},
    isAnalyticsTopic = false
  ): Observable<{ savedTopic: ISavedTopic[]; quickAccessTopics: string[] }> {
    return this.newsApiService
      .getNewsParamsObservable()
      .pipe(take(1))
      .pipe(
        switchMap((newsParams: IObservedParamsForNews) => {
          const stateForSaving: IObservedParamsForNews = {
            ...newsParams,
            availableSourcesFilters: [],
            ...customParams
          };

          const newTopic: ISavedTopic = {
            id: createUUID(15),
            searchName: name,
            order: 0,
            isDefault: false,
            queryParamsState: stateForSaving,
            isAnalyticsTopic
          };

          return combineLatest({
            savedTopic: this.store.pipe(select(selectSavedTopics)),
            quickAccessTopics: this.store.pipe(select(selectQuickAccessTopics))
          }).pipe(
            take(1),
            map(({ savedTopic, quickAccessTopics }: { savedTopic: ISavedTopic[]; quickAccessTopics: string[] }) => {
              return {
                savedTopic: [...savedTopic, newTopic],
                quickAccessTopics
              };
            })
          );
        }),
        switchMap(({ savedTopic, quickAccessTopics }: { savedTopic: ISavedTopic[]; quickAccessTopics: string[] }) => {
          const quickAccessState: IQuickAccessSavedOrder = {
            isQuickAccessSavedOrder: true,
            quickAccessSavedState: quickAccessTopics
          };

          return this.newsDataService
            .saveTopicsObservable([(<unknown>quickAccessState) as ISavedTopic, ...savedTopic])
            .pipe(map(() => ({ savedTopic, quickAccessTopics })));
        })
      );
  }

  // MAIN: Save current query
  public saveCurrentSearch(
    name: string,
    customParams: Record<string, any> = {},
    isAnalyticsTopic = false,
    shouldShowResaveMessage = false
  ) {
    this.newsApiService
      .getNewsParamsObservable()
      .pipe(take(1))
      .pipe(
        switchMap((newsParams: IObservedParamsForNews) => {
          const stateForSaving: IObservedParamsForNews = {
            ...newsParams,
            availableSourcesFilters: [],
            ...customParams
          };

          const newTopic: ISavedTopic = {
            id: createUUID(15),
            searchName: name,
            order: 0,
            isDefault: false,
            queryParamsState: stateForSaving,
            isAnalyticsTopic
          };

          // An effect will save this
          this.store.dispatch(AddSavedTopic({ savedTopic: newTopic }));

          if (shouldShowResaveMessage) {
            this.displayNotificationService.displayNotification(`User resaved ${name} Topic`);
          } else {
            this.displayNotificationService.displayNotification(`Your Topic ${name} has been saved..`);
          }

          // save topic event for dashboard and search
          return this.viewInfoService.currentView$.pipe(take(1));
        }),
        switchMap((currentView: MainApplicationRoutes) => {
          if (currentView === MainApplicationRoutes.Dashboard || currentView === MainApplicationRoutes.Search) {
            const generatedCapturedData: SavedTopicCreateCapturedData = {
              pageName: currentView,
              searchTopic: name
            };

            const generatedEvent: NeutralityEvent = new NeutralityEvent({
              eventCategory: NeuEventCategory.Analytics,
              eventType:
                currentView === MainApplicationRoutes.Search
                  ? NeuEventType.SearchCreateTopic
                  : NeuEventType.DashboardCreateTopic,
              capturedData: generatedCapturedData,
              shouldBeSaved: true
            });

            return this.eventDataService.saveEvent(generatedEvent).pipe(take(1));
          }

          return of(null);
        })
      )
      .subscribe();
  }

  public setActiveSavedTopic(savedTopic: ISavedTopic): void {
    // clear previous state
    this.searchFiltersService.clearAllFiltersForQuery(true);

    if (!savedTopic) {
      this.activeSavedTopic$.next(savedTopic);

      return;
    }

    const observedParamsForQuery: Partial<IObservedParamsForNews> = savedTopic.queryParamsState;

    // Search value
    this.newsService.executeNewSavedSearchQuery(observedParamsForQuery.currentQuery || '');
    this.activeSavedTopic$.next(savedTopic);

    if (observedParamsForQuery.categoryFilters) {
      this.store.dispatch(setCategoryFilter({ categoryFilters: observedParamsForQuery.categoryFilters }));
    }

    this.store.dispatch(setActiveSelectedSources({ sources: observedParamsForQuery.selectedActiveSourcesFilters }));

    this.store
      .pipe(
        select(selectIsProcessingRequests),
        skip(1),
        skipWhile((isProcessingRequests: boolean) => isProcessingRequests),
        take(1)
      )
      .subscribe({
        next: () => {
          this.store.dispatch(setActiveKeywordFilters({ filters: observedParamsForQuery.activeKeywordsFilters }));
        }
      });

    // TODO: Sentiment, Relevancy
  }

  public unselectSavedTopic() {
    this.searchFiltersService.clearAllFiltersForQuery(true);
    this.activeSavedTopic$.next(null);
  }

  public deleteSavedTopic(topic: ISavedTopic) {
    this.store.dispatch(DeleteSavedTopic({ savedTopic: topic }));
  }

  public requestSavedTopicsFromAPI(): Promise<ISavedTopic[]> {
    return new Promise((resolve) => {
      this.newsDataService
        .getSavedTopics()
        .pipe(take(1))
        .subscribe({
          next: (savedTopics: ISavedTopic[]) => {
            if (savedTopics && savedTopics.length) {
              this.store.dispatch(UpdateSavedTopics({ savedTopics }));
              resolve(savedTopics);
            } else {
              this.store.dispatch(UpdateSavedTopics({ savedTopics: [] }));
              resolve([]);
            }
          },
          error: (err: Error) => {
            this.store.dispatch(UpdateSavedTopics({ savedTopics: [] }));
            console.error(err);
            resolve([]);
          }
        });
    });
  }

  public selectSavedTopics() {
    return this.store.pipe(select(selectSavedTopics));
  }
}
