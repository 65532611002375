import { Injectable } from '@angular/core';
import { INewsItem, INewsApi, IStackNewsItemsResults } from '../core/interfaces';
import * as moment from 'moment';
import { ApiUtilitiesService } from 'src/app/api/api-utilities.service';
import { stringSimilarity } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class SameNewsDifferentSourceService {
  constructor(private apiUtilitiesService: ApiUtilitiesService) {}

  /**
   * This will return the final data that will be handled
   */
  public mergeDatasetRedundantElements(apiNews: INewsApi, userId: string): IStackNewsItemsResults {
    const newsItems: INewsItem[] = [];

    // Create cleaned list without duplicates
    for (const apiDocument of apiNews.docs) {
      const articleElement: INewsItem = this.apiUtilitiesService.mapNewsFromApi(apiDocument, userId);

      // disable de-duplication
      const alreadyIncludedElementIndex = -1;

      if (alreadyIncludedElementIndex === -1) {
        newsItems.push(articleElement);
      } else {
        const elementIncluded = newsItems[alreadyIncludedElementIndex];
        // Update reading list of the original in case a duplicate has any of the tags set to trues
        articleElement.isDuplicate = true;
        elementIncluded.isReadingListSaved = articleElement.isReadingListSaved
          ? articleElement.isReadingListSaved
          : elementIncluded.isReadingListSaved;
      }
    }
    // Stack
    // disable stacking similar items
    // const stackedNewsItems: INewsItem[] = this.stackSameArticleDifferentSources(newsItems);
    const stackedNewsItems: INewsItem[] = newsItems;
    /**
     * Skipped elements is the difference between the results docs and the final docs
     */
    const skippedElements: number = apiNews.docs.length - stackedNewsItems.length;

    const resultStack: IStackNewsItemsResults = {
      newsItems: this.swapMainArticleWithOldestInTheStack(stackedNewsItems),
      skippedElements
    };

    return resultStack;
  }

  /**
   * Stacks the elements to be represented
   * Demo search: How Matthew Stafford's
   */
  public stackSameArticleDifferentSources(newsItems: INewsItem[]): INewsItem[] {
    let stackedNews: INewsItem[] = [];

    for (const articleItem of newsItems) {
      if (stackedNews.length === 0) {
        stackedNews.push(articleItem);
        continue; // Early *return*
      }

      // Check if one of the already added elements has the same title
      let hasElementBeenStacked = false;
      for (const stackParent of stackedNews) {
        /**
         * Value 1 is almost exact match, 0.8 is pretty similar
         * Example:
         *  How Matthew Stafford's COVID-19 scare kept Detroit Lions-Saints game on for Sunday
         *  How Matthew Stafford's COVID-19 scare kept Lions-Saints game on for Sunday
         *  Similarity: 0.902
         */
        const similarityThreshold = 0.8;
        const titleSimilarity = stringSimilarity(stackParent.title.trim(), articleItem.title.trim());

        if (titleSimilarity >= similarityThreshold) {
          if (stackParent.stackedArticlesWithDifferentSource) {
            stackParent.stackedArticlesWithDifferentSource.push(articleItem);
          } else {
            stackParent.stackedArticlesWithDifferentSource = [articleItem];
          }

          hasElementBeenStacked = true;
          continue; // Early *return*
        }
      }

      if (!hasElementBeenStacked) {
        stackedNews.push(articleItem);
      }
    }

    // Filter Stacked news by date
    stackedNews = stackedNews.sort((element1: INewsItem, element2: INewsItem) => {
      const date1: number = moment(element1.date).valueOf();
      const date2: number = moment(element2.date).valueOf();

      if (date1 > date2) {
        return 1;
      }
      if (date1 < date2) {
        return -1;
      }

      return 0;
    });

    return stackedNews;
  }

  /**
   * Checks inside the dataset and children, if the same title + source exists
   */
  private getDuplicateArticleIndex(articleElement: INewsItem, newsItems: INewsItem[]): number {
    for (const [index, articleItem] of newsItems.entries()) {
      // Check parent

      if (
        articleElement.publisher.valueOf().trim() === articleItem.publisher.valueOf().trim() &&
        articleElement.title.valueOf().trim() === articleItem.title.valueOf().trim() &&
        articleElement.description.valueOf().trim() === articleItem.description.valueOf().trim()
      ) {
        return index;
      }
      // Check children
      if (articleItem.stackedArticlesWithDifferentSource) {
        for (const articleChildren of articleItem.stackedArticlesWithDifferentSource) {
          if (
            articleChildren.publisher.valueOf().trim() === articleItem.publisher.valueOf().trim() &&
            articleChildren.title.valueOf().trim() === articleItem.title.valueOf().trim() &&
            articleChildren.description.valueOf().trim() === articleItem.description.valueOf().trim()
          ) {
            return index; // Not a mistake, we need the parent index
          }
        }
      }
    }

    // There is no a duplicate in the given stack
    return -1;
  }

  private swapMainArticleWithOldestInTheStack(stackedNewsItems: INewsItem[]): INewsItem[] {
    for (const [index, article] of stackedNewsItems.entries()) {
      if (article.stackedArticlesWithDifferentSource?.length) {
        let earliestArticle: INewsItem = article;
        let swappedArticle: INewsItem = null;

        // Check if any article in the stack is before the main parent article
        for (const stackedArticle of article.stackedArticlesWithDifferentSource) {
          if (moment(stackedArticle.date).isBefore(moment(earliestArticle.date))) {
            // This stacked article is before the earliest registered article
            earliestArticle = stackedArticle;
            swappedArticle = article;
          }
        }

        if (earliestArticle && swappedArticle) {
          // Swap the parent
          const newStack: INewsItem[] = stackedNewsItems[index].stackedArticlesWithDifferentSource.filter(
            (filteredArticle: INewsItem) => {
              return filteredArticle.id !== earliestArticle.id;
            }
          );

          stackedNewsItems[index] = {
            ...earliestArticle,
            stackedArticlesWithDifferentSource: newStack
          };
        }
      }
    }

    return stackedNewsItems;
  }
}
