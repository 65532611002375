import { createAction, props } from '@ngrx/store';
import { IBreadcrumb } from '../breadcrumb/interfaces';
import { FilterMenuType } from '../../core/filters/core/enums';

// ---------------------- Sidebar menu ---------------------- //
export const ToggleSidebarMenu = createAction('[News] Toggle sidebar responsive');

export const OpenOrCloseSidebarMenu = createAction(
  '[News] Open or close sidebar responsive',
  props<{ isOpenSidebar: boolean }>()
);

// ---------------------- Notifications menu ---------------------- //
export const ToggleNotificationsMenu = createAction('[News] Toggle notifications responsive');

export const OpenOrCloseNotificationsMenu = createAction(
  '[News] Open or close notificaions responsive',
  props<{ isOpen: boolean }>()
);

// ---------------------- Menu drawer ---------------------- //
export const ToggleMenuDrawer = createAction('[News] Toggle menu drawer responsive responsive');

export const OpenOrCloseMenuDrawer = createAction(
  '[News] Open or close menu drawer responsive',
  props<{ isOpen: boolean }>()
);

// ---------------------- Menu drawer ---------------------- //

export const SetModalOpen = createAction('[News] Set open modals responsive', props<{ active: FilterMenuType }>());

// ---------------------- Menu drawer ---------------------- //

export const SetIsSmallScreen = createAction('[News] Set ois small screen responsive', props<{ isSmall: boolean }>());

// ---------------------- Breadcrumb ---------------------- //

export const SetBreadcrumb = createAction('[News] Set breadcrumb', props<{ breadcrumb: IBreadcrumb }>());

// ---------------------- Show/hide visualization menu ---------------------- //

export const SetVisualizationMenu = createAction(
  '[News] Show/hide visualization menu',
  props<{ showVisualizationMenu: boolean }>()
);

export const SetIsSearchbarEmpty = createAction(
  '[News] Set if the searchbar is empty or not',
  props<{ isEmpty: boolean }>()
);

export const SetSearchBarValue = createAction(
  '[News] Set the current search bar value',
  props<{ searchValue: string }>()
);

export const SetOverrideSearchBarValue = createAction(
  '[News] Override the current search bar value',
  props<{ searchValue: string }>()
);

// App Navigation
export const SetCanGoBackToNeutralityRoute = createAction(
  '[News] Set can go to app route',
  props<{ canGoBack: boolean }>()
);
