export class LRUNode<T> {
  public key: string;
  public value: T;
  public next: LRUNode<T> | null;
  public prev: LRUNode<T> | null;

  constructor(key: string, value: T, next = null, prev = null) {
    this.key = key;
    this.value = value;
    this.next = next;
    this.prev = prev;
  }
}

export class LRUCache<T> {
  public size: number;
  public limit: number;
  public head: LRUNode<T> | null;
  public tail: LRUNode<T> | null;
  public cacheMap: Record<string, LRUNode<T>>;
  public cacheTimeMap: Record<string, number>;
  public evictionTimeInMilliseconds: number | null;

  constructor(limit = 10, evictionTimeInMilliseconds = null) {
    this.size = 0;
    this.limit = limit;
    this.head = null;
    this.tail = null;
    this.cacheMap = {};
    this.cacheTimeMap = {};
    this.evictionTimeInMilliseconds = evictionTimeInMilliseconds;
  }

  public write(key: string, value: T): void {
    const existingNode = this.cacheMap[key];

    if (existingNode) {
      this.detach(existingNode);
      this.size--;
    } else if (this.size === this.limit) {
      this.removeNode(this.tail);
    }

    // Write to head of LinkedList
    if (!this.head) {
      this.tail = new LRUNode(key, value);
      this.head = this.tail;
    } else {
      const node = new LRUNode(key, value, this.head);
      this.head.prev = node;
      this.head = node;
    }

    // update cacheMap with LinkedList key and Node reference
    this.cacheMap[key] = this.head;

    if (this.evictionTimeInMilliseconds && !this.cacheTimeMap[key]) {
      this.cacheTimeMap[key] = Date.now();
    }

    this.size++;
  }

  public read(key: string): T {
    const existingNode: LRUNode<T> = this.cacheMap[key];

    if (existingNode) {
      if (this.evictionTimeInMilliseconds && Date.now() - this.cacheTimeMap[key] > this.evictionTimeInMilliseconds) {
        this.removeNode(existingNode);

        return null;
      }

      const value = existingNode.value;

      // Make the node as new Head of LinkedList if not already
      if (this.head !== existingNode) {
        // write will automatically remove the node from it's position and make it a new head i.e most used
        this.write(key, value);
      }

      return value;
    }

    return null;
  }

  public clear(): void {
    this.head = null;
    this.tail = null;
    this.size = 0;
    this.cacheMap = {};
    this.cacheTimeMap = {};
  }

  // Invokes the callback function with every node of the chain and the index of the node.
  public forEach(fn): void {
    let node = this.head;
    let counter = 0;

    while (node) {
      fn(node, counter);
      node = node.next;
      counter++;
    }
  }

  // To iterate over LRU with a 'for...of' loop
  *[Symbol.iterator]() {
    let node = this.head;
    while (node) {
      yield node;
      node = node.next;
    }
  }

  private detach(node: LRUNode<T>): void {
    if (!node) {
      return;
    }

    if (node.prev !== null) {
      node.prev.next = node.next;
    } else {
      this.head = node.next;
    }

    if (node.next !== null) {
      node.next.prev = node.prev;
    } else {
      this.tail = node.prev;
    }
  }

  private removeNode(node: LRUNode<T>): void {
    if (!node) {
      return;
    }

    delete this.cacheMap[node.key];

    if (this.evictionTimeInMilliseconds) {
      delete this.cacheTimeMap[node.key];
    }

    this.detach(node);
    this.size--;
  }
}
