import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageType } from 'src/app/neutrality-root/alert-banner/enums/message-type.enum';
import {
  SetNotificationState,
  SetNotificationText,
  SetNotificationType
} from 'src/app/neutrality-root/alert-banner/alert-banner-store/alert-banner.actions';

@Injectable({
  providedIn: 'root'
})
export class DisplayNotificationService {
  constructor(private store: Store) {}

  public displayNotification(msg: string, messageType: MessageType = MessageType.Information) {
    this.store.dispatch(SetNotificationText({ notificationText: msg }));
    this.store.dispatch(SetNotificationType({ notificationType: messageType }));
    this.store.dispatch(SetNotificationState({ showNotification: true }));
  }
}
