import { ISearchAnalyticsFilter } from '../interfaces';

const SEARCH_ANALYTIC_FILTER_OPTIONS: string[] = [
  '1 Day',
  '7 Days',
  '1 Month',
  '3 Months',
  '6 Months',
  '1 Year by Day',
  '1 Year by Month',
  'By Year'
];

export const getSearchAnalyticParams = (): ISearchAnalyticsFilter[] => {
  const options: ISearchAnalyticsFilter[] = SEARCH_ANALYTIC_FILTER_OPTIONS.map((text: string) => {
    return {
      text,
      filter: text
    };
  });

  return options;
};
