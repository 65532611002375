import { createReducer, on } from '@ngrx/store';
import * as NewsManagementActionTypes from './news-management.actions';

export const newsManagementFeatureKey = 'newsManagementState';

export interface INewsManagementState {
  isProcessingRequests: boolean;
}

export const newsManagementInitialState: INewsManagementState = {
  isProcessingRequests: false
};

export const newsManagementReducer = createReducer(
  newsManagementInitialState,
  on(NewsManagementActionTypes.setIsProcessingRequests, (state: INewsManagementState, action) => {
    return { ...state, isProcessingRequests: action.isProcessing };
  })
);
