export enum SortType {
  // Ascending = 'neutrality_article_normpubDate ',
  // Descending = 'neutrality_article_normpubDate ',
  Date = 'neutrality_article_normpubDate ',
  Relevance = 'score ',
  Category = 'articleprint_s ',
  Publisher = 'neutrality_article_publisher_s ',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Topic = 'articleprint_s ',
  Title = 'neutrality_article_title_s ',
  Author = 'neutrality_article_author_s '
}
