import { AuthActions, AuthActionTypes } from './auth.actions';
import { IUser } from '../interfaces';

export const authStateFeatureKey = 'authState';

const authInitialState: IAuthState = {
  user: undefined
};

export interface IAuthState {
  user: IUser;
}

export function authReducer(state = authInitialState, action: AuthActions): IAuthState {
  switch (action.type) {
    case AuthActionTypes.SetUser: {
      return {
        ...state,
        user: action.payload.user
      };
    }
    case AuthActionTypes.LogoutAction: {
      return {
        ...state,
        user: undefined
      };
    }
    default:
      return { ...state };
  }
}
