import * as moment from 'moment';
import { IDateFilter } from 'src/app/news-filters/core/interfaces';
import { isDateToday } from 'src/app/news-filters/core/utils';
import { DateFormatType } from '../filters/core/enums';
import { IDatePickerValue } from '../interfaces';
import { solrDefaultDateFormatting } from './query-tools';

export function getDateFilterFromPartial(action: Partial<IDateFilter>): IDateFilter {
  let startDate: moment.Moment = action?.startDate;
  let endDate: moment.Moment = action?.endDate;
  let displayString = action?.displayString;
  let apiQuery = action?.apiQuery;

  if (action.predefinedFilter) {
    apiQuery = 'neutrality_article_normpubDate:[';
    switch (action.predefinedFilter) {
      case DateFormatType.Yesterday: {
        displayString = 'Yesterday';
        const yesterdayMoment: string = moment().subtract(1, 'day').startOf('day').format(solrDefaultDateFormatting);
        const yesterdayEndMoment: string = moment().subtract(1, 'day').endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${yesterdayMoment} TO ${yesterdayEndMoment}]`;
        break;
      }
      case DateFormatType.DayAgo2: {
        displayString = DateFormatType.DayAgo2;
        const yesterdayMoment: string = moment().subtract(2, 'day').startOf('day').format(solrDefaultDateFormatting);
        const yesterdayEndMoment: string = moment().subtract(2, 'day').endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${yesterdayMoment} TO ${yesterdayEndMoment}]`;
        break;
      }
      case DateFormatType.DayAgo3: {
        displayString = DateFormatType.DayAgo3;
        const yesterdayMoment: string = moment().subtract(3, 'day').startOf('day').format(solrDefaultDateFormatting);
        const yesterdayEndMoment: string = moment().subtract(3, 'day').endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${yesterdayMoment} TO ${yesterdayEndMoment}]`;
        break;
      }
      case DateFormatType.DayAgo4: {
        displayString = DateFormatType.DayAgo4;
        const yesterdayMoment: string = moment().subtract(4, 'day').startOf('day').format(solrDefaultDateFormatting);
        const yesterdayEndMoment: string = moment().subtract(4, 'day').endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${yesterdayMoment} TO ${yesterdayEndMoment}]`;
        break;
      }
      case DateFormatType.DayAgo5: {
        displayString = DateFormatType.DayAgo5;
        const yesterdayMoment: string = moment().subtract(5, 'day').startOf('day').format(solrDefaultDateFormatting);
        const yesterdayEndMoment: string = moment().subtract(5, 'day').endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${yesterdayMoment} TO ${yesterdayEndMoment}]`;
        break;
      }
      case DateFormatType.DayAgo6: {
        displayString = DateFormatType.DayAgo6;
        const yesterdayMoment: string = moment().subtract(6, 'day').startOf('day').format(solrDefaultDateFormatting);
        const yesterdayEndMoment: string = moment().subtract(6, 'day').endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${yesterdayMoment} TO ${yesterdayEndMoment}]`;
        break;
      }
      case DateFormatType.DayAgo7: {
        displayString = DateFormatType.DayAgo7;
        const yesterdayMoment: string = moment().subtract(7, 'day').startOf('day').format(solrDefaultDateFormatting);
        const yesterdayEndMoment: string = moment().subtract(7, 'day').endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${yesterdayMoment} TO ${yesterdayEndMoment}]`;
        break;
      }
      case DateFormatType.Today: {
        displayString = 'Today';
        const todayMoment: string = moment().startOf('day').format(solrDefaultDateFormatting);
        const todayEndMoment: string = moment().endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${todayMoment} TO ${todayEndMoment}]`;
        break;
      }
      case DateFormatType.Last3Hours: {
        displayString = 'Last 3 Hours';
        apiQuery += 'NOW/HOUR-3HOURS TO NOW]';
        break;
      }
      case DateFormatType.Last6Hours: {
        displayString = 'Last 6 Hours';
        apiQuery += 'NOW/HOUR-6HOURS TO NOW]';
        break;
      }
      case DateFormatType.Last12Hours: {
        displayString = 'Last 12 Hours';
        apiQuery += 'NOW/HOUR-12HOURS TO NOW]';
        break;
      }
      case DateFormatType.Last24Hours: {
        displayString = 'Last 24 Hours';
        apiQuery += 'NOW/HOUR-24HOURS TO NOW]';
        break;
      }
      case DateFormatType.Last7: {
        displayString = 'Last 7 Days';
        const sevenDaysBeforeMoment: string = moment()
          .subtract(6, 'day')
          .startOf('day')
          .format(solrDefaultDateFormatting);
        const todayEndMoment: string = moment().endOf('day').format(solrDefaultDateFormatting);
        apiQuery += `${sevenDaysBeforeMoment} TO ${todayEndMoment}]`;
        break;
      }
      case DateFormatType.AllDates: {
        displayString = 'All Dates';
        apiQuery += '* TO NOW]';
        break;
      }
      case DateFormatType.ThisMonth: {
        displayString = 'This Month';
        const startOfTheMonthMoment: string = moment().startOf('month').format(solrDefaultDateFormatting);
        const endOfTheMonthMoment: string = moment().format(solrDefaultDateFormatting);
        apiQuery += `${startOfTheMonthMoment} TO ${endOfTheMonthMoment}]`;
        break;
      }
      case DateFormatType.LastMonth: {
        displayString = 'Last Month';
        const lastMonthMoment: string = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format(solrDefaultDateFormatting);
        const lastMonthMomentEndMoment: string = moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(solrDefaultDateFormatting);
        apiQuery += `${lastMonthMoment} TO ${lastMonthMomentEndMoment}]`;
        break;
      }
      case DateFormatType.ThisYear: {
        displayString = 'This Year';
        const startOfTheYearMoment: string = moment().startOf('year').format(solrDefaultDateFormatting);
        const endOfTheYearMoment: string = moment().format(solrDefaultDateFormatting);
        apiQuery += `${startOfTheYearMoment} TO ${endOfTheYearMoment}]`;
        break;
      }
      case DateFormatType.LastYear: {
        displayString = 'Last Year';
        const lastYearMoment: string = moment().subtract(1, 'year').startOf('year').format(solrDefaultDateFormatting);
        const lastYearMomentEndMoment: string = moment()
          .subtract(1, 'year')
          .endOf('year')
          .format(solrDefaultDateFormatting);
        apiQuery += `${lastYearMoment} TO ${lastYearMomentEndMoment}]`;
        break;
      }
    }
  } else {
    const isStartDateToday: boolean = isDateToday(action.startDate);
    const isEndDateToday: boolean = isDateToday(action.startDate);

    // Not predefined, so default range
    startDate = action.startDate;
    endDate = action.endDate;

    if (isStartDateToday) {
      // End date must be today too, so use the same as Today
      displayString = 'Today';
      const todayMoment: string = moment().startOf('day').format(solrDefaultDateFormatting);
      const todayEndMoment: string = moment().endOf('day').format(solrDefaultDateFormatting);
      apiQuery = `neutrality_article_normpubDate:[${todayMoment} TO ${todayEndMoment}]`;

      return { startDate, endDate, displayString, apiQuery };
    }

    if (isEndDateToday) {
      // End date today, use now as end
      displayString = `From ${startDate.format('L')} To NOW}`;
      apiQuery = `neutrality_article_normpubDate:[${moment(startDate).format(solrDefaultDateFormatting)} TO NOW]`;
    }

    // Default, from one date to another
    displayString = `From ${startDate.format('L')} To ${endDate.format('L')}`;
    apiQuery = `neutrality_article_normpubDate:[${moment(startDate)
      .startOf('day')
      .format(solrDefaultDateFormatting)} TO ${moment(endDate).endOf('day').format(solrDefaultDateFormatting)}]`;
  }

  return { startDate, endDate, displayString, apiQuery };
}

export function getDatePickerValueFromPredefined(predefinedFilter: DateFormatType): IDatePickerValue {
  const datePickerValue: IDatePickerValue = {
    bsInlineRangeValue: [],
    dateRange: []
  };

  switch (predefinedFilter) {
    case DateFormatType.Today: {
      datePickerValue.bsInlineRangeValue = [moment().toDate(), moment().toDate()];
      datePickerValue.dateRange = [undefined, undefined];
      break;
    }
    case DateFormatType.Yesterday: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(1, 'days').startOf('day').toDate(),
        moment().subtract(1, 'days').endOf('day').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(1, 'days').startOf('day').toDate(),
        moment().subtract(1, 'days').endOf('day').toDate()
      ];
      break;
    }
    case DateFormatType.DayAgo2: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(2, 'days').startOf('day').toDate(),
        moment().subtract(2, 'days').endOf('day').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(2, 'days').startOf('day').toDate(),
        moment().subtract(2, 'days').endOf('day').toDate()
      ];
      break;
    }
    case DateFormatType.DayAgo3: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(3, 'days').startOf('day').toDate(),
        moment().subtract(3, 'days').endOf('day').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(3, 'days').startOf('day').toDate(),
        moment().subtract(3, 'days').endOf('day').toDate()
      ];
      break;
    }
    case DateFormatType.DayAgo4: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(4, 'days').startOf('day').toDate(),
        moment().subtract(4, 'days').endOf('day').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(4, 'days').startOf('day').toDate(),
        moment().subtract(4, 'days').endOf('day').toDate()
      ];
      break;
    }
    case DateFormatType.DayAgo5: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(5, 'days').startOf('day').toDate(),
        moment().subtract(5, 'days').endOf('day').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(5, 'days').startOf('day').toDate(),
        moment().subtract(5, 'days').endOf('day').toDate()
      ];
      break;
    }
    case DateFormatType.DayAgo6: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(6, 'days').startOf('day').toDate(),
        moment().subtract(6, 'days').endOf('day').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(6, 'days').startOf('day').toDate(),
        moment().subtract(6, 'days').endOf('day').toDate()
      ];
      break;
    }
    case DateFormatType.DayAgo7: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(7, 'days').startOf('day').toDate(),
        moment().subtract(7, 'days').endOf('day').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(7, 'days').startOf('day').toDate(),
        moment().subtract(7, 'days').endOf('day').toDate()
      ];
      break;
    }
    case DateFormatType.Last12Hours: {
      datePickerValue.bsInlineRangeValue = [moment().subtract(12, 'hour').toDate(), moment().toDate()];
      datePickerValue.dateRange = [moment().subtract(12, 'hour').toDate(), moment().toDate()];
      break;
    }
    case DateFormatType.Last24Hours: {
      datePickerValue.bsInlineRangeValue = [moment().subtract(24, 'hour').toDate(), moment().toDate()];
      datePickerValue.dateRange = [moment().subtract(24, 'hour').toDate(), moment().toDate()];
      break;
    }
    case DateFormatType.Last7: {
      datePickerValue.bsInlineRangeValue = [moment().subtract(6, 'days').startOf('day').toDate(), moment().toDate()];
      datePickerValue.dateRange = [moment().subtract(6, 'days').startOf('day').toDate(), moment().toDate()];
      break;
    }
    case DateFormatType.ThisMonth: {
      datePickerValue.bsInlineRangeValue = [moment().startOf('month').toDate(), moment().toDate()];
      datePickerValue.dateRange = [moment().startOf('month').toDate(), moment().toDate()];
      break;
    }
    case DateFormatType.LastMonth: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate()
      ];
      break;
    }
    case DateFormatType.ThisYear: {
      datePickerValue.bsInlineRangeValue = [moment().startOf('year').toDate(), moment().toDate()];
      datePickerValue.dateRange = [moment().startOf('year').toDate(), moment().toDate()];
      break;
    }
    case DateFormatType.LastYear: {
      datePickerValue.bsInlineRangeValue = [
        moment().subtract(1, 'year').startOf('year').toDate(),
        moment().subtract(1, 'year').endOf('year').toDate()
      ];
      datePickerValue.dateRange = [
        moment().subtract(1, 'year').startOf('year').toDate(),
        moment().subtract(1, 'year').endOf('year').toDate()
      ];
      break;
    }
    case DateFormatType.AllDates: {
      datePickerValue.bsInlineRangeValue = [moment().startOf('month').toDate(), moment().endOf('month').toDate()];
      datePickerValue.dateRange = [undefined, undefined];
      break;
    }
    default: {
      datePickerValue.dateRange = [undefined, undefined];
      break;
    }
  }

  return datePickerValue;
}
