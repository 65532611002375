import { createAction, props } from '@ngrx/store';

export const dashboardEventStartState = createAction(
  '[Dashboard Event] dashboard event start state',
  props<{ isDashboardEventStart: boolean }>()
);

export const setDashboardEventPanelName = createAction(
  '[Dashboard Event] set dashboard event panel Name',
  props<{ panelName: string }>()
);

export const setDashboardEventNameChangeState = createAction(
  '[Dashboard Event] set dashboard event name change state',
  props<{ nameChanged: boolean }>()
);

export const setDashboardEventexpandPanelState = createAction(
  '[Dashboard Event] set dashboard event expand panel state',
  props<{ expandPanel: boolean }>()
);

export const setDashboardEventHiddenState = createAction(
  '[Dashboard Event] set dashboard event hidden state',
  props<{ hidden: boolean }>()
);

export const setDashboardEventDeletedState = createAction(
  '[Dashboard Event] set dashboard event deleted state',
  props<{ deleted: boolean }>()
);

export const setDashboardEventMoveState = createAction(
  '[Dashboard Event] set dashboard event move state',
  props<{ move: boolean }>()
);

export const setDashboardEventDuplicatePanelState = createAction(
  '[Dashboard Event] set dashboard event duplicate panel state',
  props<{ duplicatePanel: boolean }>()
);

export const setDashboardEventApplySearch = createAction(
  '[Dashboard Event] set dashboard event apply search',
  props<{ applySearch: string }>()
);

export const resetDashboardEvent = createAction('[Dashboard Event] reset dashboard event');
