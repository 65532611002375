export const convertMsToTime = (milliseconds: number): string => {
  let seconds: number = Math.floor(milliseconds / 1000);
  let minutes: number = Math.floor(seconds / 60);
  const hours: number = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0');
};
