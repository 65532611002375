import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NeuSearchEventsService } from 'src/app/event/services/neu-event-categories/neu-search-events.service';
import { SetOverrideSearchBarValue } from 'src/app/neutrality-root/store/responsive.actions';
import {
  ClearAllFiltersButDate,
  setDateFilter,
  SetMyFilterActive
} from 'src/app/news-filters/store-filters/filters.actions';
import { NewsUIService } from 'src/app/news/services';
import {
  DeleteAllSearchChip,
  InvalidateCurrentActiveSearch,
  UpdateCurrentQuery
} from 'src/app/news/store-news/news.actions';
import { DateFormatType } from './core/enums';
import { setActiveKeywordFilters, setCategoryFilter } from './filters-store/filters.actions';
import { setActiveSelectedSources } from './sources/sources-filters-store/sources-filters.actions';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchFiltersService {
  public clearNotifier$ = new Subject<void>();

  constructor(
    private store: Store,
    private neuSearchEventsService: NeuSearchEventsService,
    private newsService: NewsUIService
  ) {}

  public clearAllFiltersForQuery(shouldKeepDate = false) {
    this.newsService.executeNewSavedSearchQuery('');
    this.store.dispatch(ClearAllFiltersButDate({ timestamp: new Date().getTime() }));
    this.store.dispatch(
      SetOverrideSearchBarValue({
        searchValue: ''
      })
    );
    this.store.dispatch(UpdateCurrentQuery({ currentQuery: '' }));
    this.neuSearchEventsService.terminateSearchSession();
    this.store.dispatch(setActiveKeywordFilters({ filters: [] }));
    this.store.dispatch(InvalidateCurrentActiveSearch());

    if (!shouldKeepDate) {
      this.store.dispatch(
        setDateFilter({
          startDate: moment().startOf('day'),
          endDate: moment().endOf('day'),
          predefinedFilter: DateFormatType.Today
        })
      );
    }

    this.store.dispatch(DeleteAllSearchChip());
    this.store.dispatch(SetMyFilterActive(null));
    this.store.dispatch(setActiveKeywordFilters({ filters: [] }));
    this.store.dispatch(setActiveSelectedSources({ sources: [] }));
    this.store.dispatch(setCategoryFilter({ categoryFilters: [] }));
    this.clearNotifier$.next();
  }
}
