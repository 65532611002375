import { NeuLoginModes } from 'src/app/core/track-events/enum';
import { IDeviceInfo } from 'src/app/event/interfaces';

export class LoginCapturedData {
  public eventSource: NeuLoginModes;
  public deviceInfo: IDeviceInfo;
  public screenSize: string;
  public IPAddress: string;
  public IPCity: string;
  public IPState: string;
  public IPCountry: string;
  public IPCurrency: string;
  public IPContinent: string;
  public cookieId: string;
}
