import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, mapTo, Observable, Subscriber, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILoginSession } from './core/interfaces';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IKeycloakUser } from '../auth/interfaces';
import { NetworkCachedSubject } from '../core/models';
import { IProfileDetails } from './core/interfaces/profile-details.interface';
import { InterceptorIgnoreHeader } from '../core/enums';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private profileImageUrlCache = new NetworkCachedSubject<string>('');
  private profileImageCache = new NetworkCachedSubject<string>('');
  private profileDetailsCache = new NetworkCachedSubject<IProfileDetails>(null);
  private profileCompletePercent = 0;
  private profileComplete = new BehaviorSubject<{ profileCompletePercent: number }>({ profileCompletePercent: 0 });
  private isProfileOptInRevenue = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public uploadProfileImage(file: File): Observable<boolean> {
    const formData = new FormData();

    formData.append('image', file);

    return this.http
      .post(`${environment.API_ENDPOINT}/user-storage/profile-image`, formData, {
        headers: {
          [InterceptorIgnoreHeader.InterceptorIgnoreContentType]: 'true'
        }
      })
      .pipe(
        switchMap(() => {
          return this.imageToString(file);
        }),
        tap((image: string) => {
          this.profileImageCache.subject$.next(image);
        }),
        mapTo(true)
      );
  }

  public getProfileImageUrl(): Observable<string> {
    const profileImageUrlRequest: Observable<string> = this.http
      .get<{ url: string }>(`${environment.API_ENDPOINT}/user-storage/profile-image`)
      .pipe(
        map((urlResponse: { url: string }) => {
          return urlResponse.url;
        })
      );

    return this.profileImageUrlCache.next(profileImageUrlRequest);
  }

  public getProfileImage(): Observable<string> {
    const profileImageRequest: Observable<string> = this.getProfileImageUrl().pipe(
      switchMap((url: string) => {
        return this.http.get(url, {
          headers: {
            [InterceptorIgnoreHeader.InterceptorIgnore]: 'true',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
          },
          responseType: 'blob'
        });
      }),
      switchMap((imageResponse: Blob) => {
        const imageAsString = this.imageToString(imageResponse);

        return imageAsString;
      })
    );

    return this.profileImageCache.next(profileImageRequest);
  }

  public getLoginSessions(): Observable<ILoginSession[]> {
    return this.http.get<ILoginSession[]>(`${environment.API_ENDPOINT}/admin/login-details`).pipe(
      map((sessionResponse: ILoginSession[]) => {
        return sessionResponse;
      })
    );
  }

  public getEmail(): Observable<string> {
    return this.authService.getUser().pipe(
      map((user: IKeycloakUser) => {
        return user.email;
      })
    );
  }

  public getProfileDetails() {
    const profileRequest: Observable<IProfileDetails> = this.http.get<IProfileDetails>(
      `${environment.API_ENDPOINT}/user-storage/user-preferences`
    );

    return this.profileDetailsCache.next(profileRequest);
  }

  public setProfileDetails(profileDetails: IProfileDetails): Observable<any> {
    return this.http
      .post(`${environment.API_ENDPOINT}/user-storage/user-preferences`, { preferences: profileDetails })
      .pipe(
        tap(() => {
          this.profileDetailsCache.subject$.next(profileDetails);
        })
      );
  }

  public updatePassword(oldPassword: string, password: string): Observable<any> {
    return this.http.post(`${environment.API_ENDPOINT}/admin/change-password`, { oldPassword, password });
  }

  public getUserDetailsByUserId(userId: string): Observable<IProfileDetails> {
    return this.http.get<IProfileDetails>(`${environment.API_ENDPOINT}/user-storage/user-preferences/${userId}`);
  }

  public updateProfileCompletePercent(profileDetails: IProfileDetails) {
    let filledFields = 0;
    if (profileDetails.personalInformation.birthday) {
      filledFields++;
    }
    if (profileDetails.personalInformation.displayName) {
      filledFields++;
    }
    if (profileDetails.personalInformation.email) {
      filledFields++;
    }
    if (profileDetails.personalInformation.firstName) {
      filledFields++;
    }
    if (profileDetails.personalInformation.lastName) {
      filledFields++;
    }
    if (profileDetails.personalInformation.sex) {
      filledFields++;
    }

    this.profileCompletePercent = Math.round((filledFields / 6) * 100);

    this.profileComplete.next({ profileCompletePercent: this.profileCompletePercent });
  }

  public getProfileCompleteData() {
    return this.profileComplete.asObservable();
  }

  public updateProfileOptInRevenue(isClicked: boolean) {
    this.isProfileOptInRevenue.next(isClicked);
  }

  public getProfileOptInRevenue() {
    return this.isProfileOptInRevenue.asObservable();
  }

  private imageToString(image: Blob): Observable<string> {
    const reader = new FileReader();

    const imageAsString = new Observable<string>((observer: Subscriber<string>) => {
      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.readAsDataURL(image);
    });

    return imageAsString;
  }
}
