import { secureObjectCopy } from './secure-object-copy.function';

function isObject(item: Record<string, any>) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(targetOriginal: Record<string, any>, ...sourcesOriginal: Record<string, any>[]) {
  const target: Record<string, any> = secureObjectCopy(targetOriginal);
  const sources: Record<string, any>[] = secureObjectCopy(sourcesOriginal);

  if (!sources.length) {
    return target;
  }

  const source: Record<string, any> = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key] as Record<string, any>)) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }

        mergeDeep(target[key] as Record<string, any>, source[key] as Record<string, any>);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
