import { Injectable } from '@angular/core';
import { ApiPublisherService } from './api-publisher.service';
import { IPublisherMetadata } from './core/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiImgCurationService {
  private publishersImagesFallback: Record<string, string> = {
    'Reno Gazette-Journal': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/reno-gazette-journal.png',
    'Las Vegas Review-Journal': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/reviewjournal.jpg',
    'Billings Gazette': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/billingsgazette.jpg',
    'Courier Post': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/courier-post.jpg',
    'Concord Monitor': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/concord-monitor.jpg',
    'The Eagle Times': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/the-eagle-times.webp',
    'New Hampshire Union Leader': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/unionleader.jpg',
    'WFLA NBC Local Tampa Bay': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/wflalogo.png',
    'North Jersey': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/north-jersey.jpg',
    'Springfield News-Leader': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/springfield-news-leader.jpg',
    'Albuquerque News': 'https://neutralitypublisherlogo.s3.us-west-2.amazonaws.com/albuquerque-news.png'
  };

  constructor(private publisherMetadataService: ApiPublisherService) {}

  // Account for common and known img parsing generation errors
  public curateImg(img: string): string {
    if (img?.startsWith('ttps://neutr')) {
      return 'h' + img;
    }

    if (img?.includes('{delim}')) {
      return img.split('{delim}')[0];
    }

    return img;
  }

  public getDefaultImageForPublisherName(publisherName: string): string {
    const publisher: IPublisherMetadata = this.publisherMetadataService.getPublisherFromName(publisherName);
    let imageResult: string = this.publishersImagesFallback[publisher?.name] ?? publisher?.publisher_logo;

    if (!imageResult) {
      return '';
    }

    imageResult = typeof imageResult === 'string' ? imageResult : imageResult[0];
    // Replace old url with new one

    return imageResult
      .replace('neutralitypublogo', 'neutralitypublisherlogo')
      .replace(/"/g, '')
      .replace('.crdownload', '')
      .replace('Breitbart.png', 'https://neutralitypublogo.s3.amazonaws.com/Breitbart.png');
  }
}
