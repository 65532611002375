import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { ITrendingEvent } from '../../interfaces';
import * as trendingEventAction from './trending-event.action';

export const trendingEventFeatureKey = 'trendingEvent';

export interface INeuTrendingEvent {
  isTrendingEventStart: boolean;
  eventStartTime: Date;
  trendingEventData: ITrendingEvent;
}

export const initialTrendingEventState: INeuTrendingEvent = {
  isTrendingEventStart: false,
  eventStartTime: undefined,
  trendingEventData: {
    timePeriod: '',
    hoverOver: '',
    expandPanel: undefined,
    applySearch: undefined,
    duration: '00:00:00'
  }
};

export const trendingEventReducer: ActionReducer<INeuTrendingEvent, Action> = createReducer(
  initialTrendingEventState,
  on(trendingEventAction.trendingEventStartState, (state, { isTrendingEventStart }) => ({
    ...state,
    isTrendingEventStart,
    eventStartTime: isTrendingEventStart ? new Date() : state.eventStartTime
  })),
  on(trendingEventAction.setTrendingTimePeriod, (state, { timePeriod }) => ({
    ...state,
    trendingEventData: {
      ...state.trendingEventData,
      timePeriod
    }
  })),
  on(trendingEventAction.setTrendingHoverOver, (state, { hoverOver }) => ({
    ...state,
    trendingEventData: {
      ...state.trendingEventData,
      hoverOver
    }
  })),
  on(trendingEventAction.setTrendingExpandPanelState, (state, { expandPanel }) => ({
    ...state,
    trendingEventData: {
      ...state.trendingEventData,
      expandPanel
    }
  })),
  on(trendingEventAction.setTrendingApplySearchState, (state, { applySearch }) => ({
    ...state,
    trendingEventData: {
      ...state.trendingEventData,
      applySearch
    }
  })),
  on(trendingEventAction.resetTrendingEvent, () => initialTrendingEventState)
);
