/**
 * This function will take a number of milliseconds,
 * and format it like: hh:mm:ss
 */
export const durationInMsToPrettyFormat = (durationInMs: number): string => {
  const numberOfSeconds = durationInMs / 1000;

  let hours: number | string = Math.floor(numberOfSeconds / 3600);
  let minutes: number | string = Math.floor((numberOfSeconds - hours * 3600) / 60);
  let seconds: number | string = numberOfSeconds - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (seconds < 10) {
    seconds = '0' + Math.floor(seconds);
  }

  return hours + ':' + minutes + ':' + seconds;
};
