import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { ISavedCustomSources } from 'src/app/news-filters/filter/tree-filter-publishers/core/interfaces';
import { IQuickAccessSavedOrder } from 'src/app/ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { IResearchCollection } from 'src/app/research-collections/core/interfaces';
import { IAppState } from '../../core/store-app/reducers';
import { ISavedTopic } from '../../ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces/saved-topic.interface';
import { IPage } from '../core/interfaces';
import { NewsDataService } from '../services';
import * as NewsActionTypes from './news.actions';
import {
  selectQuickAccessTopics,
  selectSavedCustomSourecs,
  selectSavedTopics,
  selectResearchCollections
} from './news.selectors';

@Injectable()
export class NewsEffects {
  /**
   * Whenever a new saved topic is added, updated also update the DB.
   */

  public saveTopicAndQuickAccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NewsActionTypes.SaveTopicAndQuickAccess,
          NewsActionTypes.AddSavedTopic,
          NewsActionTypes.UpdateSavedTopics,
          NewsActionTypes.UpdateQuickAccessTopicsState,
          NewsActionTypes.SetQuickAccessTopicsState,
          NewsActionTypes.DeleteSavedTopic
        ),
        this.saveTopicsPipe()
      ),
    { dispatch: false }
  );

  /**
   * Whenever a new custom source is added, also update the DB.
   */

  public saveCustomSource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActionTypes.AddCustomSource),
        switchMap(() => this.store.pipe(select(selectSavedCustomSourecs)).pipe(take(1))),
        map((savedCustomSources: ISavedCustomSources[]) => {
          this.newsDataService.saveCustomSources(savedCustomSources);
        })
      ),
    { dispatch: false }
  );

  /**
   * Whenever a new saved collection is added, also update the DB.
   */

  public saveCollection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActionTypes.AddResearchCollection),
        switchMap(() => this.store.pipe(select(selectResearchCollections)).pipe(take(1))),
        map((savedCollections: IResearchCollection[]) => {
          this.newsDataService.saveCollections(savedCollections);
        })
      ),
    { dispatch: false }
  );

  /**
   * Whenever a custom source is deleted, also update the DB.
   */

  public deleteCustomSource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActionTypes.DeleteCustomSource),
        switchMap(() => this.store.pipe(select(selectSavedCustomSourecs)).pipe(take(1))),
        map((savedCustomSources: ISavedCustomSources[]) => {
          this.newsDataService.saveCustomSources(savedCustomSources);
        })
      ),
    { dispatch: false }
  );

  /**
   * Go to the first page whenever we execute a new search.
   * Current Page Action activates a getNews() search on news-data.service.ts
   */

  public executeSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActionTypes.ExecuteNewSearch),
      map(() => {
        const page: IPage = {
          pageIndex: 0,
          pageSize: 10
        };

        return NewsActionTypes.SetCurrentPage({ currentPage: page });
      })
    )
  );

  constructor(private actions$: Actions, private store: Store<IAppState>, private newsDataService: NewsDataService) {}

  private saveTopicsPipe<T>(): OperatorFunction<T, Observable<void>> {
    function _saveTopicsPipe(source: Observable<T>): Observable<void> {
      return source.pipe(
        switchMap(() => {
          return combineLatest({
            savedTopic: this.store.pipe(select(selectSavedTopics)),
            quickAccessTopics: this.store.pipe(select(selectQuickAccessTopics))
          }).pipe(debounceTime(1000), take(1));
        }),
        map(({ savedTopic, quickAccessTopics }: { savedTopic: ISavedTopic[]; quickAccessTopics: string[] }) => {
          const quickAccessState: IQuickAccessSavedOrder = {
            isQuickAccessSavedOrder: true,
            quickAccessSavedState: quickAccessTopics
          };

          this.newsDataService.saveTopics([(<unknown>quickAccessState) as ISavedTopic, ...savedTopic]);
        })
      );
    }

    return _saveTopicsPipe.bind(this);
  }
}
