import { createReducer, on } from '@ngrx/store';
import * as NewsUiTypes from './news-ui.actions';

export const newsUiFeatureKey = 'newsUiState';

export interface INewsUiState {
  isAnalyticsRolloutVisible: boolean;
}

export const newsUiInitialState: INewsUiState = {
  isAnalyticsRolloutVisible: false
};

export const newsUiReducer = createReducer(
  newsUiInitialState,
  on(NewsUiTypes.setIsAnalyticsRolloutVisible, (state: INewsUiState, action) => {
    return {
      ...state,
      isAnalyticsRolloutVisible: action.isVisible === undefined ? !state.isAnalyticsRolloutVisible : action.isVisible
    };
  })
);
