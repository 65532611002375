export function createUUID(idLength = 36) {
  // http://www.ietf.org/rfc/rfc4122.txt
  const generatedSymbols: string[] = [];
  const hexDigits = '0123456789abcdef';

  for (let keyLengthIndex = 0; keyLengthIndex < idLength; keyLengthIndex++) {
    generatedSymbols[keyLengthIndex] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }

  generatedSymbols[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  // eslint-disable-next-line no-bitwise
  generatedSymbols[19] = hexDigits.substr((generatedSymbols[19] && 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  generatedSymbols[8] = generatedSymbols[13] = generatedSymbols[18] = generatedSymbols[23] = '-';

  const uuid = generatedSymbols.join('');

  return uuid;
}
