import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/core/store-app/reducers';
import { NeuEventCategory, NeuEventType } from 'src/app/core/track-events/enum';
import { INewsItem } from 'src/app/news/core/interfaces';
import { EmptyCapturedData } from '../../models/captured-data-types';
import { ArticleCapturedData } from '../../models/captured-data-types/general';
import { OpenmindLinkCapturedData } from '../../models/captured-data-types/openmind';
import { NeutralityEvent } from '../../models/neutrality-event.model';
import { EventDataService } from '../neu-event-data.service';

@Injectable({
  providedIn: 'root'
})
export class NeuOpenmindEventsService {
  constructor(private store: Store<IAppState>, private eventDataService: EventDataService) {}

  public catchOpenmindFilterToggle(filterName: string, newStatus: boolean) {
    // Generate query by filter
    const generatedCapturedData: EmptyCapturedData = {};
    if (filterName.indexOf('/') !== -1) {
      // Is a date filter
      filterName = 'date(' + filterName + ')';
    }
    const generatedEventType = this.stringToCamelCase(filterName) + (newStatus ? 'On' : 'Off');

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: generatedEventType,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent);
  }

  public catchSaveStoryToggle(newsItem: INewsItem, newStatus: boolean) {
    const generatedCapturedData: ArticleCapturedData = {
      articleID: newsItem.id,
      articleUUID: newsItem.id,
      articleTitle: newsItem.title,
      articleAuthor: newsItem.author,
      publisher: newsItem.publisher,
      publishedDate: newsItem.date
    };
    const generatedEventType = 'saveStoryToggle' + (newStatus ? 'On' : 'Off');

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: generatedEventType,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent).subscribe();
  }

  public catchPinStoryToggle(newsItem: INewsItem, newStatus: boolean) {
    const generatedCapturedData: ArticleCapturedData = {
      articleID: newsItem.id,
      articleUUID: newsItem.id,
      articleTitle: newsItem.title,
      articleAuthor: newsItem.author,
      publisher: newsItem.publisher,
      publishedDate: newsItem.date
    };
    const generatedEventType = 'pinStoryToggle' + (newStatus ? 'On' : 'Off');

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: generatedEventType,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent).subscribe();
  }

  public catchReturnedAmountChange(newAmountToReturn: string) {
    const generatedCapturedData: EmptyCapturedData = {};
    const generatedEventType = 'articleReturnAmount' + newAmountToReturn;

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: generatedEventType,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent);
  }

  public catchPublisherLinkClicked(newsItem: INewsItem) {
    const generatedCapturedData: OpenmindLinkCapturedData = {
      articleID: newsItem.id,
      articleUUID: newsItem.id,
      articleTitle: newsItem.title,
      articleAuthor: newsItem.author,
      publisher: newsItem.publisher,
      publishedDate: newsItem.date
    };

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: NeuEventType.ArticleOut,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent).subscribe();
  }

  // Converts a tring into camelCase format
  private stringToCamelCase(toConvert: string) {
    return toConvert
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }
}
