import { AsyncSubject, BehaviorSubject, defer, Observable, skipWhile, switchMap, take } from 'rxjs';
import { LRUCache } from './lru-cache.model';

/*
 * this class is used for cache a observable/network-request that is executed/subscribed from different places
 * of the app almost at the same time, we make a single network call and cache the result,
 * also we return an observable that emit only when the network call finish, the cached result
 * is returned to the successive subscriptions
 */

export class NetworkCachedSubject<T> {
  private cache$: BehaviorSubject<T>;
  private isMakingRequest$: BehaviorSubject<boolean>;
  private isDataUpdated = false;

  constructor(initialValue: T) {
    this.cache$ = new BehaviorSubject<T>(initialValue);
    this.isMakingRequest$ = new BehaviorSubject<boolean>(false);
  }

  public setIsUpdated(value: boolean) {
    this.isDataUpdated = value;
  }

  public get observable$(): Observable<T> {
    return this.cache$.asObservable();
  }

  public get subject$(): BehaviorSubject<T> {
    return this.cache$;
  }

  public next(networkCall: Observable<T>, forceRequest: boolean = false): Observable<T> {
    if (this.isDataUpdated && !forceRequest) {
      /*
       * since cache$ is a BehaviorSubject they alway emit, so we delay the emit
       * until the network request completes and we put in this subject the updated values
       */

      return this.isMakingRequest$
        .pipe(
          skipWhile((isMakingRequest: boolean) => isMakingRequest),
          take(1)
        )
        .pipe(switchMap(() => this.cache$));
    }

    this.isDataUpdated = true;
    this.isMakingRequest$.next(true);

    const subject = new AsyncSubject<void>();

    return defer(() => {
      // make network call only when we have a subscription
      networkCall.pipe(take(1)).subscribe({
        next: (value: T) => {
          this.cache$.next(value);
          this.isMakingRequest$.next(false);
          subject.next();
          subject.complete();
        },
        error: (error: Error) => {
          this.cache$.error(error);
          this.isMakingRequest$.next(false);
          subject.next();
          subject.complete();
        }
      });

      return subject.pipe(switchMap(() => this.cache$));
    });
  }
}

export class NetworkCachedSubjectWithEviction<T> {
  private evictionTimeInMilliseconds: number | null;
  private cacheTimestamp: number | null;
  private cache: NetworkCachedSubject<T>;

  constructor(evictionTimeInMilliseconds: number | null = null) {
    this.cache = new NetworkCachedSubject(null);
    this.evictionTimeInMilliseconds = evictionTimeInMilliseconds;
    this.cacheTimestamp = null;
  }

  public next(observableData: Observable<T>): Observable<T> {
    if (!this.cacheTimestamp) {
      this.cacheTimestamp = Date.now();
    }

    if (Date.now() - this.cacheTimestamp > this.evictionTimeInMilliseconds) {
      this.cache.setIsUpdated(false);
      this.cacheTimestamp = Date.now();
    }

    return this.cache.next(observableData);
  }
}

/*
 * this class is used for cache a observable/network-request that is called with different params
 * we associate each observable to a provided key and the cached result
 * is returned to the successive subscriptions, also we use NetworkCachedSubject to prevent make
 * several call when is subscriber from different places at same time
 */

export class NetworkCachedObservable<T> {
  private evictionTimeInMilliseconds: number | null = null;
  private cache: Map<string, NetworkCachedSubject<T>>;
  private cacheTimestamp: Map<string, number>;

  constructor(evictionTimeInMilliseconds: number | null = null) {
    this.cache = new Map<string, NetworkCachedSubject<T>>();
    this.cacheTimestamp = new Map<string, number>();
    this.evictionTimeInMilliseconds = evictionTimeInMilliseconds;
  }

  public next(key: string, observableData: Observable<T>): Observable<T> {
    const isEvicted: boolean =
      this.evictionTimeInMilliseconds && this.cacheTimestamp.has(key)
        ? Date.now() - this.cacheTimestamp.get(key) > this.evictionTimeInMilliseconds
        : false;

    if (this.cache.has(key) && !isEvicted) {
      return this.cache.get(key).next(observableData);
    }

    const wrappedObservableData = new NetworkCachedSubject<T>(null);
    this.cacheTimestamp.set(key, Date.now());
    this.cache.set(key, wrappedObservableData);

    return wrappedObservableData.next(observableData);
  }
}

export class NetworkLRUCachedObservable<T> {
  public cache: LRUCache<NetworkCachedSubject<T>>;

  constructor(limit = 20, evictionTimeInMilliseconds: number | null = null) {
    this.cache = new LRUCache<NetworkCachedSubject<T>>(limit, evictionTimeInMilliseconds);
  }

  public next(key: string, observableData: Observable<T>): Observable<T> {
    const data: NetworkCachedSubject<T> = this.cache.read(key);

    if (data) {
      return data.next(observableData);
    }

    const wrappedObservableData = new NetworkCachedSubject<T>(null);
    this.cache.write(key, wrappedObservableData);

    return wrappedObservableData.next(observableData);
  }
}
