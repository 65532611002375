export enum ChipColorType {
  ReadingList = '#FF4FFE',
  Publishers = '#2EC700',
  Author = '#29E1FF',
  Topic = '#000265',
  Category = '#656565',
  Date = '#0089eb',
  Search = '#7fc4fd',
  TrendingTopicForNews = '#0a8e02',
  Keywords = '#EF9E0F',
  Relevancy = '#7F819F',
  Sentiment = '#1c7800'
}
