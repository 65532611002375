/* eslint-disable @typescript-eslint/no-unused-expressions */
/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { ApiPlaceHolderValues } from '../core/enums';
import { FilterType } from '../core/filters/core/enums';
import { IFilterLabel } from '../core/filters/core/interfaces';
import { SentimentRelevancyService } from '../core/services/sentiment-relevancy.service';
import { stringSimilarity } from '../core/utils';
import { titleCase } from '../core/utils/title-case.function';
import { INewsItem, INewsItemApi } from '../news/core/interfaces';
import { ArticleSentimentType } from '../news/enums';
import { IOpenmindItem } from '../openmind/core/interfaces';
import { ApiImgCurationService } from './api-img-curation.service';
import { IPublisherMetadata } from './core/api-interfaces';
import { ApiPublisherService } from './api-publisher.service';

@Injectable({
  providedIn: 'root'
})
export class ApiUtilitiesService {
  // These lists used to decode Publisher and Category values
  // Data delivered in path0S and articleprint values in INewsItem
  private categoryList: IFilterLabel[] = [
    {
      name: 'Unknown',
      value: 0,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Arts, Culture and Entertainment',
      value: 1,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Crime, Law and Justice',
      value: 2,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Disaster and Accident',
      value: 3,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Economy, Business and Finance',
      value: 4,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Education',
      value: 5,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Environmental Issue',
      value: 6,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Health',
      value: 7,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Human Interest',
      value: 8,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Labour',
      value: 9,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Lifestyle and Leisure',
      value: 10,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Politics',
      value: 11,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Religion and Belief',
      value: 12,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Science and Technology',
      value: 13,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Social Issue',
      value: 14,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Sport',
      value: 15,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Conflict, War And Peace',
      value: 16,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    },
    {
      name: 'Weather',
      value: 17,
      isActive: true,
      icon: null,
      type: FilterType.Category,
      path: null,
      logo: null
    }
  ];

  constructor(
    private sentimentRelevancyService: SentimentRelevancyService,
    private apiImgCurationService: ApiImgCurationService,
    private publisherMetadataService: ApiPublisherService
  ) {}

  // This routine will interpret trailing characters in "articleprint" field
  // as an ascii string with category. Eventually, this value will come from neural network.
  // This value was calculated at article aggregation time.
  public binToString(binarray): string {
    const binarray2 = binarray.match(/.{1,8}/g);
    let result = '';
    if (binarray2) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < binarray2.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        result += String.fromCharCode(parseInt(binarray2[i], 2));
      }
    }

    return result;
  }

  public addToCategoryLookupArray(category: IFilterLabel): void {
    if (this.categoryList.indexOf(category) === -1) {
      this.categoryList.push(category);
    }
  }

  /**
   *
   * @param apiNewsItem: The API Article that we will transform to a frontend model
   * @param userId: The current User ID
   * @param relevancyBaseArticle: For openmind, the Article which score will be taken as base to calculate
   *                              other scores (relevancy)
   */
  public mapNewsFromApi(
    apiNewsItem: INewsItemApi | Partial<INewsItemApi>,
    userId: string, // This is only used to check if saved story
    relevancyBaseArticle?: INewsItemApi
  ): INewsItem {
    const id: string = apiNewsItem.article_id_s ?? apiNewsItem.id;

    let author: string;
    apiNewsItem.neutrality_article_author && apiNewsItem.neutrality_article_author.length > 0
      ? (author = apiNewsItem.neutrality_article_author)
      : (author = null);

    const date: string = apiNewsItem.neutrality_article_normpubDate
      ? apiNewsItem.neutrality_article_normpubDate.toString()
      : '2000-01-01T00:00:00Z';

    let description: string;
    apiNewsItem.neutrality_article_description && apiNewsItem.neutrality_article_description.length > 0
      ? (description = apiNewsItem.neutrality_article_description)
      : (description = 'No description provided, please visit the link for more information');

    const publisherLogo: string = this.apiImgCurationService.getDefaultImageForPublisherName(
      apiNewsItem.publisher_name
    );

    let image: string | string[] = apiNewsItem.neutrality_article_thumbnail_url;

    if (image && typeof image !== 'string' && image.map !== undefined) {
      // image is an array
      image = image[0];
    }

    // If image is empty, then use default logo from Publisher metadata
    if (!image || image.length <= 5) {
      image = publisherLogo;
    }

    let imageAlt: string;
    apiNewsItem.neutrality_article_thumbnail_url && apiNewsItem.neutrality_article_thumbnail_url.length > 0
      ? (imageAlt = apiNewsItem.neutrality_article_thumbnail_url[0])
      : (imageAlt = '');

    let link: string;
    apiNewsItem.neutrality_article_url && apiNewsItem.neutrality_article_url.length > 0
      ? (link = apiNewsItem.neutrality_article_url)
      : (link = apiNewsItem.id);

    const elementPublisher: IPublisherMetadata = this.publisherMetadataService.getPublisherFromName(
      apiNewsItem.publisher_name
    );
    const publisher: string = elementPublisher?.name || apiNewsItem?.publisher_name || ApiPlaceHolderValues.Unknown;
    const path = apiNewsItem.path;

    let title: string;
    apiNewsItem.neutrality_article_title && apiNewsItem.neutrality_article_title.length > 0
      ? (title = apiNewsItem.neutrality_article_title)
      : (title = '');

    const sentiment: ArticleSentimentType = this.sentimentRelevancyService.getSentimentLabel(
      apiNewsItem.sentiment
    );

    // Openmind and Search Terms
    let terms: string[];

    if (apiNewsItem.neutrality_terms && apiNewsItem.neutrality_terms.length > 0) {
      terms = apiNewsItem.neutrality_terms;
    } else if (apiNewsItem.terms_ss && apiNewsItem.terms_ss.length > 0) {
      terms = apiNewsItem.terms_ss;
    } else {
      terms = [];
    }

    const isDuplicate = false;
    const isReadingList: boolean = this.checkIfReadingList(apiNewsItem as INewsItemApi, userId);

    let categories: string[] = [];

    if (
      apiNewsItem.signal_data &&
      apiNewsItem.signal_data.docs[0] &&
      apiNewsItem.signal_data.docs[0].category_list?.length
    ) {
      categories = apiNewsItem.signal_data.docs[0].category_list.map((category) => titleCase(category));
    } else if (apiNewsItem.category_list?.length) {
      categories = apiNewsItem.category_list?.map((category) => titleCase(category));
    }

    if (categories.length > 1) {
      const seenCategories = new Set<string>();

      categories = categories.filter((category: string) => {
        if (seenCategories.has(category)) {
          return false;
        }

        seenCategories.add(category);

        return true;
      });
    }

    const relevancy: number = apiNewsItem.score;

    let relevancyPercent: number;
    relevancyBaseArticle ? (relevancyPercent = (relevancy / relevancyBaseArticle.score) * 100) : (relevancyPercent = 0);

    return <INewsItem | IOpenmindItem>{
      id,
      author,
      categories,
      date,
      description,
      image,
      imageAlt,
      isDuplicate,
      isReadingListSaved: isReadingList,
      link,
      relevancy,
      relevancyPercent,
      publisher,
      sentiment,
      terms,
      title,
      path,
      publisherLogo,
      hasPaywall: elementPublisher?.paywall || false,
      paywallMessage: elementPublisher?.paywall_text || '',
      raw: apiNewsItem
    };
  }

  public publisherNameToPath(publisherName: string): string | string[] {
    const associatedPublisher = this.publisherMetadataService.getPublisherFromName(publisherName);

    return associatedPublisher ? associatedPublisher.path : 'Unknown';
  }

  /**
   * This will take a topic filter and match it to a filter in the category list
   * @return number from 1 to 17 that represents the category filter code
   */
  public topicFilterToArticlePrintByName(categoryName: string): number {
    let bestCategoryMatch: { indexValue: number; score: number } = null;

    for (const categoryFilter of this.categoryList) {
      if (!bestCategoryMatch) {
        bestCategoryMatch = {
          indexValue: categoryFilter.value,
          score: stringSimilarity(categoryName, categoryFilter.name)
        };
      } else {
        const currentScore = stringSimilarity(categoryName, categoryFilter.name);

        if (currentScore > bestCategoryMatch.score) {
          bestCategoryMatch = {
            indexValue: categoryFilter.value,
            score: stringSimilarity(categoryName, categoryFilter.name)
          };
        }
      }
    }

    return bestCategoryMatch.indexValue;
  }

  public checkIfReadingList(newsItem: INewsItemApi, userId: string): boolean {
    if (newsItem.reading_list_users) {
      if (newsItem.reading_list_users && newsItem.reading_list_users.length) {
        let isReadingList = false;
        for (const tag of newsItem.reading_list_users) {
          if (tag === userId) {
            isReadingList = true;

            return isReadingList;
          }
        }

        return isReadingList;
      }

      return false;
    }

    if (newsItem.signal_data && newsItem.signal_data.docs.length) {
      if (newsItem.signal_data.docs[0].reading_list_users) {
        if (newsItem.signal_data.docs[0].reading_list_users && newsItem.signal_data.docs[0].reading_list_users.length) {
          let isReadingList = false;
          for (const tag of newsItem.signal_data.docs[0].reading_list_users) {
            if (tag === userId) {
              isReadingList = true;

              return isReadingList;
            }
          }

          return isReadingList;
        }

        return false;
      }
    }

    return false;
  }
}
