import { Injectable } from '@angular/core';
import { NeutralityEvent } from '../../models/neutrality-event.model';
import { NeuEventCategory, NeuEventType } from 'src/app/core/track-events/enum';
import { EventDataService } from '../neu-event-data.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/core/store-app/reducers';
import { NotificationClosedCapturedData, NotificationRefreshedCapturedData } from '../../models/captured-data-types';
import { NotificationRefreshMode } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class NeuNotificationEventsService {
  constructor(private store: Store<IAppState>, private eventDataService: EventDataService) {}

  public catchNotificationClosed(notificationID: string) {
    const generatedCapturedData: NotificationClosedCapturedData = {
      notificationID
    };

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: NeuEventType.NotificationClosed,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent);
  }

  public catchNotificationsRefresh(notificationRefreshMode: NotificationRefreshMode) {
    const generatedCapturedData: NotificationRefreshedCapturedData = {
      mode: notificationRefreshMode
    };

    const generatedEvent: NeutralityEvent = new NeutralityEvent({
      eventCategory: NeuEventCategory.Analytics,
      eventType: NeuEventType.NotificationClosed,
      capturedData: generatedCapturedData,
      shouldBeSaved: true
    });

    return this.eventDataService.saveEvent(generatedEvent);
  }
}
