import { createReducer, on } from '@ngrx/store';
import { mergeDeep } from 'src/app/core/utils/merge-deep.function';
import { IDatasetTuneupData } from '../core/api-interfaces';
import { IApiSentimentRelevancyBucket } from '../core/interfaces';
import * as ApiNewsTypes from './api-news.actions';

export const apiNewsFeatureKey = 'apiNewsState';

export interface IApiNewsState {
  currentDataSetTuneupData: IDatasetTuneupData;
  sentimentTuneupData: IApiSentimentRelevancyBucket[];
  relevancyTuneupData: IApiSentimentRelevancyBucket[];
  // Loaders
  isLoadingTuneupData: boolean;
  isLoadingTuneupSources: boolean;
}

const apiNewsInitialState: IApiNewsState = {
  currentDataSetTuneupData: null,
  sentimentTuneupData: [],
  relevancyTuneupData: [],
  isLoadingTuneupData: true,
  isLoadingTuneupSources: true
};

export const apiNewsReducer = createReducer(
  apiNewsInitialState,
  on(ApiNewsTypes.setCurrentDatasetTuneupData, (state: IApiNewsState, action) => {
    return { ...state, currentDataSetTuneupData: action.tuneupData };
  }),
  on(ApiNewsTypes.setSentimentTuneupData, (state: IApiNewsState, action) => {
    return { ...state, sentimentTuneupData: action.sentimentTuneup };
  }),
  on(ApiNewsTypes.setRelevancyTuneupData, (state: IApiNewsState, action) => {
    return { ...state, relevancyTuneupData: action.relevancyTuneup };
  }),
  on(ApiNewsTypes.updateCurrentDatasetTuneupData, (state: IApiNewsState, action) => {
    return {
      ...state,
      currentDataSetTuneupData: mergeDeep(
        state.currentDataSetTuneupData || ({} as IDatasetTuneupData),
        action.tuneupData
      ) as IDatasetTuneupData
    };
  }),
  // Loaders
  on(ApiNewsTypes.setIsLoadingTuneupData, (state: IApiNewsState, action) => {
    return { ...state, isLoadingTuneupData: action.isLoading };
  }),
  on(ApiNewsTypes.setIsLoadingTuneupSources, (state: IApiNewsState, action) => {
    return { ...state, isLoadingTuneupSources: action.isLoading };
  })
);
