/* istanbul ignore file */

// Clean the query, remove extra spaces...
export const cleanAndAdjustQuery = (inputQuery: string) => {
  let modifiedQuery: string = inputQuery.trim();

  const regexToMatchStringsAndDigits = /(["'])(?:(?=(\\?))\2.)*?\1~\d+/g;

  modifiedQuery = inputQuery.replace(regexToMatchStringsAndDigits, (fullMatch) => {
    return '(' + fullMatch + ')';
  });

  modifiedQuery = modifiedQuery.replace(/,/g, ' AND ');
  // Clean extra spaces, for beauty purposes, because the query will work anyway
  modifiedQuery = modifiedQuery.replace(/ {2}AND {2}/g, ' AND ');
  modifiedQuery = modifiedQuery.replace(/ AND {2}/g, ' AND ');
  modifiedQuery = modifiedQuery.replace(/ {2}AND /g, ' AND ');

  return modifiedQuery;
};

export const solrDefaultDateFormatting = 'YYYY-MM-DDTHH:mm:ss[Z]';
