/**
 * This function will take system date time as the input value (example:'Wed Jan 25 2023 18:34:08 GMT+0530)
 * and return a string date format as the output value (example : '2023-01-25 18:34:08')
 */
export const formatDateTimeToString = (date: Date): string => {
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
  );
};

const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0');
};
