export enum CollectionItemType {
  ParentNode = 'Collection',
  ChildNode = 'Folder',
  SavedTopic = 'Saved Topic',
  Article = 'Article',
  Link = 'Link'
}

export enum CollectionItemState {
  Edit = 'edit',
  Add = 'add'
}

export enum CollectionDropPosition {
  Above = 'above',
  Below = 'below',
  Center = 'center'
}

export enum BriefcaseItem {
  Collection = 'collection',
  ReadingList = 'readingList'
}

export enum EditItemType {
  Title = 'title',
  Description = 'description',
  URL = 'url'
}

