const stopWords = new Set(['and', 'or', 'the', 'in', 'on', 'at']);

export const titleCase = (title: string, shouldSkipStopWords = true) => {
  const splitTitle = title.toLowerCase().split(' ');

  for (let i = 0; i < splitTitle.length; i++) {
    if (shouldSkipStopWords && stopWords.has(splitTitle[i].toLowerCase()) && i !== 0) {
      continue;
    }

    splitTitle[i] = splitTitle[i].charAt(0).toUpperCase() + splitTitle[i].substring(1);
  }

  const newTitle: string = splitTitle.join(' ');

  return newTitle;
};
