import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INewsState, newsStateFeatureKey } from './news-state.reducer';

export const selectNewsState = createFeatureSelector<INewsState>(newsStateFeatureKey);

export const selectAllFiltersForQuery = createSelector(selectNewsState, (newsState) => {
  return [...newsState.arrayFiltersForQuery, ...newsState.toggleFiltersForQuery];
});

export const selectCurrentPage = createSelector(selectNewsState, (newsState) => newsState.currentPage);
export const selectCurrentSortOrder = createSelector(selectNewsState, (newsState) => newsState.currentSortOrder);
export const selectCurrentSortDirection = createSelector(
  selectNewsState,
  (newsState) => newsState.currentSortDirection
);
export const selectfilterChips = createSelector(selectNewsState, (newsState) => newsState.filterChips);
export const selectCurrentSearchQuery = createSelector(selectNewsState, (newsState) => newsState.currentSearchQuery);

export const selectSavedTopics = createSelector(selectNewsState, (newsState) => newsState.savedTopics);
export const selectQuickAccessTopics = createSelector(selectNewsState, (newsState) => newsState.quickAccessSavedTopics);

export const selectSavedCustomSourecs = createSelector(selectNewsState, (newsState) => newsState.savedCustomSources);
export const selectResearchCollections = createSelector(selectNewsState, (newsState) => newsState.researchCollections);
// Populate filters
export const selectAllFilters = createSelector(selectNewsState, (newsState) => newsState.filters);
export const selectActiveDateFilter = createSelector(selectNewsState, (newsState) => newsState.isActiveDefaultFilter);

export const selectDefaultFilter = createSelector(selectNewsState, (newsState) =>
  newsState.savedTopics.filter((item) => {
    return item.isDefault && item.isDefault;
  })
);

export const selectActiveFilters = createSelector(selectNewsState, (newsState) =>
  newsState.filters.filter((item) => {
    return item.isActive;
  })
);

export const selectMenuChartView = createSelector(selectNewsState, (newsState) => newsState.currentMenuChartView);
export const selectMenuLayoutOpen = createSelector(selectNewsState, (newsState) => newsState.currentLayoutMenuView);

// Spinner
export const selectSpinnerIsActive = createSelector(selectNewsState, (newsState) => newsState.currentSpinner);

// News
export const selectCurrentNewsDataset = createSelector(selectNewsState, (newsState) => newsState.currentNewsDataset);

// Filters
export const selectCurrentDateFilter = createSelector(selectNewsState, (newsState) => newsState.dateQueryFilter);
// Filters
export const selectTotalItems = createSelector(selectNewsState, (newsState) => newsState.currentNewsDataset.totalItems);

// Trenidng topics
export const selectshowResultsFromTrendingTopicsEventId = createSelector(
  selectNewsState,
  (newsState) => newsState.showResultsFromTrendingTopicsEvent
);

// Tabs
export const selectIsArticlesTabVisible = createSelector(selectNewsState, (newsState) => newsState.isArticlesTabActive);

export const selectReadingListCount = createSelector(selectNewsState, (newsState) => newsState.readingListCount);

// Saved topic invalidation
export const selectSavedTopicInvalidation = createSelector(
  selectNewsState,
  (newsState) => newsState.invalidatedActiveSavedTopicTimestamp
);

// Selected saved topic
export const selectSelectedSavedTopic = createSelector(selectNewsState, (newsState) => newsState.selectedSavedTopic);
