import { Action } from '@ngrx/store';
import { IUser } from '../interfaces';

export enum AuthActionTypes {
  SetUser = '[Auth] Set the user email and ID',
  LogoutAction = '[Auth] Logout'
}

export class SetUser implements Action {
  public readonly type = AuthActionTypes.SetUser;
  constructor(public payload: { user: IUser }) {}
}

export class LogoutAction implements Action {
  public readonly type = AuthActionTypes.LogoutAction;
}

export type AuthActions = SetUser | LogoutAction;
