import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NetworkErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errorObj: HttpErrorResponse) => {
        // TODO: Send log to DB

        return throwError(() => new HttpErrorResponse(errorObj));
      })
    );
  }
}
