import { createReducer, on } from '@ngrx/store';
import { getDateFilterFromPartial } from 'src/app/core/utils';
import { ITermsDataset } from 'src/app/news/core/interfaces';
import { ITrendingTopicForFilter, ITrendingTopicPublisher } from 'src/app/api/core/api-interfaces';
import { IFilterFacetFromNews, IFilterFacetFromSections } from '../../core/filters/core/interfaces';
import { ISavedTopic } from '../../ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { IDateFilter } from '../core/interfaces';
import {
  CirculationFilterType,
  distributionFiltersType,
  SubfilterType
} from '../filter/tree-filter-publishers/core/enums';
import {
  IPublisherFilter,
  PublisherItemNode,
  PublisherStateNode
} from '../filter/tree-filter-publishers/core/interfaces';
import * as FilterActionTypes from './filters.actions';

export const filtersStateFeatureKey = 'filtersState';

export interface IFiltersState {
  // Publishers
  availablePublishersFilters: PublisherItemNode[];
  availablePublishersLocalStateFilters: PublisherStateNode[];
  selectedPublishersNodes: IPublisherFilter[];
  selectedPublishersLocalStateNodes: IPublisherFilter[];
  publishersToBeDeselectedFromUi: string[];
  publishersToBeSelectedFromUi: string[];
  circulationFilters: CirculationFilterType[];
  typeFilters: distributionFiltersType[];
  subFilterSearchQuery: string;
  // Date
  dateFilter: IDateFilter;
  myFilterActive: ISavedTopic;
  isDateFilterOpened: boolean;
  // Trending topics, Categories
  allTrendingTopicsPublishers: ITrendingTopicPublisher[];
  allCategoriesFilters: string[];
  topicCategoriesFilters: string[];
  trendingTopicsFiltersForNews: ITrendingTopicForFilter[];
  // Facets (articleCount for given publishers, categories etc)
  // ex: Washington Post: 5454 articles
  filtersFacets: IFilterFacetFromNews;
  filtersFacetsForSections: IFilterFacetFromSections;
  termFilters: ITermsDataset[];
  lastTimeCleared: number;
  // Subfilter, query to search trending topics
  trendingTopicSubFilterQuery: string;
}

export const filtersInitialState: IFiltersState = {
  // Publishers
  availablePublishersFilters: [],
  availablePublishersLocalStateFilters: [],
  selectedPublishersNodes: [],
  selectedPublishersLocalStateNodes: [],
  publishersToBeDeselectedFromUi: [],
  publishersToBeSelectedFromUi: [],
  circulationFilters: [],
  typeFilters: [],
  subFilterSearchQuery: '',
  // Date
  dateFilter: null,
  myFilterActive: null,
  isDateFilterOpened: false,
  // Trending topics, Categories
  allTrendingTopicsPublishers: [],
  topicCategoriesFilters: [],
  termFilters: [],
  allCategoriesFilters: [],
  trendingTopicsFiltersForNews: [],
  // Facets
  filtersFacets: null,
  // Facets For Sections
  filtersFacetsForSections: null,
  // Chips
  lastTimeCleared: 0,
  trendingTopicSubFilterQuery: ''
};

export const filtersReducer = createReducer(
  filtersInitialState,
  on(FilterActionTypes.SetAvailablePublishersFilters, (state, action) => {
    return { ...state, availablePublishersFilters: action.availablePublishers };
  }),
  on(FilterActionTypes.SetAvailablePublishersLocalStateFilters, (state, action) => {
    return { ...state, availablePublishersLocalStateFilters: action.availablePublisherLocalStates };
  }),
  on(FilterActionTypes.ToggleFilterSelection, (state, action) => {
    // Adds it
    let selectedPublishersNodes: IPublisherFilter[] = [...state.selectedPublishersNodes];

    if (action.newState) {
      selectedPublishersNodes = [
        ...selectedPublishersNodes,
        {
          parentNode: action.parentNode,
          childNode: action.childNode,
          newState: action.newState
        }
      ];
    } else {
      selectedPublishersNodes = selectedPublishersNodes?.filter((node: IPublisherFilter) => {
        if (
          node.parentNode.itemTitle === action.parentNode.itemTitle &&
          node.childNode.itemTitle === action.childNode.itemTitle
        ) {
          return false;
        }

        return true;
      });
    }

    return { ...state, selectedPublishersNodes };
  }),
  on(FilterActionTypes.ToggleStateFilterSelection, (state, action) => {
    // Adds it
    let selectedPublishersLocalStateNodes: IPublisherFilter[] = [...state.selectedPublishersLocalStateNodes];

    if (action.newState) {
      selectedPublishersLocalStateNodes = [
        ...selectedPublishersLocalStateNodes,
        {
          parentNode: action.parentNode,
          childNode: action.childNode,
          newState: action.newState
        }
      ];
    } else {
      selectedPublishersLocalStateNodes = selectedPublishersLocalStateNodes.filter((node: IPublisherFilter) => {
        if (
          node.parentNode.itemTitle === action.parentNode.itemTitle &&
          node.childNode.itemTitle === action.childNode.itemTitle
        ) {
          return false;
        }

        return true;
      });
    }

    return { ...state, selectedPublishersLocalStateNodes };
  }),
  on(FilterActionTypes.ToggleMultipleFilterSelection, (state, action) => {
    // Adds it
    let selectedPublishersNodes: IPublisherFilter[] = [...state.selectedPublishersNodes];

    for (const filter of action.publisherFilters) {
      if (filter.newState) {
        selectedPublishersNodes = [
          ...selectedPublishersNodes,
          {
            parentNode: filter.parentNode,
            childNode: filter.childNode,
            newState: filter.newState
          }
        ];
      } else {
        selectedPublishersNodes = selectedPublishersNodes?.filter((node: IPublisherFilter) => {
          if (
            node.parentNode.itemTitle === filter.parentNode.itemTitle &&
            node.childNode.itemTitle === filter.childNode.itemTitle
          ) {
            return false;
          }

          return true;
        });
      }
    }

    return { ...state, selectedPublishersNodes };
  }),
  on(FilterActionTypes.ToggleSubfilter, (state, action) => {
    let circulationFilters = state.circulationFilters;
    let typeFilters = state.typeFilters;
    let subFilterSearchQuery = state.subFilterSearchQuery;

    switch (action.filterClass) {
      case SubfilterType.Circulation:
        if (circulationFilters.includes(action.filterName as CirculationFilterType)) {
          circulationFilters = circulationFilters.filter(
            (filter: CirculationFilterType) => filter !== (action.filterName as CirculationFilterType)
          );
        } else {
          circulationFilters = [...circulationFilters, action.filterName as CirculationFilterType];
        }
        break;
      case SubfilterType.Type:
        if (typeFilters.includes(action.filterName as distributionFiltersType)) {
          typeFilters = typeFilters.filter(
            (filter: distributionFiltersType) => filter !== (action.filterName as distributionFiltersType)
          );
        } else {
          typeFilters = [...typeFilters, action.filterName as distributionFiltersType];
        }
        break;
      case SubfilterType.SearchString:
        subFilterSearchQuery = action.newState as string;
        break;
    }

    return { ...state, circulationFilters, typeFilters, subFilterSearchQuery };
  }),
  on(FilterActionTypes.ClearSubfilters, (state) => {
    const circulationFilters = [];
    const typeFilters = [];

    return { ...state, circulationFilters, typeFilters, subFilterSearchQuery: '' };
  }),
  on(FilterActionTypes.ClearPublisherFilters, (state) => {
    return { ...state, selectedPublishersNodes: [] };
  }),
  on(FilterActionTypes.ClearSelectedPublishersLocalNodes, (state) => {
    return { ...state, selectedPublishersLocalStateNodes: [] };
  }),
  /** * Date Filter **/
  on(FilterActionTypes.setDateFilter, (state, action) => {
    const generatedFilter: IDateFilter = getDateFilterFromPartial(action);

    return { ...state, dateFilter: generatedFilter };
  }),
  on(FilterActionTypes.setIsDateFilterOpened, (state, action) => {
    return { ...state, isDateFilterOpened: action.opened };
  }),
  on(FilterActionTypes.toggleIsDateFilterOpened, (state) => {
    return { ...state, isDateFilterOpened: !state.isDateFilterOpened };
  }),
  // My filters
  on(FilterActionTypes.SetMyFilterActive, (state, action) => {
    return { ...state, myFilterActive: action.myActiveFilter };
  }),
  on(FilterActionTypes.AddPublisherToDeselect, (state, action) => {
    const newPublisher: string[] = [...state.publishersToBeDeselectedFromUi];
    newPublisher.push(action.newPublisherToBeUnselected);

    return { ...state, publishersToBeDeselectedFromUi: [...newPublisher] };
  }),
  on(FilterActionTypes.AddMultiplePublisherToDeselect, (state, action) => {
    const newPublisher: string[] = [...state.publishersToBeDeselectedFromUi];

    for (const deselectString of action.newPublisherToBeSelected) {
      newPublisher.push(deselectString);
    }

    return { ...state, publishersToBeDeselectedFromUi: [...newPublisher] };
  }),
  on(FilterActionTypes.DeletePublisherToDeselect, (state, action) => {
    let newPublisher: string[] = [...state.publishersToBeDeselectedFromUi];
    newPublisher = newPublisher.filter((filter) => filter !== action.newPublisherToBeUnselected);

    return { ...state, publishersToBeDeselectedFromUi: [...newPublisher] };
  }),
  on(FilterActionTypes.DeleteMultiplePublisherToDeselect, (state, action) => {
    let newPublisher: string[] = [...state.publishersToBeDeselectedFromUi];

    for (const filter of action.newPublisherToBeUnselected) {
      newPublisher = newPublisher.filter((filterInArray: string) => filterInArray !== filter);
    }

    return { ...state, publishersToBeDeselectedFromUi: [...newPublisher] };
  }),
  on(FilterActionTypes.AddPublisherToSelect, (state, action) => {
    const newPublisher: string[] = [...state.publishersToBeSelectedFromUi];
    newPublisher.push(action.newPublisherToBeSelected);

    return { ...state, publishersToBeSelectedFromUi: [...newPublisher] };
  }),
  on(FilterActionTypes.DeletePublisherToSelect, (state, action) => {
    let newPublisher: string[] = [...state.publishersToBeSelectedFromUi];
    newPublisher = newPublisher.filter((filter: string) => filter !== action.newPublisherToBeSelected);

    return { ...state, publishersToBeSelectedFromUi: [...newPublisher] };
  }),
  // Trending topics
  on(FilterActionTypes.SetAllTrendingTopicsPublishers, (state, action) => {
    return { ...state, allTrendingTopicsPublishers: action.publishers };
  }),
  on(FilterActionTypes.SetTopicCategoriesFilters, (state, action) => {
    return { ...state, topicCategoriesFilters: action.categories };
  }),
  on(FilterActionTypes.SetTermsFilters, (state, action) => {
    return { ...state, termFilters: action.terms };
  }),
  // Facets
  on(FilterActionTypes.SetFiltersFacets, (state, action) => {
    return { ...state, filtersFacets: action.facets };
  }),
  // Facets For Section
  on(FilterActionTypes.SetFiltersFacetsForSections, (state, action) => {
    return { ...state, filtersFacetsForSections: action.facetsForSections };
  }),
  // Chips
  on(FilterActionTypes.ClearAllFiltersButDate, (state, action) => {
    return {
      ...state,
      lastTimeCleared: action.timestamp,
      trendingTopicsFiltersForNews: [],
      categoryHotTopicsFilter: [],
      selectedPublishersNodes: [],
      topicCategoriesFilters: [],
      termFilters: []
    };
  }),
  // Category Filter
  on(FilterActionTypes.RemoveCategoryFilterByValue, (state, action) => {
    const newsFilters = state.topicCategoriesFilters.filter((value: string) => {
      return value !== action.filterValue;
    });

    return { ...state, topicCategoriesFilters: newsFilters };
  }),
  on(FilterActionTypes.SetAllCategoriesFilters, (state, action) => {
    return { ...state, allCategoriesFilters: action.categories };
  }),
  /** * Trending topics filters for news ***/
  on(FilterActionTypes.SetTrendingTopicsFiltersForNews, (state, action) => {
    return { ...state, trendingTopicsFiltersForNews: action.filters };
  }),
  on(FilterActionTypes.ClearTrendingTopicsFiltersForNews, (state) => {
    return { ...state, trendingTopicsFiltersForNews: [] };
  }),
  on(FilterActionTypes.AddTrendingTopicsFiltersForNews, (state, action) => {
    let newTrendingTopicFilters = [...state.trendingTopicsFiltersForNews];

    for (const newFitler of action.filters) {
      const isElementDuplicate = !!state.trendingTopicsFiltersForNews.find((existent: ITrendingTopicForFilter) => {
        if (
          existent.topic === newFitler.topic ||
          existent.id === newFitler.id ||
          existent.shortName === newFitler.shortName
        ) {
          return true;
        }

        return false;
      });

      if (!isElementDuplicate) {
        newTrendingTopicFilters.push(newFitler);
      }
    }

    // Remove duplicates
    newTrendingTopicFilters = newTrendingTopicFilters.filter((value, index: number) => {
      return newTrendingTopicFilters.indexOf(value) === index;
    });

    return {
      ...state,
      trendingTopicsFiltersForNews: newTrendingTopicFilters
    };
  }),
  on(FilterActionTypes.RemoveTrendingTopicsFiltersForNews, (state, action) => {
    const filterRemovedArray: ITrendingTopicForFilter[] = state.trendingTopicsFiltersForNews.filter(
      (value: ITrendingTopicForFilter) => {
        return value.topic !== action.filterToRemove.topic;
      }
    );

    return { ...state, trendingTopicsFiltersForNews: filterRemovedArray };
  }),
  on(FilterActionTypes.RemoveTrendingTopicsFiltersForNewsByTopicName, (state, action) => {
    const filterRemovedArray: ITrendingTopicForFilter[] = state.trendingTopicsFiltersForNews.filter(
      (value: ITrendingTopicForFilter) => {
        return value.topic !== action.filterToRemove;
      }
    );

    return { ...state, trendingTopicsFiltersForNews: filterRemovedArray };
  }),
  // Trending topics sub filters
  on(FilterActionTypes.setTrendingTopicSubfilterQuery, (state, action) => {
    return { ...state, trendingTopicSubFilterQuery: action.query };
  })
);
