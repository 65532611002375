export enum DateFormatType {
  BeforeDate = 'Before Date',
  AfterDate = 'After Date',
  Yesterday = 'Yesterday',
  Today = 'Today',
  DayAgo2 = '2 Days Ago',
  DayAgo3 = '3 Days Ago',
  DayAgo4 = '4 Days Ago',
  DayAgo5 = '5 Days Ago',
  DayAgo6 = '6 Days Ago',
  DayAgo7 = '7 Days Ago',
  Last3Hours = 'Last 3 Hours',
  Last6Hours = 'Last 6 Hours',
  Last12Hours = 'Last 12 Hours',
  Last24Hours = 'Last 24 Hours',
  Last7 = 'Last 7 Days',
  Last15 = 'Last 15 Days',
  Last30 = 'Last 30 Days',
  LastMonth = 'Last Month',
  CustomDate = 'Custom',
  AllDates = 'All Dates',
  ThisMonth = 'This Month',
  NoDateFilter = 'No Date Filter',
  ThisYear = 'This Year',
  LastYear = 'Last Year'
}
