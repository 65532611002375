import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { InterceptorIgnoreHeader } from '../enums';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Allow to ignore headers, in case of using 3d parties
    if (req.headers.has(InterceptorIgnoreHeader.InterceptorIgnore)) {
      const newHeaders = req.headers.delete(InterceptorIgnoreHeader.InterceptorIgnore);

      return next.handle(req.clone({ headers: newHeaders }));
    }

    let headers = new HttpHeaders();

    if (req.headers.has(InterceptorIgnoreHeader.InterceptorIgnoreContentType)) {
      req.headers.delete(InterceptorIgnoreHeader.InterceptorIgnoreContentType);
    } else {
      headers = headers.set('content-type', 'application/json');
    }

    let tokenHeader$ = this.keycloakService.addTokenToHeader(headers);

    if (this.keycloakService.isTokenExpired()) {
      tokenHeader$ = from(this.keycloakService.updateToken()).pipe(
        catchError(() => {
          this.keycloakService.clearToken();

          return null;
        }),
        switchMap(() => this.keycloakService.addTokenToHeader(headers))
      );
    }

    return tokenHeader$.pipe(
      switchMap((headersWithToken: HttpHeaders) => {
        const copiedReq: HttpRequest<any> = req.clone({
          headers: headersWithToken
        });

        return next.handle(copiedReq);
      })
    );
  }
}
