import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { selectUserId } from 'src/app/auth/store-auth/auth.selectors';
import { setActiveKeywordFilters } from 'src/app/core/filters/filters-store/filters.actions';
import {
  setActiveCirculationFilters,
  setActivePublisherTypeFilters,
  setActiveSelectedSources
} from 'src/app/core/filters/sources/sources-filters-store/sources-filters.actions';
import { IOpenmindItem } from 'src/app/openmind/core/interfaces';
import { DateFormatType, FilterType } from '../../core/filters/core/enums';
import { IFilterLabel } from '../../core/filters/core/interfaces';
import { FilterSorterService } from '../../core/filters/core/services/filter-sorter.service';
import { IAppState } from '../../core/store-app/reducers';
import { ISavedTopic } from '../../ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { IPage } from '../core/interfaces';
import { setNewsTaggingFilters, toggleNewsTaggingFilter } from '../news-tagging/news-tags-store/news-tags.actions';
import { AppendToFilters, SetCurrentPage, UpdateFiltersForQuery } from '../store-news/news.actions';
import { selectAllFilters, selectAllFiltersForQuery, selectSavedTopics } from '../store-news/news.selectors';
import { setIsSearchExecute } from 'src/app/event/store/store-events/event.actions';
import { setAnalyticSearchEventQuery } from 'src/app/event/store/store-analytic-search-event/analytic-search-event.action';
import { setDateFilter } from '../../news-filters/store-filters/filters.actions';
import * as moment from 'moment';
import {
  setSearchAnalyticParams,
  setSelectedChartType
} from '../../user-dashboard/components/analytics/core/store/analytic.action';
import { getSearchAnalyticParams } from '../../user-dashboard/components/analytics/core/functions/get-search-analytic-params.function';
import { AnalyticChartTypes } from '../../user-dashboard/components/analytics/core/enums';

@Injectable({
  providedIn: 'root'
})
export class NewsUIService {
  public queryExecuted$: Observable<string>;
  public uniqueDateChipRemoved$: Observable<void>;
  public latestQueryExecuted$ = new BehaviorSubject<string>('');
  public oneDayFilter$ = new BehaviorSubject<boolean>(false);

  private positionRestoreToNew: IOpenmindItem;

  private _executeQuery = new BehaviorSubject<string>('');
  private _uniqueDateChipRemoved: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<IAppState>,
    private filterSorter: FilterSorterService
  ) {
    this.queryExecuted$ = this._executeQuery.asObservable();
    this.uniqueDateChipRemoved$ = this._uniqueDateChipRemoved.asObservable();
  }

  public clearFilters() {
    this.store.dispatch(setActiveKeywordFilters({ filters: [] }));
    this.store.dispatch(setActiveSelectedSources({ sources: [] }));
    this.store.dispatch(setNewsTaggingFilters({ filters: [] }));
    this.store.dispatch(setActiveCirculationFilters({ activeCirculationFilters: [] }));
    this.store.dispatch(setActivePublisherTypeFilters({ activePublisherTypeFilters: [] }));

    this.store.dispatch(
      setDateFilter({
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
        predefinedFilter: DateFormatType.Today
      })
    );

    this.oneDayFilter$.next(false);
    this.store.dispatch(setSearchAnalyticParams({ searchFilter: getSearchAnalyticParams()[1] }));
    this.store.dispatch(setSelectedChartType({ chartType: AnalyticChartTypes.BarChart }));
  }

  public executeNewSavedSearchQuery(searchString: string = ''): void {
    if (searchString && searchString !== '') {
      this.store.dispatch(setAnalyticSearchEventQuery({ query: searchString }));
      this.store.dispatch(setIsSearchExecute({ isSearchStart: true }));
    } else {
      this.store.dispatch(setIsSearchExecute({ isSearchStart: false }));
    }
    this._executeQuery.next(searchString);
  }

  public setCurrentPage(page: IPage): void {
    this.store.dispatch(SetCurrentPage({ currentPage: page }));
  }

  /**
   * After we get the filters from the API, we need to add them to the store.
   * @param filters: Array of filters
   */
  public appendToFilters(filters: IFilterLabel[]): void {
    this.store.dispatch(AppendToFilters({ filters }));
  }

  /**
   * Populates the filters that were obtained previously from the API.
   * @param filterType: FilterType.
   */
  public populateFilterData(filterType: FilterType): Observable<IFilterLabel[]> {
    return this.store.pipe(
      select(selectAllFilters),
      map((filterTypes: IFilterLabel[]) =>
        filterTypes.filter((filterLabel: IFilterLabel) => {
          return filterLabel.type === filterType;
        })
      ),
      map((filtersData) => this.filterSorter.sortSelectedFirst(filtersData))
    );
  }

  public getSavedTopics(): Observable<ISavedTopic[]> {
    return this.store.pipe(select(selectSavedTopics));
  }

  /**
   * Remove the date filter from the queries, but keep other filters
   */
  public removeDateFilterFromQueryStore(): void {
    this.store.pipe(take(1), select(selectAllFiltersForQuery)).subscribe({
      next: (newFilters: string[]) => {
        const newQueryArray = newFilters.filter((filterQuery) => {
          return filterQuery.indexOf('neutrality_article_normpubDate:') !== -1 ? false : filterQuery;
        });

        this.store.dispatch(
          UpdateFiltersForQuery({
            filters: newQueryArray
          })
        );
      }
    });
  }

  /**
   * Prevents selected date to cause nasty bugs
   */
  public removeDateSelectedFromFilterComponent(): void {
    this._uniqueDateChipRemoved.next();
  }

  /**
   * For restoring position when going back from openmind
   */

  public setArticleToRestoreFromOpenmind(newToRestore: IOpenmindItem | null): void {
    this.positionRestoreToNew = newToRestore;
  }

  public getArticleToRestoreFromOpenmind() {
    return this.positionRestoreToNew;
  }

  /**
   * For reset top_story and reading_list filters
   */
  public resetReadingFilters(): void {
    this.store.pipe(select(selectUserId), take(1)).subscribe({
      next: (userId: string) => {
        const isActive = false;
        // uncheck reading_list filter
        this.store.dispatch(
          toggleNewsTaggingFilter({
            filter: `reading_list_users:("${userId}")`,
            isActive
          })
        );
      }
    });
  }
}
