import { createAction, props } from '@ngrx/store';
import {
  PublisherItemNode,
  IPublisherFilter,
  ISubfilterToggle,
  PublisherStateNode
} from '../filter/tree-filter-publishers/core/interfaces';
import * as moment from 'moment';
import { DateFormatType } from 'src/app/core/filters/core/enums';
import { ISavedTopic } from '../../ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { IFilterFacetFromNews, IFilterFacetFromSections } from '../../core/filters/core/interfaces';
import { ITermsDataset } from 'src/app/news/core/interfaces';
import { ITrendingTopicForFilter, ITrendingTopicPublisher } from 'src/app/api/core/api-interfaces';

export const SetAvailablePublishersFilters = createAction(
  '[Filters] Set Available Filter',
  props<{ availablePublishers: PublisherItemNode[] }>()
);

export const SetAvailablePublishersLocalStateFilters = createAction(
  '[Filters] Set Available Local States Filter',
  props<{ availablePublisherLocalStates: PublisherStateNode[] }>()
);

export const ToggleFilterSelection = createAction('[Filters] Toggle filter selection', props<IPublisherFilter>());

export const ToggleStateFilterSelection = createAction(
  '[Filters] Toggle state filter selection',
  props<IPublisherFilter>()
);

export const ToggleMultipleFilterSelection = createAction(
  '[Filters] Toggle multiple filter selection',
  props<{ publisherFilters: IPublisherFilter[] }>()
);

export const ToggleSubfilter = createAction('[Filters] Toggle subfilter', props<ISubfilterToggle>());

export const ClearPublisherFilters = createAction('[Filters] Clear all publisher filters');

export const ClearSelectedPublishersLocalNodes = createAction('[Filters] Clear all state filters');

export const ClearSubfilters = createAction('[Filters] Clear subfilters');

/** * Date **/
export const setDateFilter = createAction(
  '[Filters] Set date filter',
  props<{
    startDate: moment.Moment;
    endDate: moment.Moment;
    predefinedFilter?: DateFormatType;
  }>()
);

export const setIsDateFilterOpened = createAction(
  '[Filters] Set date filter opened',
  props<{
    opened: boolean;
  }>()
);

export const toggleIsDateFilterOpened = createAction('[Filters] Toggle date filter opened');

/** * My filters ***/
export const SetMyFilterActive = createAction(
  '[Filters] Set Active My Filter',
  props<{ myActiveFilter: ISavedTopic }>()
);

export const AddPublisherToDeselect = createAction(
  '[Filters] Add publisher to be unselected in filter tree',
  props<{ newPublisherToBeUnselected: string }>()
);

export const DeletePublisherToDeselect = createAction(
  '[Filters] Delete publisher to be unselected in filter tree',
  props<{ newPublisherToBeUnselected: string }>()
);

export const DeleteMultiplePublisherToDeselect = createAction(
  '[Filters] Delete multiple publisher to be unselected in filter tree',
  props<{ newPublisherToBeUnselected: string[] }>()
);

export const AddMultiplePublisherToDeselect = createAction(
  '[Filters] Add multiple publisher to be unselected in filter tree',
  props<{ newPublisherToBeSelected: string[] }>()
);

export const AddPublisherToSelect = createAction(
  '[Filters] Add publisher to be selected in filter tree',
  props<{ newPublisherToBeSelected: string }>()
);

export const AddMultiplePublisherToSelect = createAction(
  '[Filters] Add multiple publisher to be selected in filter tree',
  props<{ newPublisherToBeSelected: string[] }>()
);

export const DeletePublisherToSelect = createAction(
  '[Filters] Delete publisher to be selected in filter tree',
  props<{ newPublisherToBeSelected: string }>()
);

/** * Trending topics category and publisher filters ***/
export const SetAllTrendingTopicsPublishers = createAction(
  '[Filters] Set all trending  topics publishers',
  props<{ publishers: ITrendingTopicPublisher[] }>()
);

export const SetTopicCategoriesFilters = createAction(
  '[Filters] Set topics categgories filters',
  props<{ categories: string[] }>()
);

export const SetTrendingTopicsFiltersForNews = createAction(
  '[Filters] Set all trending topics filters for news',
  props<{ filters: ITrendingTopicForFilter[] }>()
);

export const ClearTrendingTopicsFiltersForNews = createAction(
  '[Filters] Clear all trending topics filters for news',
  props<{ filters: ITrendingTopicForFilter[] }>()
);

export const AddTrendingTopicsFiltersForNews = createAction(
  '[Filters] Add a trending topics filters for news',
  props<{ filters: ITrendingTopicForFilter[] }>()
);

export const RemoveTrendingTopicsFiltersForNews = createAction(
  '[Filters] Remove a trending topics filter for news',
  props<{ filterToRemove: ITrendingTopicForFilter }>()
);

export const RemoveTrendingTopicsFiltersForNewsByTopicName = createAction(
  '[Filters] Remove a trending topics filter for news',
  props<{ filterToRemove: string }>()
);

/** * Category filters ***/

export const RemoveCategoryFilterByValue = createAction(
  '[Filters] Removes a filter by value',
  props<{ filterValue: string }>()
);

/** * Facets ***/

export const SetFiltersFacets = createAction('[Filters] Set filters facets', props<{ facets: IFilterFacetFromNews }>());

/** * Facets For Sections***/
export const SetFiltersFacetsForSections = createAction(
  '[Filters] Set filters facets for sections',
  props<{ facetsForSections: IFilterFacetFromSections }>()
);

export const SetTermsFilters = createAction('[Filters] Set terms filters', props<{ terms: ITermsDataset[] }>());

export const SetAllCategoriesFilters = createAction(
  '[Filters] Set all categories filters',
  props<{ categories: string[] }>()
);

/** * Chips ***/
export const ClearAllFiltersButDate = createAction('[Filters] Clear All Filter Chips', props<{ timestamp: number }>());

// Trending topic subfilter query
export const setTrendingTopicSubfilterQuery = createAction(
  '[Filters] Set trending topic subfilter query',
  props<{ query: string }>()
);
