// this class reset its internal value to the default value after the value is consulted

export class OneTimeValue<T> {
  protected _defaultValue: T;
  protected _value: T;

  constructor(defaultValue: T) {
    this._defaultValue = defaultValue;
    this._value = defaultValue;
  }

  public get value(): T {
    const value: T = this._value;
    this.reset();

    return value;
  }

  public set value(value: T) {
    this._value = value;
  }

  public reset() {
    this._value = this._defaultValue;
  }

  public get defaultValue(): T {
    return this._defaultValue;
  }
}

export class BoleanOneTimeValue extends OneTimeValue<boolean> {
  constructor() {
    super(false);
  }

  public set() {
    this._value = true;
  }

  public isSet(): boolean {
    return this._value;
  }
}
