import { createAction, props } from '@ngrx/store';

export const trendingEventStartState = createAction(
  '[Trending Event] trending event start state',
  props<{ isTrendingEventStart: boolean }>()
);

export const setTrendingTimePeriod = createAction(
  '[Trending Event] set trending event time period',
  props<{ timePeriod: string }>()
);

export const setTrendingHoverOver = createAction(
  '[Trending Event] set trending event hoverOver',
  props<{ hoverOver: string }>()
);

export const setTrendingExpandPanelState = createAction(
  '[Trending Event] set trending event expand panel state',
  props<{ expandPanel: boolean }>()
);

export const setTrendingApplySearchState = createAction(
  '[Trending Event] set trending event apply search state',
  props<{ applySearch: string }>()
);

export const resetTrendingEvent = createAction('[Trending Event] reset trending event');
