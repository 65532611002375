import { createReducer, MetaReducer, on } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as ResponsiveActionTypes from './responsive.actions';
import { IBreadcrumb } from '../breadcrumb/interfaces';
import { BreadcrumbTitleColorType, BreadcrumbType } from 'src/app/neutrality-root/breadcrumb/enums';
import { BreadcrumbColorType } from '../breadcrumb/enums';
import { FilterMenuType } from '../../core/filters/core/enums';

export const responsiveStateFeatureKey = 'responsiveState';

export interface IResponsiveState {
  isOpenSidebar: boolean;
  isOpenMenuDrawer: boolean;
  isOpenNotifications: boolean;
  activeModal: FilterMenuType;
  isSmallScreen: boolean;
  breadcrumb: IBreadcrumb;
  showVisualizationMenu: boolean;
  isSearchBarEmpty: boolean;
  // App Navigation
  canGoBackToNeutralityRoute: boolean;
  // Search bar
  searchBarValue: string;
  overrideSearchBarValue: {
    timestamp: number;
    searchValue: string;
  };
}

export const responsiveInitialState: IResponsiveState = {
  isOpenSidebar: false,
  isOpenMenuDrawer: false,
  isOpenNotifications: false,
  activeModal: FilterMenuType.Empty,
  isSmallScreen: false,
  breadcrumb: {
    breadcrumbTitle: BreadcrumbType.Empty,
    breadcrumbColor: BreadcrumbColorType.Empty,
    breadcrumbTitleColor: BreadcrumbTitleColorType.Empty
  },
  showVisualizationMenu: true,
  isSearchBarEmpty: true,
  canGoBackToNeutralityRoute: false,
  searchBarValue: '',
  overrideSearchBarValue: {
    timestamp: null,
    searchValue: null
  }
};

export const responsiveReducer = createReducer(
  responsiveInitialState,
  on(ResponsiveActionTypes.OpenOrCloseSidebarMenu, (state, action) => {
    return { ...state, isOpenSidebar: action.isOpenSidebar };
  }),
  on(ResponsiveActionTypes.ToggleSidebarMenu, (state: IResponsiveState) => {
    const isOpened: boolean = state.isOpenSidebar;

    return {
      ...state,
      isOpenNotifications: false,
      isOpenSidebar: !isOpened
    };
  }),

  // Notifications
  on(ResponsiveActionTypes.OpenOrCloseNotificationsMenu, (state, action) => {
    return { ...state, isOpenNotifications: action.isOpen };
  }),
  on(ResponsiveActionTypes.ToggleNotificationsMenu, (state) => {
    const isOpen: boolean = state.isOpenNotifications;

    return {
      ...state,
      isOpenSidebar: false,
      isOpenMenuDrawer: false,
      isOpenNotifications: !isOpen
    };
  }),

  // Menu drawer
  on(ResponsiveActionTypes.OpenOrCloseMenuDrawer, (state, action) => {
    return { ...state, isOpenMenuDrawer: action.isOpen };
  }),
  on(ResponsiveActionTypes.ToggleMenuDrawer, (state) => {
    const isOpen: boolean = state.isOpenMenuDrawer;

    return {
      ...state,
      isOpenSidebar: false,
      isOpenNotifications: false,
      isOpenMenuDrawer: !isOpen
    };
  }),

  // Set modals open
  on(ResponsiveActionTypes.SetModalOpen, (state, action) => {
    return {
      ...state,
      isOpenSidebar: false,
      isOpenNotifications: false,
      isOpenMenuDrawer: false,
      activeModal: action.active
    };
  }),

  // Set is small screen
  on(ResponsiveActionTypes.SetIsSmallScreen, (state, action) => {
    return {
      ...state,
      isSmallScreen: action.isSmall
    };
  }),

  // Set breadcrumb
  on(ResponsiveActionTypes.SetBreadcrumb, (state, action) => {
    return {
      ...state,
      breadcrumb: action.breadcrumb
    };
  }),

  // Set breadcrumb
  on(ResponsiveActionTypes.SetVisualizationMenu, (state, action) => {
    return {
      ...state,
      showVisualizationMenu: action.showVisualizationMenu
    };
  }),
  // Search Bar empty
  on(ResponsiveActionTypes.SetIsSearchbarEmpty, (state, action) => {
    return {
      ...state,
      isSearchBarEmpty: action.isEmpty
    };
  }),
  // App navigation
  on(ResponsiveActionTypes.SetCanGoBackToNeutralityRoute, (state, action) => {
    return {
      ...state,
      canGoBackToNeutralityRoute: action.canGoBack
    };
  }),
  // Search bar
  on(ResponsiveActionTypes.SetSearchBarValue, (state, action) => {
    return {
      ...state,
      searchBarValue: action.searchValue
    };
  }),
  on(ResponsiveActionTypes.SetOverrideSearchBarValue, (state, action) => {
    return {
      ...state,
      overrideSearchBarValue: {
        timestamp: new Date().getTime(),
        searchValue: action.searchValue
      },
      searchBarValue: action.searchValue
    };
  })
);

export const responsiveMetaReducers: MetaReducer<IResponsiveState>[] = !environment.production ? [] : [];
