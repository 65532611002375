/* istanbul ignore file */
import { createReducer, MetaReducer, on } from '@ngrx/store';
import { DEFAULT_NEWS_QUERY } from 'src/app/core/constants/default-query';
import { SortType } from 'src/app/core/filters/core/enums';
import { ISavedCustomSources } from 'src/app/news-filters/filter/tree-filter-publishers/core/interfaces';
import { environment } from '../../../environments/environment';
import { FilterType, SortDirection } from '../../core/filters/core/enums';
import { IFilterChip, IFilterLabel } from '../../core/filters/core/interfaces';
import { ISavedTopic } from '../../ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { IResearchCollection } from '../../research-collections/core/interfaces';
import { INewsDataset, INewsItem, INewsPagination, IPage, IShowTrendingTopicsResultsEvent } from '../core/interfaces';
import { getTransition } from '../utils/compute-transition.function';
import * as NewsActionTypes from './news.actions';

export const newsStateFeatureKey = 'newsState';

export interface INewsState {
  // If property ends in the word "Query", is the formatted value that will be added on the API.
  currentPage: IPage;
  filterChips: IFilterChip[];
  currentSearchQuery: string;
  savedTopics: ISavedTopic[];
  quickAccessSavedTopics: string[];
  arrayFiltersForQuery: string[];
  toggleFiltersForQuery: string[];

  // Populating filters section
  dateFilter: string;
  filters: IFilterLabel[];

  currentSpinner: boolean;
  currentSortDirection: string;
  currentSortOrder: INewsPagination;
  currentMenuChartView: boolean;
  currentLayoutMenuView: boolean;
  isActiveDefaultFilter: boolean;
  currentNewsDataset: INewsDataset;
  dateQueryFilter: string;
  showResultsFromTrendingTopicsEvent: IShowTrendingTopicsResultsEvent;
  savedCustomSources: ISavedCustomSources[];

  // Tabs
  isArticlesTabActive: boolean;

  // Validated saved topic
  invalidatedActiveSavedTopicTimestamp: number;

  readingListCount: number;

  researchCollections: IResearchCollection[];

  // Selected saved topic
  selectedSavedTopic?: string;
}

export const newsInitialState: INewsState = {
  toggleFiltersForQuery: [],
  arrayFiltersForQuery: [],
  currentSearchQuery: DEFAULT_NEWS_QUERY,

  currentPage: { pageSize: 100, pageIndex: 0 },

  filterChips: [],

  savedTopics: [],
  quickAccessSavedTopics: [null, null, null, null, null, null, null],
  filters: [
    {
      name: FilterType.ReadingList,
      value: FilterType.ReadingList,
      isActive: false,
      icon: 'book',
      type: FilterType.ReadingList,
      path: [],
      logo: null
    }
  ],
  dateFilter: undefined,

  currentSortOrder: {
    start: 0,
    rows: 0,
    sort: SortType.Date,
    qf: 'neutrality_article_title^50 neutrality_article_description^20 neutrality_terms^20 '
  },

  currentSortDirection: SortDirection.DESC,
  currentMenuChartView: false,
  currentLayoutMenuView: false,
  currentSpinner: false,
  isActiveDefaultFilter: false,
  currentNewsDataset: null,
  dateQueryFilter: null,
  // Trending Topics
  showResultsFromTrendingTopicsEvent: null,
  savedCustomSources: [],

  // Tabs
  isArticlesTabActive: true,

  // Validated saved topic
  invalidatedActiveSavedTopicTimestamp: null,

  readingListCount: 0,

  researchCollections: []
};

export const newsReducer = createReducer(
  newsInitialState,
  on(NewsActionTypes.AddToggleFilterToQuery, (state, action) => {
    const filters: string[] = [...state.toggleFiltersForQuery];
    filters.push(action.filter);

    return { ...state, toggleFiltersForQuery: [...filters] };
  }),

  on(NewsActionTypes.RemoveToggleFilterFromQuery, (state, action) => {
    const filters: string[] = [...state.toggleFiltersForQuery];

    // Remove filter
    if (filters.includes(action.filter)) {
      const idx: number = filters.findIndex((filter: string) => filter === action.filter);
      filters.splice(idx, 1);
    }

    return { ...state, toggleFiltersForQuery: [...filters] };
  }),

  on(NewsActionTypes.UpdateFiltersForQuery, (state, action) => {
    const newFiltersForQuery: string[] = [...action.filters];
    // check if there is an active date filter
    const dateFilter: string[] = state.arrayFiltersForQuery.filter((filter) =>
      filter.includes('neutrality_article_normpubDate')
    );
    // check if there is a new date filter
    const actualDateFilter: string[] = newFiltersForQuery.filter((filter) =>
      filter.includes('neutrality_article_normpubDate')
    );
    // If there is an active date filter and payload do not have a new date filter
    // then add the payload data and respect active date filter
    // else add only the payload data
    if (dateFilter.length && !actualDateFilter.length) {
      return { ...state, arrayFiltersForQuery: [...newFiltersForQuery, ...dateFilter] };
    }

    return { ...state, arrayFiltersForQuery: [...newFiltersForQuery] };
  }),

  on(NewsActionTypes.ToggleFilter, (state, action) => {
    const filters: IFilterLabel[] = [...state.filters];

    const toggledFilter: IFilterLabel = { ...action.filter };
    toggledFilter.isActive = !toggledFilter.isActive;

    if (action.shouldBeActive !== undefined) {
      toggledFilter.isActive = action.shouldBeActive;
    }

    const index = filters.findIndex((filter) => {
      return filter.name === action.filter.name;
    });
    filters[index] = { ...toggledFilter };

    return { ...state, filters: [...filters] };
  }),
  on(NewsActionTypes.ClearActiveReadingAndTopFilters, (state) => {
    const filters: IFilterLabel[] = state.filters.map((filter: IFilterLabel) => {
      return {
        ...filter,
        isActive: false
      };
    });

    return { ...state, filters: [...filters] };
  }),

  on(NewsActionTypes.AppendToFilters, (state, action) => {
    const allFilters: IFilterLabel[] = [...state.filters, ...action.filters];
    // PATCH to avoid filter duplicates.
    const distinctFilters: IFilterLabel[] = [];

    allFilters.forEach((value: IFilterLabel) => {
      let findCount = 0;

      distinctFilters.forEach((t: IFilterLabel) => {
        const isMatch: boolean =
          t.isActive === value.isActive && t.name === value.name && t.type === value.type && t.value === value.value;

        if (isMatch) {
          findCount++;
        }
      });

      if (findCount < 1) {
        distinctFilters.push(value);
      }
    });

    return { ...state, filters: distinctFilters };
  }),

  on(NewsActionTypes.SetDateFilter, (state, action) => {
    return { ...state, dateFilter: action.date };
  }),

  on(NewsActionTypes.ResetDateForm, (state) => {
    return { ...state, dateFilter: undefined };
  }),

  on(NewsActionTypes.ResetAllFilters, (state) => {
    const chips: IFilterChip[] = [...state.filterChips.filter((chip) => chip.filterType === FilterType.Search)];
    const filters: IFilterLabel[] = [
      ...state.filters.map((filter) => {
        return { ...filter, isActive: false };
      })
    ];

    return {
      ...state,
      filterChips: [...chips],
      filters: [...filters],
      toggleFiltersForQuery: [],
      dateFilter: undefined,
      arrayFiltersForQuery: []
    };
  }),

  on(NewsActionTypes.SetCurrentPage, (state, action) => {
    return { ...state, currentPage: { ...action.currentPage } };
  }),

  on(NewsActionTypes.SetCurrentSortOrder, (state, action) => {
    return { ...state, currentSortOrder: action.currentSortOrder };
  }),

  on(NewsActionTypes.SetCurrentSortDirection, (state, action) => {
    return { ...state, currentSortDirection: action.currentSortDirection };
  }),

  on(NewsActionTypes.AddSavedTopic, (state, action) => {
    let savedTopics: ISavedTopic[] = [];
    if (action.savedTopic.isDefault) {
      let resetTopicDefault: ISavedTopic;
      for (const topic of state.savedTopics) {
        resetTopicDefault = {
          ...topic,
          searchName: topic.searchName,
          order: topic.order,
          isDefault: false
        };
        savedTopics = [...savedTopics, resetTopicDefault];
      }
    } else {
      savedTopics = [...state.savedTopics];
    }
    const idx: number = savedTopics.findIndex((topic: ISavedTopic) => {
      return topic.searchName === action.savedTopic.searchName;
    });

    if (idx !== -1) {
      savedTopics.splice(idx, 1, action.savedTopic);
    } else {
      savedTopics.splice(0, 0, action.savedTopic);
    }

    return { ...state, savedTopics: [...savedTopics] };
  }),

  on(NewsActionTypes.AddCustomSource, (state, action) => {
    let savedCustomSources: ISavedCustomSources[] = [];

    savedCustomSources = [...state.savedCustomSources];

    const index: number = savedCustomSources.findIndex((search: ISavedCustomSources) => {
      return search.customSourceName === action.savedCustomSource.customSourceName;
    });

    if (index !== -1) {
      savedCustomSources.splice(index, 1, action.savedCustomSource);
    } else {
      savedCustomSources.splice(0, 0, action.savedCustomSource);
    }

    return { ...state, savedCustomSources: [...savedCustomSources] };
  }),

  on(NewsActionTypes.AddResearchCollection, (state, action) => {
    let researchCollections: IResearchCollection[] = [];
    let resetCollectionDefault: IResearchCollection;
    for (const search of state.researchCollections) {
      resetCollectionDefault = {
        id: search.id,
        label: search.label,
        collections: search.collections,
        itemType: search.itemType,
        owner: search.owner,
        creationDate: search.creationDate
      };
      researchCollections = [...researchCollections, resetCollectionDefault];
    }
    // TODO: to implement store for default item
    // if (action.researchCollection.isDefault) {
    // add top default item
    // } else {
    //   researchCollections = [...state.researchCollections];
    // }
    const idx: number = researchCollections.findIndex((collection: IResearchCollection) => {
      return collection.label === action.researchCollection.label;
    });

    if (idx !== -1) {
      researchCollections.splice(idx, 1, action.researchCollection);
    } else {
      researchCollections.splice(0, 0, action.researchCollection);
    }

    return { ...state, researchCollections: [...researchCollections] };
  }),
  on(NewsActionTypes.UpdateSavedTopics, (state, action) => {
    const savedTopics: ISavedTopic[] = action.savedTopics;

    return { ...state, savedTopics: [...savedTopics] };
  }),
  on(NewsActionTypes.UpdateQuickAccessTopicsState, (state, action) => {
    return { ...state, quickAccessSavedTopics: [...action.topics] };
  }),
  on(NewsActionTypes.SetQuickAccessTopicsState, (state, action) => {
    return { ...state, quickAccessSavedTopics: [...action.topics] };
  }),
  on(NewsActionTypes.UpdateSavedCustomSource, (state, action) => {
    const savedCustomSources: ISavedCustomSources[] = action.savedCustomSources;

    return { ...state, savedCustomSources: [...savedCustomSources] };
  }),
  on(NewsActionTypes.UpdateCurrentQuery, (state, action) => {
    return { ...state, currentSearchQuery: action.currentQuery };
  }),
  on(NewsActionTypes.UpdateResearchCollections, (state, action) => {
    const researchCollections: IResearchCollection[] = action.researchCollections;

    return { ...state, researchCollections: [...researchCollections] };
  }),
  on(NewsActionTypes.DeleteSavedTopic, (state, action) => {
    const searchToDelete = action.savedTopic;
    const index: number = state.savedTopics.findIndex((topic: ISavedTopic) => {
      return topic.searchName === searchToDelete.searchName;
    });
    if (index !== -1) {
      let topics: ISavedTopic[] = [...state.savedTopics];
      topics = topics.filter((element: ISavedTopic, elementIndex: number) => {
        return elementIndex !== index;
      });

      return { ...state, savedTopics: [...topics] };
    }

    return { ...state };
  }),
  on(NewsActionTypes.DeleteCustomSource, (state, action) => {
    const customSourceToDelete = action.deletedCustomSource;
    const index: number = state.savedCustomSources.findIndex((search: ISavedCustomSources) => {
      return search.customSourceName === customSourceToDelete.customSourceName;
    });
    if (index !== -1) {
      let customSources: ISavedCustomSources[] = [...state.savedCustomSources];
      customSources = customSources.filter((element: ISavedCustomSources, elementIndex: number) => {
        return elementIndex !== index;
      });

      return { ...state, savedCustomSources: [...customSources] };
    }

    return { ...state };
  }),
  on(NewsActionTypes.DeleteResearchCollection, (state, action) => {
    const searchToDelete = action.researchCollection;
    const index: number = state.researchCollections.findIndex((collection: IResearchCollection) => {
      return collection.label === searchToDelete.label;
    });
    if (index !== -1) {
      let collections: IResearchCollection[] = [...state.researchCollections];
      collections = collections.filter((element: IResearchCollection, elementIndex: number) => {
        return elementIndex !== index;
      });

      return { ...state, researchCollections: [...collections] };
    }

    return { ...state };
  }),

  on(NewsActionTypes.SetNameSavedTopic, (state, action) => {
    const topicsToUpdate: ISavedTopic[] = [...action.newTopics];

    return { ...state, savedTopics: [...topicsToUpdate] };
  }),

  on(NewsActionTypes.SetNameResearchCollection, (state, action) => {
    const collectionsToUpdate: IResearchCollection[] = [...action.newCollections];

    return { ...state, researchCollections: [...collectionsToUpdate] };
  }),
  on(NewsActionTypes.SetSavedTopicsOrder, (state, action) => {
    const topicsToUpdateOrder: ISavedTopic[] = [...action.setSavedTopicOrder];

    return { ...state, savedTopics: [...topicsToUpdateOrder] };
  }),
  on(NewsActionTypes.SetSelectedTopic, (state, action) => {
    const selectedTopic: string = action.selectedTopic;

    return { ...state, selectedSavedTopic: selectedTopic };
  }),
  on(NewsActionTypes.SetResearchCollectionsOrder, (state, action) => {
    const collectionsToUpdateOrder: IResearchCollection[] = [...action.setResearchCollectionOrder];

    return { ...state, researchCollections: [...collectionsToUpdateOrder] };
  }),

  on(NewsActionTypes.SetMenuChartOpen, (state, action) => {
    return {
      ...state,
      currentMenuChartView: action.currentState,
      currentLayoutMenuView: false
    };
  }),

  on(NewsActionTypes.SetMenuLayoutOpen, (state, action) => {
    return {
      ...state,
      currentLayoutMenuView: action.currentState,
      currentMenuChartView: false
    };
  }),

  on(NewsActionTypes.SetSpinnerStatus, (state, action) => {
    return { ...state, currentSpinner: action.currentState };
  }),

  on(NewsActionTypes.SetDefaultFilter, (state, action) => {
    let topics: ISavedTopic[] = [];
    let updateTopic: ISavedTopic;

    for (const topic of state.savedTopics) {
      if (topic.searchName === action.defaultFilter.searchName) {
        updateTopic = {
          ...topic,
          isDefault: action.defaultFilter.isDefault ? false : true
        };
      } else {
        updateTopic = {
          ...topic,
          isDefault: false
        };
      }
      topics = [...topics, updateTopic];
    }

    return { ...state, savedTopics: [...topics] };
  }),

  on(NewsActionTypes.ToggleDefaultFilter, (state, action) => {
    return { ...state, isActiveDefaultFilter: action.active };
  }),

  on(NewsActionTypes.SetCurrentNews, (state, action) => {
    return { ...state, currentNewsDataset: action.newsDataset };
  }),

  on(NewsActionTypes.UpdateCurrentNewsItem, (state, action) => {
    let readingListCount = state.readingListCount;

    const currentNewsItemsData: INewsItem[] = state.currentNewsDataset?.newsItems?.map((newItem: INewsItem) => {
      if (newItem.id === action.newsItemData.id) {
        readingListCount += getTransition(newItem.isReadingListSaved, action.newsItemData.isReadingListSaved);

        return {
          ...action.newsItemData
        };
      }

      return newItem;
    });

    return {
      ...state,
      readingListCount,
      currentNewsDataset: {
        ...state.currentNewsDataset,
        newsItems: currentNewsItemsData
      }
    };
  }),

  on(NewsActionTypes.SetCurrentDateFilter, (state, action) => {
    return { ...state, dateQueryFilter: action.dateQueryFilter };
  }),
  // Trending topics
  on(NewsActionTypes.SetShowResultsFromTrendingTopicsId, (state, action) => {
    return { ...state, showResultsFromTrendingTopicsEvent: action.event };
  }),
  // Tabs
  on(NewsActionTypes.SetIsArticlesTabVisible, (state, action) => {
    return { ...state, isArticlesTabActive: action.isVisible };
  }),

  on(NewsActionTypes.SetReadingListCount, (state, action) => {
    return { ...state, readingListCount: action.count };
  }),
  // Validation saved topics
  on(NewsActionTypes.InvalidateCurrentActiveSearch, (state) => {
    return { ...state, invalidatedActiveSavedTopicTimestamp: new Date().getTime() * Math.random() };
  }),
  on(NewsActionTypes.ClearInvalidateCurrentActiveSearch, (state) => {
    return { ...state, invalidatedActiveSavedTopicTimestamp: null };
  })
);

export const newsMetaReducers: MetaReducer<INewsState>[] = !environment.production ? [] : [];
