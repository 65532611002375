import { createAction, props } from '@ngrx/store';

export const analyticSearchEventStartState = createAction(
  '[Analytic Search Event] analytic search event state',
  props<{ isAnalyticSearchEventStart: boolean }>()
);

export const setAnalyticSearchEventQuery = createAction(
  '[Analytic Search Event] set analytic search event query',
  props<{ query: string }>()
);

export const setAnalyticSearchEventQuerySavedTopicState = createAction(
  '[Analytic Search Event] set analytic search event querySavedTopic state',
  props<{ querySavedTopic: boolean }>()
);

export const setAnalyticSearchEventSearchSession = createAction(
  '[Analytic Search Event] set analytic search event searchSession',
  props<{ searchSession: string }>()
);

export const setAnalyticSearchEventTimePeriod = createAction(
  '[Analytic Search Event] set analytic search event timePeriod',
  props<{ timePeriod: string }>()
);

export const setAnalyticSearchEventChartType = createAction(
  '[Analytic Search Event] set analytic search event chartType',
  props<{ chartType: string }>()
);

export const setAnalyticSearchEventExpandPanelState = createAction(
  '[Analytic Search Event] set analytic search event expandPanel state',
  props<{ expandPanel: boolean }>()
);

export const setAnalyticSearchEventApplySearch = createAction(
  '[Analytic Search Event] set analytic search event applySearch',
  props<{ applySearch: string }>()
);

export const setAnalyticSearchEventDuration = createAction(
  '[Analytic Search Event] set analytic search event duration',
  props<{ duration: string }>()
);

export const setAnalyticSearchEventSelectedTimePeriod = createAction(
  '[Analytic Search Event] set analytic search event selected timeperiod',
  props<{ selectedTimePeriod: string }>()
);

export const setAnalyticSearchEventTooltipClickedState = createAction(
  '[Analytic Search Event] set analytic search event tooltip click state',
  props<{ isTooltipClicked: boolean }>()
);

export const resetAnalyticSearchEvent = createAction('[Analytic Search Event] reset analytic search event');
