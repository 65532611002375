export enum FilterType {
  ReadingList = 'Reading List',
  Publishers = 'Source',
  Author = 'Author',
  Topic = 'Topic',
  Category = 'Category',
  Date = 'Date',
  Search = 'Search',
  TrendingTopicForNews = 'TrendingTopicForNews',
  // Keywords
  Keywords = 'Keywords', // For the sidebar, these do not affect keywords themselves
  KeywordsForQuery = 'KeywordsForQuery', // For the top filters, this changes the keywords result
  Relevancy = 'Relevancy',
  Sentiment = 'Sentiment'
}
