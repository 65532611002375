import { createAction, props } from '@ngrx/store';
import { IPlaceHolderSpaceElement, IRenderedVisualization, ISavedDashboard } from '../core/interfaces';

// ------------------- Main ------------------- //
export const SetVersion = createAction('[Dashboard] Sets the version', props<{ version: string }>());

export const AddDashboardVisualization = createAction(
  '[Dashboard] Add new dashboard visualization',
  props<{ visualization: IRenderedVisualization }>()
);

export const RemoveDashboardVisualization = createAction(
  '[Dashboard] Remove a visualization',
  props<{ visualizationId: string }>()
);

export const RemoveAllDashboardVisualizations = createAction('[Dashboard] Remove all visualizations');

// Selecting

export const SetActiveVisualizationId = createAction(
  '[Dashboard] Set selected dashboard output',
  props<{ id: string }>()
);

export const SetVisualizationTitleById = createAction(
  '[Dashboard] Set the title of a visualization by id',
  props<{ id: string; title: string }>()
);

export const SetPanelMovementLocked = createAction(
  '[Dashboard] Sets the panel movement lock',
  props<{ isLocked: boolean }>()
);

export const SetVisualizationBackgroundImage = createAction(
  '[Dashboard] Sets the visualization background image',
  props<{ imageInBase64: string }>()
);

export const setShouldDisplayBackgroundPicker = createAction(
  '[Dashboard] Sets if should display bg picker',
  props<{ shouldDisplay: boolean }>()
);

// Grid, Free modes

export const setVisualizationFreeMode = createAction(
  '[Dashboard] Sets the visualization free mode',
  props<{ isFreeMode: boolean }>()
);

export const setVisualizationResetMode = createAction(
  '[Dashboard] Sets the visualization reset mode',
  props<{ canResetPanel: boolean }>()
);

export const setGridFreeSpacePlaceHolders = createAction(
  '[Dashboard] Sets the grid free space placeholders',
  props<{ placeholderElements: IPlaceHolderSpaceElement[] }>()
);

export const setFullScreenElement = createAction(
  '[Dashboard] Sets the full screen visualization',
  props<{ visualizationId: string }>()
);

// Animations

export const setIsDraggingAVisualization = createAction(
  '[Dashboard] Sets if there is a visualization being dragged',
  props<{ isDragged: boolean }>()
);

// Save state

export const setStoredDashboards = createAction(
  '[Dashboard] Sets the stored dashboards',
  props<{ dashboards: ISavedDashboard[] }>()
);

export const addStoredDashboard = createAction(
  '[Dashboard] Add a dashboard',
  props<{ dashboardToAdd: ISavedDashboard }>()
);

export const removeStoredDashboardByName = createAction(
  '[Dashboard] Remove a dashboard by name',
  props<{ name: string }>()
);

export const setActiveDashboardFromSave = createAction(
  '[Dashboard] Set active dashboard from save',
  props<{ dashboard: ISavedDashboard }>()
);

// Sidebar

export const setIsDashboardSidebarOpened = createAction(
  '[Dashboard] Set the status of the dashboard sidebar',
  props<{ isOpen: boolean }>()
);

// Loading

export const setIsLoadingDashboardFromApi = createAction(
  '[Dashboard] Set the loading from api state',
  props<{ isLoading: boolean }>()
);

// State
export const updateVisualization = createAction(
  '[Dashboard] Update visualization state',
  props<{ visualization: Partial<IRenderedVisualization> }>()
);

export const setIsDashboardModified = createAction(
  '[Dashboard] Set is dashboard modified',
  props<{ isModified: boolean }>()
);
