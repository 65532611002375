import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ApiUtilitiesService } from 'src/app/api/api-utilities.service';
import { NetworkCachedSubject } from 'src/app/core/models';
import { NeuOpenmindEventsService } from 'src/app/event/services/neu-event-categories';
import { ISavedCustomSources } from 'src/app/news-filters/filter/tree-filter-publishers/core/interfaces';
import {
  UpdateOpenMindMainArticle,
  UpdateRelatedOpenmindArticlesNewsItem
} from 'src/app/openmind/core/store-openmind/openmind.actions';
import { environment } from '../../../environments/environment';
import { selectUserId } from '../../auth/store-auth/auth.selectors';
import { FilterType } from '../../core/filters/core/enums';
import { IFilterLabel } from '../../core/filters/core/interfaces';
import { IAppState } from '../../core/store-app/reducers';
import {
  IQuickAccessSavedOrder,
  ISavedTopic
} from '../../ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';
import { IResearchCollection, IResearchCollectionData } from '../../research-collections/core/interfaces';
import { ICategoryPublisherAPI, INewsItem } from '../core/interfaces';
import { IApiTaggingResponse, ITagReadingListRequestModel } from '../news-tagging/interfaces';
import { SetQuickAccessTopicsState, UpdateCurrentNewsItem } from '../store-news/news.actions';
import { selectAllFilters } from '../store-news/news.selectors';

@Injectable({
  providedIn: 'root'
})
export class NewsDataService {
  private savedTopics$ = new NetworkCachedSubject<ISavedTopic[]>([]);

  constructor(
    private http: HttpClient,
    private store: Store<IAppState>,
    private apiUtilitiesService: ApiUtilitiesService,
    private neuOpenmindEventsService: NeuOpenmindEventsService
  ) {
    this.store.pipe(select(selectAllFilters)).subscribe({
      next: (allFilters: IFilterLabel[]) => {
        allFilters
          .filter((filter: IFilterLabel) => filter.type === FilterType.Category)
          .map((filter) => {
            this.apiUtilitiesService.addToCategoryLookupArray(filter);
          });
      }
    });
  }

  /**
   * Get Categories
   * @returns the array of categories
   */
  public getCategories(): Observable<IFilterLabel[]> {
    return this.http.get<ICategoryPublisherAPI>(`${environment.API_ENDPOINT}/categories`).pipe(
      map((apiCategories: ICategoryPublisherAPI) => {
        const categories: IFilterLabel[] = [];
        for (const apiCategory of apiCategories.facets) {
          if (apiCategory.type === 1) {
            categories.push({
              name: apiCategory.name,
              isActive: apiCategory.selected,
              icon: null,
              value: apiCategory.val,
              type: FilterType.Category,
              path: [],
              logo: null
            });
          }
        }

        return categories;
      })
    );
  }

  /**
   * This function changes the isReadingList property from a news
   * @param newsItem history to be changed to isReadinglist or viceversa
   * @param isReadingListSaved boolean that is going to decide wheter is a ReadingList or not
   * @returns updated news
   */

  public updateToggleReadingListStatus(newsItem: INewsItem, isReadingListSaved: boolean): void {
    this.neuOpenmindEventsService.catchSaveStoryToggle(newsItem, isReadingListSaved);

    this.store
      .pipe(
        select(selectUserId),
        switchMap((userId: string) => {
          const tagReadingListRequestModel: ITagReadingListRequestModel = {
            reading_list_tagged: isReadingListSaved,
            article_id: newsItem.id,
            user_id: userId,
            publisher_name: newsItem.publisher,
            neutrality_pubRSS_ID: newsItem.raw?.neutrality_pubRSS_ID,
            neutrality_article_normpubDate: newsItem.raw?.neutrality_article_normpubDate?.toString()
          };

          return this.tagReadingList(tagReadingListRequestModel);
        })
      )
      .pipe(take(1))
      .subscribe();
  }

  public toggleReadingList(newsItem: INewsItem, isReadingListSaved: boolean) {
    this.neuOpenmindEventsService.catchSaveStoryToggle(newsItem, isReadingListSaved);

    this.store.dispatch(
      UpdateCurrentNewsItem({
        newsItemData: { ...newsItem, isReadingListSaved }
      })
    );

    this.store.dispatch(
      UpdateRelatedOpenmindArticlesNewsItem({
        article: { ...newsItem, isReadingListSaved }
      })
    );

    this.store.dispatch(
      UpdateOpenMindMainArticle({
        mainArticleUpdate: {
          isReadingListSaved
        },
        onlyForId: newsItem.id
      })
    );

    // Execute
    return this.store.pipe(
      select(selectUserId),
      switchMap((userId: string) => {
        const tagReadingListRequestModel: ITagReadingListRequestModel = {
          reading_list_tagged: isReadingListSaved,
          article_id: newsItem.id,
          user_id: userId,
          publisher_name: newsItem.publisher,
          neutrality_pubRSS_ID: newsItem.raw.neutrality_pubRSS_ID,
          neutrality_article_normpubDate: newsItem.raw.neutrality_article_normpubDate.toString()
        };

        return this.tagReadingList(tagReadingListRequestModel);
      })
    );
  }

  public toggleOpenmindReadingList(newsItem: INewsItem, isReadingListSaved: boolean) {
    this.neuOpenmindEventsService.catchSaveStoryToggle(newsItem, isReadingListSaved);

    return this.store.pipe(
      select(selectUserId),
      switchMap((userId: string) => {
        const tagReadingListRequestModel: ITagReadingListRequestModel = {
          reading_list_tagged: isReadingListSaved,
          article_id: newsItem.id,
          user_id: userId,
          publisher_name: newsItem.publisher,
          neutrality_pubRSS_ID: newsItem.raw.neutrality_pubRSS_ID,
          neutrality_article_normpubDate: newsItem.raw.neutrality_article_normpubDate.toString()
        };

        return this.tagReadingList(tagReadingListRequestModel);
      })
    );
  }

  public saveTopicsObservable(newSavedTopics: ISavedTopic[]): Observable<boolean> {
    return this.http
      .post(`${environment.API_ENDPOINT}/user-storage/saved-topics`, {
        saved_topics: newSavedTopics
      })
      .pipe(
        map(() => {
          this.savedTopics$.subject$.next(newSavedTopics);

          return true;
        })
      );
  }

  public saveTopics(newSavedTopics: ISavedTopic[]): void {
    this.saveTopicsObservable(newSavedTopics).pipe(take(1)).subscribe();
  }

  public getSavedTopics(forceRequest = false): Observable<ISavedTopic[]> {
    const savedTopicsRequest = this.http.get<ISavedTopic[]>(`${environment.API_ENDPOINT}/user-storage/saved-topics`);

    return this.savedTopics$.next(savedTopicsRequest, forceRequest).pipe(
      map((topicsToManipulate: ISavedTopic[]) => {
        const topics: ISavedTopic[] = [...topicsToManipulate];

        if (topics.length > 0) {
          const quickAccessState: IQuickAccessSavedOrder = (<unknown>topics[0]) as IQuickAccessSavedOrder;

          if (quickAccessState.isQuickAccessSavedOrder) {
            // Using new api logic
            this.store.dispatch(SetQuickAccessTopicsState({ topics: quickAccessState.quickAccessSavedState }));
          } else {
            // Not with new api logic
          }
        }

        return topics.filter(
          (topic: ISavedTopic) => !((<unknown>topic) as IQuickAccessSavedOrder).isQuickAccessSavedOrder
        );
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  public saveCollections(newResearchCollections: IResearchCollection[]): void {
    this.http
      .post(`${environment.API_ENDPOINT}/user-collections/saved-collections`, {
        saved_collections: newResearchCollections
      })
      .pipe(take(1))
      .subscribe({ next: () => {} });
  }

  public getSavedCollections(): Observable<IResearchCollectionData> {
    return this.http
      .get<IResearchCollectionData>(`${environment.API_ENDPOINT}/user-collections/saved-collections`)
      .pipe(
        catchError(() => {
          return of(<IResearchCollectionData>{
            id: 'error',
            savedCollections: []
          });
        })
      );
  }

  public saveCustomSources(newCustomSources: ISavedCustomSources[]): void {
    this.http
      .post(`${environment.API_ENDPOINT}/user-storage/custom-sources`, {
        customSources: newCustomSources
      })
      .pipe(take(1))
      .subscribe();
  }

  public getCustomSources(): Observable<ISavedCustomSources[]> {
    return this.http.get<ISavedCustomSources[]>(`${environment.API_ENDPOINT}/user-storage/custom-sources`).pipe(
      catchError(() => {
        return of([]);
      })
    );
  }

  private tagReadingList(tagReadingListRequestModel: ITagReadingListRequestModel): Observable<IApiTaggingResponse> {
    return this.http.post<IApiTaggingResponse>(
      `${environment.API_ENDPOINT}/news/tag/reading-list`,
      tagReadingListRequestModel
    );
  }
}
