import { Observable, of, tap } from 'rxjs';

/*
 * this class is used for cache a observable/network-request that is called with different params
 * we associate each observable to a provided key and the cached result
 * is returned to the successive subscriptions
 */

export class CachedObservable<T> {
  private cache: Map<string, T>;

  constructor() {
    this.cache = new Map<string, T>();
  }

  public next(key: string, observableData: Observable<T>) {
    if (this.cache.has(key)) {
      return of(this.cache.get(key));
    }

    return observableData.pipe(
      tap((response: T) => {
        this.cache.set(key, response);
      })
    );
  }
}
