import { createReducer, on } from '@ngrx/store';
import * as CustomSourcesTypes from './custom-sources.actions';

export const customSourcesFeatureKey = 'customSourcesState';

export interface ICustomSourcesState {
  isDraggingSourceChip: boolean;
}

export const customSourcesInitialState: ICustomSourcesState = {
  isDraggingSourceChip: false
};

export const customSourcesReducer = createReducer(
  customSourcesInitialState,
  on(CustomSourcesTypes.setIsDraggingCustomSources, (state: ICustomSourcesState, action) => {
    return { ...state, isDraggingSourceChip: action.isDragging };
  })
);
