import { IHistogram } from './interfaces';

export function makeHistogram(X: number[], numberOfBins = 5): IHistogram[] {
  if (!X?.length) {
    return [];
  }

  const max = Math.max(...X);
  const min = Math.min(...X);
  const len = max - min;

  const binRange = len / numberOfBins;
  const bins: number[] = new Array(numberOfBins).fill(0);
  const binsSum: number[] = new Array(numberOfBins).fill(0);
  const binsSumSquare: number[] = new Array(numberOfBins).fill(0);
  const binsMin: number[] = new Array(numberOfBins).fill(max);
  const binsMax: number[] = new Array(numberOfBins).fill(min);

  for (const x of X) {
    const binBucket: number = Math.min(numberOfBins - 1, Math.floor((x - min) / binRange));

    bins[binBucket] += 1;
    binsSum[binBucket] += x;
    binsSumSquare[binBucket] += x * x;
    binsMin[binBucket] = Math.min(binsMin[binBucket], x);
    binsMax[binBucket] = Math.max(binsMax[binBucket], x);
  }

  const histogramData: IHistogram[] = bins.map((bin: number, index: number) => {
    const mean: number = binsSum[index] / bin;
    const variance = (binsSumSquare[index] - 2 * mean + mean * mean) / X.length;

    const histogram: IHistogram = {
      max: binsMax[index],
      min: binsMin[index],
      N: bin,
      mean,
      var: variance,
      sum: binsSum[index]
    };

    return histogram;
  });

  return histogramData;
}
