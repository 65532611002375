import { Injectable } from '@angular/core';
import { IFilterLabel } from 'src/app/core/filters/core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class FilterSorterService {
  /**
   * This function sorts by name and also moves the inactive filter to the end of the array.
   * @param filtersData filter data we want to sort
   * @returns finalSortedList sorted filters
   */
  public sortSelectedFirst(filtersData: IFilterLabel[]): IFilterLabel[] {
    const finalSortedList: IFilterLabel[] = [];
    filtersData.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    for (const filter of filtersData) {
      if (filter.isActive) {
        finalSortedList.unshift(filter);
      } else {
        finalSortedList.push(filter);
      }
    }

    return finalSortedList;
  }
}
