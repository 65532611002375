import { createReducer, on } from '@ngrx/store';
import { FilterType, RequestedFilterAnimationType } from '../core/enums';
import { IFilter, IRequestedFilterAnimation } from '../core/interfaces';
import * as FiltersActionTypes from './filters.actions';

export const filtersFeatureKey = 'filtersFeatureKey';

export interface IFiltersState {
  activeKeywordFilters: IFilter[];
  activeKeywordForQueryFilters: IFilter[];
  availableKeywords: IFilter[];
  categoryFilters: string[];
  isLoadingKeywords: boolean;

  // Animation
  requestedAnimation: IRequestedFilterAnimation;

  activeSentimentFilters: IFilter[];
  activeRelevancyFilters: IFilter[];
}

export const filtersInitialState: IFiltersState = {
  activeKeywordFilters: [],
  activeKeywordForQueryFilters: [],
  availableKeywords: [],
  categoryFilters: [],
  isLoadingKeywords: true,
  requestedAnimation: {
    animationType: RequestedFilterAnimationType.None,
    iteration: 0
  },
  activeSentimentFilters: [],
  activeRelevancyFilters: []
};

export const filtersReducer = createReducer(
  filtersInitialState,
  // Setters
  on(FiltersActionTypes.setCategoryFilter, (state: IFiltersState, action) => {
    return { ...state, categoryFilters: action.categoryFilters };
  }),
  on(FiltersActionTypes.setActiveKeywordFilters, (state: IFiltersState, action) => {
    return { ...state, activeKeywordFilters: action.filters };
  }),
  on(FiltersActionTypes.setAvailableKeywords, (state: IFiltersState, action) => {
    const keywords: IFilter[] = action.keywords;

    // TODO: remove when we do not use simple keywords anymore
    // use simple keywords for now
    /* keywords = keywords.filter((keyword: IFilter) => {
      const hasSpaces = keyword.value.split(' ').length > 1;
      const hasDashes = keyword.value.split('-').length > 1;
      const maxLengthWithDashes = 12;

      return !hasSpaces && (!hasDashes || keyword.value.length <= maxLengthWithDashes);
    }); */

    return { ...state, availableKeywords: keywords };
  }),
  on(FiltersActionTypes.setIsLoadingKeywords, (state: IFiltersState, action) => {
    return { ...state, isLoadingKeywords: action.isLoading };
  }),
  // ToggleState
  on(FiltersActionTypes.toggleActiveKeywordFilter, (state: IFiltersState, action) => {
    let willBeActive: boolean = action.isActive;
    let newActiveKeywords = [];

    const isKeywordPresent = !!state.activeKeywordFilters.find(
      (keyword: IFilter) => keyword.value === action.keyword.value
    );

    if (willBeActive === undefined) {
      willBeActive = !isKeywordPresent;
    }

    newActiveKeywords = state.activeKeywordFilters;

    if (isKeywordPresent && !willBeActive) {
      newActiveKeywords = newActiveKeywords.filter((keyword: IFilter) => keyword.value !== action.keyword.value);
    }

    if (!isKeywordPresent && willBeActive) {
      newActiveKeywords = [...newActiveKeywords, action.keyword];
    }

    return { ...state, activeKeywordFilters: newActiveKeywords };
  }),
  // Remove
  on(FiltersActionTypes.removeActiveFilter, (state: IFiltersState, action) => {
    const newFilters: IFilter[] = state.activeKeywordFilters.filter((filter: IFilter) => {
      if (filter.type === action.filter.type) {
        if (filter.value === action.filter.value) {
          return false;
        }
      }

      return true;
    });

    return { ...state, activeKeywordFilters: newFilters };
  }),
  on(FiltersActionTypes.addActiveFilter, (state: IFiltersState, action) => {
    return { ...state, activeKeywordFilters: [...state.activeKeywordFilters, action.filter] };
  }),
  on(FiltersActionTypes.clearAllKeywordsFilters, (state: IFiltersState) => {
    const newFilters: IFilter[] = state.activeKeywordFilters.filter((filter: IFilter) => {
      return filter.type !== FilterType.Keywords;
    });

    return { ...state, activeKeywordFilters: newFilters };
  }),
  on(FiltersActionTypes.addMultipleActiveFilter, (state: IFiltersState, action) => {
    const newFilters: IFilter[] = [...state.activeKeywordFilters, ...action.filters];

    return { ...state, activeKeywordFilters: newFilters };
  }),
  on(FiltersActionTypes.requestFilterAnimation, (state: IFiltersState, action) => {
    const newAnimation: IRequestedFilterAnimation = {
      animationType: action.animationType,
      iteration: state.requestedAnimation.iteration + 1
    };

    return { ...state, requestedAnimation: newAnimation };
  }),
  // Keywords for query
  on(FiltersActionTypes.toggleKeywordForQueryFilter, (state: IFiltersState, action) => {
    let willBeActive: boolean = action.isActive;
    let newActiveKeywordsForQuery = [];

    const isKeywordPresent = !!state.activeKeywordForQueryFilters.find(
      (keyword: IFilter) => keyword.value === action.keyword.value
    );

    if (willBeActive === undefined) {
      willBeActive = !isKeywordPresent;
    }

    newActiveKeywordsForQuery = state.activeKeywordForQueryFilters;

    if (isKeywordPresent && !willBeActive) {
      newActiveKeywordsForQuery = newActiveKeywordsForQuery.filter(
        (keyword: IFilter) => keyword.value !== action.keyword.value
      );
    }

    if (!isKeywordPresent && willBeActive) {
      newActiveKeywordsForQuery = [...newActiveKeywordsForQuery, action.keyword];
    }

    return { ...state, activeKeywordForQueryFilters: newActiveKeywordsForQuery };
  }),
  // Sentiment filters
  on(FiltersActionTypes.setSentimentFilters, (state, action) => {
    return { ...state, activeSentimentFilters: action.sentimentFilters };
  }),
  on(FiltersActionTypes.addRemoveSentimentFilters, (state, action) => {
    const activeSentimentFilters: IFilter[] = [...state.activeSentimentFilters];
    const activeFilterIndex: number = activeSentimentFilters.findIndex(
      (activeFilter: IFilter) => activeFilter?.value === action.sentimentFilter.value
    );

    if (activeFilterIndex > -1) {
      activeSentimentFilters.splice(activeFilterIndex, 1);

      return { ...state, activeSentimentFilters };
    }

    return { ...state, activeSentimentFilters: [...activeSentimentFilters, action.sentimentFilter] };
  }),
  // Relevancy Filters
  on(FiltersActionTypes.toggleRelevancyFilterByType, (state, action) => {
    const newFilters = state.activeRelevancyFilters.filter((filter: IFilter) => {
      if (filter.value === (action.relevancyFilterType as string)) {
        if (!action.shouldBeActive) {
          return false;
        }
      }

      return true;
    });

    return { ...state, activeRelevancyFilters: newFilters };
  }),
  on(FiltersActionTypes.setActiveRelevancyFilters, (state, action) => {
    return { ...state, activeRelevancyFilters: action.relevancyFilters };
  }),
  on(FiltersActionTypes.addRemoveRelevancyFilter, (state, action) => {
    const activeRelevancyFilters: IFilter[] = [...state.activeRelevancyFilters];
    const activeFilterIndex: number = activeRelevancyFilters.findIndex(
      (activeFilter: IFilter) => activeFilter?.value === action.relevancyFilter.value
    );

    if (activeFilterIndex > -1) {
      activeRelevancyFilters.splice(activeFilterIndex, 1);

      return { ...state, activeRelevancyFilters };
    }

    return { ...state, activeRelevancyFilters: [...activeRelevancyFilters, action.relevancyFilter] };
  })
);
