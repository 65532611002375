import { createAction, props } from '@ngrx/store';
import { IDatasetTuneupData } from '../core/api-interfaces';
import { IApiSentimentRelevancyBucket } from '../core/interfaces';

// ------------------- Main ------------------- //

export const setCurrentDatasetTuneupData = createAction(
  '[apiNews] Set current dataset tuneup data',
  props<{ tuneupData: IDatasetTuneupData }>()
);

export const setSentimentTuneupData = createAction(
  '[apiNews] Set current sentiment tuneup data',
  props<{ sentimentTuneup: IApiSentimentRelevancyBucket[] }>()
);

export const setRelevancyTuneupData = createAction(
  '[apiNews] Set current relevancy tuneup data',
  props<{ relevancyTuneup: IApiSentimentRelevancyBucket[] }>()
);

export const updateCurrentDatasetTuneupData = createAction(
  '[apiNews] Update current dataset tuneup data',
  props<{ tuneupData: Partial<IDatasetTuneupData> }>()
);

// Loaders
export const setIsLoadingTuneupData = createAction(
  '[apiNews] Set is loading tuneup data',
  props<{ isLoading: boolean }>()
);

export const setIsLoadingTuneupSources = createAction(
  '[apiNews] Set is loading sources',
  props<{ isLoading: boolean }>()
);
