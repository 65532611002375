import { createReducer, on } from '@ngrx/store';
import * as NewsTagsTypes from './news-tags.actions';

export const newsTagsFeatureKey = 'newsTagsState';

export interface INewsTagsState {
  filters: string[];
}

export const newsTagsInitialState: INewsTagsState = {
  filters: []
};

export const newsTagsReducer = createReducer(
  newsTagsInitialState,
  on(NewsTagsTypes.toggleNewsTaggingFilter, (state: INewsTagsState, action) => {
    let newFilters: string[] = [...state.filters];
    const isFilterPresent = !!newFilters.find((filterName: string) => filterName === action.filter);

    let willBeActive = action.isActive;

    if (willBeActive === undefined) {
      if (isFilterPresent) {
        // Remove
        willBeActive = false;
      } else {
        // Add
        willBeActive = true;
      }
    }

    if (willBeActive && !isFilterPresent) {
      newFilters = [...newFilters, action.filter];
    }

    if (!willBeActive && isFilterPresent) {
      newFilters = newFilters.filter((filterValue: string) => filterValue !== action.filter);
    }

    return { ...state, filters: newFilters };
  }),
  on(NewsTagsTypes.setNewsTaggingFilters, (state: INewsTagsState, action) => {
    return { ...state, filters: action.filters };
  })
);
