/* eslint-disable no-undef */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { KeycloakAuthGuard, KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions } from 'keycloak-js';
import { filter, firstValueFrom, fromEvent, map, take } from 'rxjs';
import { IKeycloakUser } from 'src/app/auth/interfaces';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SetUser } from 'src/app/auth/store-auth/auth.actions';
import { IProfileDetails } from 'src/app/profile/core/interfaces/profile-details.interface';
import { ProfileService } from 'src/app/profile/profile.service';
import { authStatusKey } from '../../constants/auth-status-key';
import { GeneralApplicationRoutes, MainApplicationRoutes } from '../../enums';
import { getGeneralAppRoute } from '../../utils/get-app-route';
import { SavedTopicsService } from '../../filters/saved-topics/saved-topics.service';
import { ISavedTopic } from 'src/app/ui-kits/neu-ui-kit/components/saved-topic-picker-selector/interfaces';

@Injectable({
  providedIn: 'root'
})
export class KeycloakGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private store: Store,
    private keycloakService: KeycloakService,
    private authService: AuthService,
    private savedTopicsService: SavedTopicsService,
    private profileService: ProfileService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(router, keycloak);

    this.authStatusSub();
    this.keycloakEventsSub();
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      const redirectUrl = `${window.location.origin}/${MainApplicationRoutes.Dashboard}`;
      const loginOptions: KeycloakLoginOptions = {
        redirectUri: redirectUrl,
        prompt: 'login'
      };

      await this.keycloakService.login(loginOptions);

      return false;
    }

    this.authService.user$.next(
      (<any>await this.keycloakService.getKeycloakInstance().loadUserInfo()) as IKeycloakUser
    );

    // Set the user in the store
    await this.setUserInStore();

    const appRoute: GeneralApplicationRoutes = getGeneralAppRoute(route.url.toString());

    const currentUserSavedTopics: ISavedTopic[] = await this.savedTopicsService.requestSavedTopicsFromAPI();
    const shouldRedirectToCategories = currentUserSavedTopics.length === 0;

    if (shouldRedirectToCategories && appRoute === GeneralApplicationRoutes.UserCategories) {
      return true;
    }

    if (shouldRedirectToCategories) {
      return this.router.parseUrl(`/${GeneralApplicationRoutes.UserCategories}`);
    }

    // Role based protection
    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    const isAllowedToEnter: boolean = requiredRoles.every((role: string) => this.roles.includes(role));

    if (!isAllowedToEnter) {
      this.router.navigate(['/status/c403']);
    }

    return isAllowedToEnter;
  }

  private async setUserInStore(): Promise<void> {
    const parsedToken: Keycloak.KeycloakTokenParsed = this.keycloakService.getKeycloakInstance().tokenParsed;
    const keycloakUserId: string = parsedToken.sub;
    const keycloakUserProfile: Keycloak.KeycloakProfile = await this.keycloakService.loadUserProfile();

    this.setAuthStatusKey(true);

    this.store.dispatch(
      new SetUser({
        user: {
          userId: keycloakUserId,
          email: keycloakUserProfile.email
        }
      })
    );
  }

  private setAuthStatusKey(status: boolean): void {
    localStorage.setItem(authStatusKey, JSON.stringify(status));
  }

  private keycloakEventsSub() {
    let isProcessing = false;
    this.keycloakService.keycloakEvents$.pipe().subscribe({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: async (keycloakEvent: KeycloakEvent) => {
        const shouldShowExpiredError: boolean = [
          KeycloakEventType.OnAuthRefreshError,
          KeycloakEventType.OnAuthError,
          KeycloakEventType.OnTokenExpired
        ].includes(keycloakEvent.type);

        if (!shouldShowExpiredError || isProcessing) {
          return;
        }

        isProcessing = true;

        const sessionTimedOutErrorMessage = 'Session timed out';

        if (this.keycloakService.isTokenExpired()) {
          try {
            await this.keycloakService.updateToken();
            if (!this.keycloakService.isTokenExpired()) {
              isProcessing = false;

              return;
            }
          } catch (error) {
            console.error(error);
            this.keycloakService.clearToken();
          }
        }

        isProcessing = false;
        const redirectUrl = `${window.location.origin}/${MainApplicationRoutes.Dashboard}`;
        const loginOptions: KeycloakLoginOptions = {
          redirectUri: redirectUrl + '?' + encodeURIComponent(`kc_generated_error="${sessionTimedOutErrorMessage}"`),
          prompt: 'login'
        };

        await this.keycloakService.login(loginOptions);
      }
    });
  }

  private authStatusSub() {
    fromEvent<StorageEvent>(this.document.defaultView, 'storage')
      .pipe(
        filter((event) => event.storageArea === localStorage),
        filter((event) => event.key === authStatusKey),
        map((event) =>
          event.newValue !== null && event.newValue !== undefined ? JSON.parse(event.newValue) : event.newValue
        )
      )
      .subscribe({
        next: (isLogIn: string) => {
          if (!isLogIn) {
            this.keycloakService.logout().then(() => this.keycloakService.clearToken());
          }
        }
      });
  }
}
