import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { notFoundRoute } from './core/constants/not-found';
import { GeneralApplicationRoutes } from './core/enums';
import { KeycloakGuard } from './core/keycloak/guards/keycloak.guard';

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login.component').then((m) => m.LoginComponent)
  },
  {
    path: 'signup',
    loadComponent: () => import('./auth/register/register.component').then((m) => m.RegisterComponent)
  },
  {
    path: GeneralApplicationRoutes.Public,
    loadChildren: () => import('./public-views/public-views.module').then((m) => m.PublicViewsModule)
  },
  {
    path: `${GeneralApplicationRoutes.SharedPlots}/:id`,
    loadChildren: () => import('./shared-plots/shared-plots.module').then((m) => m.SharedPlotsModule)
  },
  {
    path: `${GeneralApplicationRoutes.SharedDashboard}/:id`,
    loadChildren: () =>
      import('./dashboard/shared-dashboard/shared-dashboard.module').then((m) => m.SharedDashboardModule)
  },
  {
    path: GeneralApplicationRoutes.AdditionalUserInfo,
    loadChildren: () =>
      import('./additional-user-info/additional-user-info.module').then((m) => m.AdditionalUserInfoModule),
    canActivate: [KeycloakGuard]
  },
  {
    path: GeneralApplicationRoutes.UserCategories,
    loadChildren: () => import('./user-categories/user-categories.module').then((m) => m.UserCategoriesModule),
    canActivate: [KeycloakGuard]
  },
  {
    path: 'neu-session-event-filters',
    loadChildren: () => import('./neu-event-filters/neu-event-filters.module').then((m) => m.NeuEventFiltersModule),
    canActivate: [KeycloakGuard]
  },
  {
    path: '',
    loadChildren: () => import('./neutrality-root/neutrality-root.module').then((m) => m.NeutralityRootModule),
    canActivate: [KeycloakGuard]
  },
  {
    path: '**',
    redirectTo: notFoundRoute
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
