import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { apiNewsFeatureKey, apiNewsReducer } from '../api/store/api-news-state.reducer';
import { authReducer, authStateFeatureKey } from '../auth/store-auth/auth-state.reducer';
import { newsTagsFeatureKey, newsTagsReducer } from '../news/news-tagging/news-tags-store/news-tags-state.reducer';
import { filtersFeatureKey, filtersReducer } from './filters/filters-store/filters.reducer';
import {
  sourcesFiltersFeatureKey,
  sourcesFiltersReducer
} from './filters/sources/sources-filters-store/sources-filters-state.reducer';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NetworkErrorInterceptor } from './interceptors/network-error.interceptor';
import { CustomSerializer } from './store-app/custom-route-serializer';
import { metaReducers, reducers } from './store-app/reducers';
import { newsMetaReducers, newsReducer, newsStateFeatureKey } from '../news/store-news/news-state.reducer';
import {
  customSourcesFeatureKey,
  customSourcesReducer
  // eslint-disable-next-line max-len
} from '../ui-kits/neu-ui-kit/components/right-sidebar/shared-filters/filter-modal-custom-sources/custom-sources-store/custom-sources-state.reducer';
import { newsUiFeatureKey, newsUiReducer } from '../news/news-ui-store/news-ui-state.reducer';
import { eventReducer, searchSessionEventFeatureKey } from '../event/store/store-events/event.reducer';
import {
  openmindEventReducer,
  openmindSessionEventFeatureKey
} from '../event/store/store-openmind-event/openmind-event.reducer';
import {
  profileEventReducer,
  profileSessionEventFeatureKey
} from '../event/store/store-profile-event/profile-event-reducer';
import {
  readingListEventReducer,
  readingListSessionEventFeatureKey
} from '../event/store/store-reading-list-event/reading-list-event-reducer';
import {
  trendingEventFeatureKey,
  trendingEventReducer
} from '../event/store/store-analytic-trending-event/trending-event.reducer';
import {
  analyticSearchEventFeatureKey,
  analyticSearchEventReducer
} from '../event/store/store-analytic-search-event/analytic-search-event.reducer';
import {
  dashboardEventFeatureKey,
  dashboardEventReducer
} from '../event/store/store-dashboard-event/dashboard-event.reducer';
import { NewsEffects } from '../news/store-news/news.effects';
import {
  responsiveMetaReducers,
  responsiveReducer,
  responsiveStateFeatureKey
} from '../neutrality-root/store/responsive.reducer';
import {
  newsManagementFeatureKey,
  newsManagementReducer
} from '../news/store-news-management/news-management-state.reducer';
import { dashboardReducer, dashboardStateFeatureKey } from '../dashboard/store-dashboard/dashboard-state.reducer';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forFeature(authStateFeatureKey, authReducer),
    StoreModule.forFeature(filtersFeatureKey, filtersReducer),
    StoreModule.forFeature(sourcesFiltersFeatureKey, sourcesFiltersReducer),
    StoreModule.forFeature(newsTagsFeatureKey, newsTagsReducer),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }
    }),
    StoreModule.forFeature(apiNewsFeatureKey, apiNewsReducer),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25, // Returns last 25 states
          logOnly: environment.production // Boolean. Restricts to log only depending on env
        })
      : [],
    StoreRouterConnectingModule.forRoot({
      // serializer: DefaultRouterStateSerializer,
      stateKey: 'router'
    }),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(newsStateFeatureKey, newsReducer, { metaReducers: newsMetaReducers }),
    StoreModule.forFeature(customSourcesFeatureKey, customSourcesReducer),
    StoreModule.forFeature(newsUiFeatureKey, newsUiReducer),
    StoreModule.forFeature(searchSessionEventFeatureKey, eventReducer),
    StoreModule.forFeature(openmindSessionEventFeatureKey, openmindEventReducer),
    StoreModule.forFeature(profileSessionEventFeatureKey, profileEventReducer),
    StoreModule.forFeature(readingListSessionEventFeatureKey, readingListEventReducer),
    StoreModule.forFeature(trendingEventFeatureKey, trendingEventReducer),
    StoreModule.forFeature(analyticSearchEventFeatureKey, analyticSearchEventReducer),
    StoreModule.forFeature(dashboardEventFeatureKey, dashboardEventReducer),
    StoreModule.forFeature(responsiveStateFeatureKey, responsiveReducer, {
      metaReducers: responsiveMetaReducers
    }),
    StoreModule.forFeature(newsManagementFeatureKey, newsManagementReducer),
    StoreModule.forFeature(dashboardStateFeatureKey, dashboardReducer),
    EffectsModule.forFeature([NewsEffects])
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NetworkErrorInterceptor, multi: true }
  ]
})
export class CoreModule {}
