import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { RoleType } from 'src/app/core/enums';

/**
 * This directive will work as an ngIf but with given roles
 * Only those with the given role will be able to see the content
 * Use as:
 *  <div *neuForRole="[ROLES]">
      // Content
    </div>
 */
@Directive({
  selector: '[neuForRole]'
})
export class RoleDirective implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private keycloakService: KeycloakService,
    private viewContainer: ViewContainerRef
  ) {}

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @Input()
  public set neuForRole(roles: RoleType[]) {
    const userRoles: RoleType[] = this.keycloakService.getUserRoles() as any;

    let isRoleInList = false;

    for (const role of roles) {
      if (userRoles.includes(role)) {
        isRoleInList = true;
        break;
      }
    }

    if (isRoleInList) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
