import { IDatasetTuneupPublisher, IPublisherMetadata } from 'src/app/api/core/api-interfaces';
import { IApiRssDetails } from 'src/app/api/core/interfaces';
import { secureObjectCopy } from 'src/app/core/utils';
import { INewsItem } from 'src/app/news/core/interfaces';

/**
 * This calls compute incrementally from INewsItem the data necessary to generate
 * the publishers tuneups filters
 */

export class PublisherHandler {
  private publishersArticlesCount: Map<string, number>;
  private publishersRSS: Map<string, Set<string>>;
  private publishersArticlesRSSCount: Map<string, number>;
  private getPublisherMetadata: (publisherName: string) => IPublisherMetadata;

  constructor(getPublisherMetadata: (publisherName: string) => IPublisherMetadata) {
    this.publishersArticlesCount = new Map<string, number>();
    this.publishersRSS = new Map<string, Set<string>>();
    this.publishersArticlesRSSCount = new Map<string, number>();
    this.getPublisherMetadata = getPublisherMetadata;
  }

  public update(articles: INewsItem[]) {
    for (const article of articles) {
      this.push(article);
    }
  }

  public push_publisher(publisher: IPublisherMetadata) {
    if (this.publishersArticlesCount.has(publisher.name)) {
      this.publishersArticlesCount.set(
        publisher.name,
        this.publishersArticlesCount.get(publisher.name) + publisher.datasetTuneupPublisher.article_count
      );

      if (publisher.RSSmap) {
        this.publishersRSS.get(publisher.name).add(publisher.RSSmap);
      }
    } else {
      this.publishersArticlesCount.set(publisher.name, publisher.datasetTuneupPublisher.article_count);
      this.publishersRSS.set(publisher.name, new Set<string>([publisher.RSSmap]));
    }

    if (!publisher.RSSmap) {
      return;
    }

    const publisherRssKey = `${publisher.name}@${publisher.RSSmap}`;
    if (this.publishersArticlesRSSCount.has(publisherRssKey)) {
      this.publishersArticlesRSSCount.set(
        publisherRssKey,
        this.publishersArticlesRSSCount.get(publisherRssKey) + publisher.datasetTuneupPublisher.article_count
      );
    } else {
      this.publishersArticlesRSSCount.set(publisherRssKey, publisher.datasetTuneupPublisher.article_count);
    }
  }

  public push(article: INewsItem) {
    if (this.publishersArticlesCount.has(article.publisher)) {
      this.publishersArticlesCount.set(article.publisher, this.publishersArticlesCount.get(article.publisher) + 1);
      this.publishersRSS.get(article.publisher).add(article.raw.RSSmap);
    } else {
      this.publishersArticlesCount.set(article.publisher, 1);
      this.publishersRSS.set(article.publisher, new Set<string>());
    }

    const publisherRssKey = `${article.publisher}@${article.raw.RSSmap}`;
    if (this.publishersArticlesRSSCount.has(publisherRssKey)) {
      this.publishersArticlesRSSCount.set(publisherRssKey, this.publishersArticlesRSSCount.get(publisherRssKey) + 1);
    } else {
      this.publishersArticlesRSSCount.set(publisherRssKey, 1);
    }
  }

  public get publishers(): IDatasetTuneupPublisher[] {
    return this.buildPublishers(this.publishersArticlesCount, this.publishersRSS);
  }

  public get publisherRss(): Record<string, IApiRssDetails[]> {
    return this.buildPublisherRss(this.publishersRSS, this.publishersArticlesRSSCount);
  }

  private buildPublishers(
    publishersArticlesCount: Map<string, number>,
    publishersRSS: Map<string, Set<string>>
  ): IDatasetTuneupPublisher[] {
    let publishers: IDatasetTuneupPublisher[] = [];
    const publisherMap = new Map<string, IDatasetTuneupPublisher>();

    for (const publisherName of publishersArticlesCount.keys()) {
      if (!this.getPublisherMetadata(publisherName)) {
        console.warn('unknown publisher', publisherName);
        continue;
      }

      const publisher: IDatasetTuneupPublisher = secureObjectCopy(
        this.getPublisherMetadata(publisherName).datasetTuneupPublisher
      );

      delete publisher.RSSmap;

      const publisherRss: Set<string> = publishersRSS.get(publisherName);
      publisher.metadata = publisherRss;

      publisher.article_count = publishersArticlesCount.get(publisherName);
      publisher.rssmap_count = [...publisherRss].length;

      if (publisherMap.has(publisherName)) {
        const savedPublisher = publisherMap.get(publisherName);
        savedPublisher.metadata = new Set([...(<[]>savedPublisher.metadata), ...publisherRss]);
        savedPublisher.rssmap_count = [...(<[]>savedPublisher.metadata)].length;
        savedPublisher.article_count += publisher.article_count;

        publisherMap.set(publisherName, savedPublisher);
      } else {
        publisherMap.set(publisherName, publisher);
      }
    }

    publishers = [...publisherMap.values()];
    publishers.sort(
      (publisher1: IDatasetTuneupPublisher, publisher2: IDatasetTuneupPublisher) =>
        publisher2.article_count - publisher1.article_count
    );

    return publishers;
  }

  private buildPublisherRss(
    publishersRSS: Map<string, Set<string>>,
    publishersArticlesRSSCount: Map<string, number>
  ): Record<string, IApiRssDetails[]> {
    const publishersRss: Record<string, IApiRssDetails[]> = {};

    for (const publisher of publishersRSS.keys()) {
      const rssDetails: IApiRssDetails[] = [...publishersRSS.get(publisher)].map((rss: string) => {
        const rssDetail: IApiRssDetails = {
          article_count: publishersArticlesRSSCount.get(`${publisher}@${rss}`) || 0,
          publisher_name: [publisher],
          RSSmap: [rss]
        };

        return rssDetail;
      });

      rssDetails.sort(
        (details1: IApiRssDetails, details2: IApiRssDetails) => details2.article_count - details1.article_count
      );

      publishersRss[publisher] = rssDetails;
    }

    return publishersRss;
  }
}
