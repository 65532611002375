export enum TutorialPositioningId {
  AnalyticsRolloutID = 'analytics-rollout',
  NeuRolloutcontentID = 'neu-rollout-content',
  Joyridestep2ID = 'joyride-step-step2',
  Joyridestep3ID = 'joyride-step-step3',
  Joyridestep4ID = 'joyride-step-step4',
  Joyridestep5ID = 'joyride-step-step5',
  Joyridestep6ID = 'joyride-step-step6',
  Joyridestep7ID = 'joyride-step-step7'
}
