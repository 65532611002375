export const removeObjectEmptyFields = (capturedObject: any, isRemoveFalse?: boolean) => {
  const cloneCapturedObject: object = { ...capturedObject };
  Object.keys(cloneCapturedObject).forEach((fieldName: string) => {
    if (
      cloneCapturedObject[fieldName] === null ||
      cloneCapturedObject[fieldName] === '' ||
      cloneCapturedObject[fieldName] === undefined
    ) {
      delete cloneCapturedObject[fieldName];
    }

    if (isRemoveFalse && cloneCapturedObject[fieldName] === false) {
      delete cloneCapturedObject[fieldName];
    }

    if (Array.isArray(cloneCapturedObject[fieldName]) && cloneCapturedObject[fieldName].length === 0) {
      delete cloneCapturedObject[fieldName];
    }
  });

  return cloneCapturedObject;
};
