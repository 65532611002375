import { Injectable } from '@angular/core';
import { ApiUtilitiesService } from 'src/app/api/api-utilities.service';
import { IDateFilter } from 'src/app/news-filters/core/interfaces';
import { ITrendingTopicForFilter } from 'src/app/api/core/api-interfaces';
import { DEFAULT_NEWS_QUERY } from '../constants/default-query';
import { cleanAndAdjustQuery } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class QueryFiltersService {
  constructor(private apiUtilitiesService: ApiUtilitiesService) {}

  public buildQuery(query: string, trendingTopicFiltersForNews: ITrendingTopicForFilter[] = []): string {
    let returnQuery = '';

    // Empty query should bring all results
    if (query === '' || query === '*') {
      query = DEFAULT_NEWS_QUERY;
    }

    const checkQueryForSingleQuotes: string = query.replace(/'/g, "\\'");

    returnQuery = checkQueryForSingleQuotes;

    if (trendingTopicFiltersForNews.length) {
      const trendingTopicsQuerySection: string = this.generateCleanQueryForTrendingTopic(trendingTopicFiltersForNews);

      // If the query includes * then remove it
      if (returnQuery === '*') {
        // Replace query
        returnQuery = trendingTopicsQuerySection;
      } else {
        // Append to existing query
        returnQuery += ' AND ' + trendingTopicsQuerySection;
      }
    }

    return returnQuery;
  }

  public buildFilters(plainFilters: string[], dateFilters: IDateFilter): string[] {
    return [...this.getDefaultAndDateFilters(plainFilters, dateFilters)];
  }

  public generateFilterString(filterName: string, filters: string[], join = 'OR') {
    if (!filters || !filters.length) {
      return '';
    }

    let filtersQueryStr = '';

    // description section
    let descriptionSection = `${filterName}:("${filters.join(`" ${join} "`)}")`;
    descriptionSection = cleanAndAdjustQuery(descriptionSection);

    filtersQueryStr = `+${descriptionSection}`;

    return filtersQueryStr;
  }

  private getDefaultAndDateFilters(plainFilters: string[], dateFilters: IDateFilter): string[] {
    let filtersToReturn: string[] = [];

    // Default filters
    const ReadingListFilter: string[] = plainFilters.filter((aFilter) => aFilter.startsWith('{!join'));

    // If top-story or reading-list, show ALL time ignoring date filter
    if (ReadingListFilter.length) {
      filtersToReturn = [...filtersToReturn, 'neutrality_article_normpubDate:[* TO NOW]'];

      filtersToReturn = filtersToReturn.concat(ReadingListFilter);
    } else {
      if (dateFilters && dateFilters.apiQuery) {
        filtersToReturn = [...filtersToReturn, dateFilters.apiQuery];
      }
    }

    return filtersToReturn;
  }

  private generateCleanQueryForTrendingTopic(trendingTopicFilters: ITrendingTopicForFilter[]): string {
    const finalQueriesToBeJoined = [];

    // Go through each query filter, which is an array...
    for (const trendingTopicFilter of trendingTopicFilters) {
      finalQueriesToBeJoined.push(trendingTopicFilter.query.join(') OR ('));
    }

    const generatedTrendingTopicsSection = ' ( (' + finalQueriesToBeJoined.join(') OR (') + ') )';

    // Examples of a generated edismax:
    // "bets" AND ( ( nascar ) OR ( "premier league" ) )
    // where "bets" is the user search term, nascar is a trending topic, and premier league is another trending topic
    // We could get matches for both nascar and premier league topics
    return generatedTrendingTopicsSection;
  }
}
