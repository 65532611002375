/**
 * This file will take care of the actual push of data to the Database
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { IAppState } from 'src/app/core/store-app/reducers';
import { environment } from '../../../environments/environment';
import { selectUserId } from '../../auth/store-auth/auth.selectors';
import { NeutralityEvent } from '../models/neutrality-event.model';

@Injectable({
  providedIn: 'root'
})
export class EventDataService {
  private userId$: Observable<string>;

  constructor(private http: HttpClient, private store: Store<IAppState>) {
    this.userId$ = this.store.pipe(select(selectUserId));
  }
  /**
   * This function will recieve an eventas (a capture of data such as search, login ,logout..)
   as a param and posts it to the server
   * @param customEvent event that is going to be saved
   * @returns http call saving the event into the NEU_EVENTS_URL
   */
  public saveEvent(customEvent: NeutralityEvent): Observable<any> {
    // WARNING: Huge amount of traffic will be generated
    // uncomment next lines to prevent this
    // return of(true);
    return this.userId$.pipe(
      switchMap((userID) => {
        // We can define the initiator in the event, or default it to the current user
        if (!customEvent.initiatorUserID) {
          customEvent.initiatorUserID = userID;
        }

        return this.http.post(`${environment.API_ENDPOINT}/neu-events`, customEvent);
      }),
      take(1)
    );
  }

  /**
   * This function will recieve an notification event as a param and posts it to the server
   * @param customNotification event that is going to be posted
   * @returns http call saving the event into environment.NEU_EVENTS_URL
   */
  public saveNotification(): void {
    // TODO: Notifications service must be redone
  }
}
