import { createAction, props } from '@ngrx/store';

// ------------------- Main ------------------- //

export const toggleNewsTaggingFilter = createAction(
  '[newsTags] Toggle filter string',
  props<{ filter: string; isActive?: boolean }>()
);

export const setNewsTaggingFilters = createAction('[newsTags] Set filters', props<{ filters: string[] }>());
