import { createAction, props } from '@ngrx/store';

export const setSearchSessionEventStartState = createAction(
  '[events] start singleSession event',
  props<{ isEventStart: boolean }>()
);

export const setSearchSessionQuery = createAction('[events] set session query', props<{ searchQuery: string }>());

export const setSearchSessionfilterDate = createAction(
  '[events] set session filter date',
  props<{ filterDate: string }>()
);

export const setSearchSessionFilterSources = createAction(
  '[events] set session filter sources',
  props<{ sources: string }>()
);

export const setIsSearchExecute = createAction('[events] start search', props<{ isSearchStart: boolean }>());

export const setSearchSessionFilterKeywords = createAction(
  '[events] set session filter keywords',
  props<{ keywords: string }>()
);

export const setSearchSessionSelectedLayoutType = createAction(
  '[events] set session selected layout type',
  props<{ selectedLayout: string }>()
);

export const setSearchSessionSortType = createAction('[events] set session sort type', props<{ sortType: string }>());

export const setSearchSessionSaveTopicState = createAction(
  '[events] set session save topic state',
  props<{ isTopicSave: boolean }>()
);

export const setSearchSessionDuration = createAction(
  '[events] set session duration',
  props<{ sessionDuration: string }>()
);

export const resetSearchSessionEvent = createAction('[events] reset search session event');
