/**
 /* Returns a hash code from a string
 /* @param  {String} str The string to hash.
 /* @return {Number}    A 32bit integer
 /* @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str: string): number {
  let hash = 0;

  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr; // Equivalent to: hash * 31 + char
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32-bit integer
  }

  return hash;
}
