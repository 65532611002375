import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService, router: Router): () => Promise<boolean> {
  return () => {
    // Check if cookies enabled
    if (!navigator.cookieEnabled) {
      console.error("Cookies are not enabled, application won't work properly");

      router.navigate(['/public', 'cookies-required']);

      return null;
    }

    try {
      const keycloakInitialization: Promise<boolean> = keycloak.init({
        config: {
          url: `${environment.AUTH_KC_ENDPOINT}/auth/`,
          realm: environment.keycloakRealm,
          clientId: environment.keycloakClientId
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
          checkLoginIframe: false
        },
        loadUserProfileAtStartUp: true,
        enableBearerInterceptor: false
      });

      keycloakInitialization.then(() => {
        // Check if the page is loaded
        const checkPageLoad = setInterval(function () {
          // Check if Angular is defined
          if (typeof window['angular'] === 'undefined') {
            showAngularStatus('Angular is now undefined', 'info');
          }

          // Check if the document is ready
          if (document.readyState === 'complete') {
            clearInterval(checkPageLoad);
            hiddenModal();
            showAngularStatus('Angular is now loaded', 'info');
          }
        }, 1000);
      });

      return keycloakInitialization;
    } catch (error) {
      console.error('Intercepted KC init', error);
    }
  };
}
 // eslint-disable-next-line no-unused-vars
function checkInternetSpeed() {
  /*
  const startTime = new Date().getTime();
  const url = 'https://api.publicapis.org/entries';

  fetch(url, { mode: 'no-cors' })
    .then(() => {
      const endTime = new Date().getTime();
      const duration = endTime - startTime;

      if (duration > 1000) {
        showModal(
          `We are sorry to inform you that we have detected an issue with the internet connection. 
          You might experience some issues due to this.`
        );
      }
    })
    .catch((error) => {
      console.error('Error when measuring Internet speed:', error);
    });
    */
}

function showModal(message) {
  // Create modal element
  const modal: HTMLDivElement = document.createElement('div');
  modal.style.display = 'flex';
  modal.style.flexDirection = 'column';
  modal.style.justifyContent = 'space-between';
  modal.style.alignItems = 'center';
  modal.style.width = '100vw';
  modal.style.height = '100vh';
  modal.style.overflow = 'auto';
  modal.style.backgroundColor = '#010440';
  modal.classList.add('modal');

  // Create modal header element
  const modalHeader: HTMLDivElement = document.createElement('div');
  modalHeader.style.backgroundColor = '#fefefe';
  modalHeader.style.width = '100%';
  modalHeader.style.height = '20%';
  modalHeader.style.display = 'flex';
  modalHeader.style.alignItems = 'center';

  // Add an icon to the header
  const icon: HTMLImageElement = document.createElement('img');
  icon.src = 'https://neutralityinc.com/storage/2023/10/NEUTRALITY-LOGO.svg';
  icon.style.width = '250px';
  icon.style.height = '150px';
  icon.style.marginLeft = '20px';
  modalHeader.appendChild(icon);

  // Create modal content element
  const modalContent: HTMLDivElement = document.createElement('div');
  modalContent.style.backgroundColor = '#fefefe';
  modalContent.style.margin = '15% auto';
  modalContent.style.padding = '20px';
  modalContent.style.border = '2px solid red';
  modalContent.style.width = '80%';

  // Create paragraph element with message
  const paragraph: HTMLParagraphElement = document.createElement('p');
  paragraph.style.color = '#010440';
  paragraph.style.textAlign = 'center';
  paragraph.style.fontSize = '20px';
  const text: Text = document.createTextNode(message as string);
  paragraph.appendChild(text);

  // Append paragraph to modal content
  modalContent.appendChild(paragraph);

  // Create modal footer element
  const modalFooter: HTMLDivElement = document.createElement('div');
  modalFooter.style.backgroundColor = '#fefefe';
  modalFooter.style.width = '100%';
  modalFooter.style.height = '20%';

  // Create close botton
  const closeButton: HTMLButtonElement = document.createElement('button');
  closeButton.textContent = 'Close';
  closeButton.style.fontSize = '16px';
  closeButton.style.padding = '10px 20px';
  closeButton.style.margin = '10px';
  closeButton.style.cursor = 'pointer';
  closeButton.style.borderRadius = '60px';
  closeButton.style.backgroundColor = 'red';
  closeButton.style.color = 'white';
  closeButton.style.fontWeight = '600';
  closeButton.addEventListener('click', hiddenModal);

  // Add close button to the footer
  modalFooter.appendChild(closeButton);

  // Append modal content to modal
  modal.appendChild(modalHeader);
  modal.appendChild(modalContent);
  modal.appendChild(modalFooter);

  // Append modal to body
  document.body.appendChild(modal);
}

function hiddenModal() {
  const modals: HTMLCollectionOf<Element> = document.getElementsByClassName('modal');
  while (modals[0]) {
    modals[0].parentNode.removeChild(modals[0]);
  }
}

function showAngularStatus(message: string, type: string) {
  // eslint-disable-next-line no-console
  console[type](message);
}
