import { createReducer, on } from '@ngrx/store';
import { IDatasetTuneupPublisher } from 'src/app/api/core/api-interfaces';
import { IApiRssDetails } from 'src/app/api/core/interfaces';
import * as SourcesFiltersTypes from './sources-filters.actions';

export const sourcesFiltersFeatureKey = 'sourcesFiltersState';

export interface ISourcesFiltersState {
  // Sources
  availableSources: (IDatasetTuneupPublisher | IApiRssDetails)[];
  selectedActiveSources: (IDatasetTuneupPublisher | IApiRssDetails)[];
  sourcesWithContentInDatasetCount: number;
  // Circulation filters
  availableCirculationFilters: string[];
  activeCirculationFilters: string[];
  // Type filters
  availablePublisherTypeFilters: string[];
  activePublisherTypeFilters: string[];
  // Publisher State filters
  activePublisherStateFilters: string[];
}

export const sourcesFiltersInitialState: ISourcesFiltersState = {
  // Sources
  availableSources: [],
  selectedActiveSources: [],
  sourcesWithContentInDatasetCount: 0,
  // Circulation filters
  availableCirculationFilters: ['National', 'Local', 'International'],
  activeCirculationFilters: [],
  // Type filters
  availablePublisherTypeFilters: ['Online', 'Newspaper', 'Magazine'],
  activePublisherTypeFilters: [],
  activePublisherStateFilters: []
};

export const sourcesFiltersReducer = createReducer(
  sourcesFiltersInitialState,
  on(SourcesFiltersTypes.toggleActiveSelectedSource, (state: ISourcesFiltersState, action) => {
    const newActiveFilters: (IDatasetTuneupPublisher | IApiRssDetails)[] = [...state.selectedActiveSources];

    const toggledPublisherName: string = Array.isArray(action.source.publisher_name)
      ? action.source.publisher_name[0]
      : action.source.publisher_name;
    const isSubSource = !!(<IApiRssDetails>action.source).RSSmap;
    const subSourceRSS: string = isSubSource ? (<IApiRssDetails>action.source).RSSmap[0] : null;

    const filterIndex: number = state.selectedActiveSources.findIndex(
      (source: IDatasetTuneupPublisher | IApiRssDetails) => {
        const publisherName: string = Array.isArray(source.publisher_name)
          ? source.publisher_name[0]
          : source.publisher_name;
        const isPubNameSame: boolean = publisherName === toggledPublisherName;

        if (!isSubSource) {
          return isPubNameSame;
        }

        const sourceRSS: string = (<IApiRssDetails>source)?.RSSmap ? (<IApiRssDetails>source).RSSmap[0] : null;

        const isSubSourceSame: boolean = sourceRSS === subSourceRSS;

        return isPubNameSame && isSubSourceSame;
      }
    );

    if (filterIndex !== -1) {
      if (action.isActive === false || action.isActive === undefined) {
        // Toggle off
        newActiveFilters.splice(filterIndex, 1);
      }
    } else {
      if (action.isActive === true || action.isActive === undefined) {
        // Toggle on
        newActiveFilters.push(action.source);
      }
    }

    return { ...state, selectedActiveSources: newActiveFilters };
  }),
  on(SourcesFiltersTypes.setAvailableSources, (state: ISourcesFiltersState, action) => {
    return { ...state, availableSources: [...action.sources] };
  }),
  on(SourcesFiltersTypes.setActiveSelectedSources, (state: ISourcesFiltersState, action) => {
    return { ...state, selectedActiveSources: [...action.sources] };
  }),
  on(SourcesFiltersTypes.setSourcesWithContentInDatasetCount, (state: ISourcesFiltersState, action) => {
    return { ...state, sourcesWithContentInDatasetCount: action.amount };
  }),
  // Circulation
  on(SourcesFiltersTypes.setActiveCirculationFilters, (state: ISourcesFiltersState, action) => {
    return { ...state, activeCirculationFilters: [...action.activeCirculationFilters] };
  }),
  // Publisher type
  on(SourcesFiltersTypes.setActivePublisherTypeFilters, (state: ISourcesFiltersState, action) => {
    return { ...state, activePublisherTypeFilters: [...action.activePublisherTypeFilters] };
  }),
  // Publisher State
  on(SourcesFiltersTypes.setActivePublisherStateFilters, (state: ISourcesFiltersState, action) => {
    return { ...state, activePublisherStateFilters: [...action.activePublisherStateFilters] };
  })
);
